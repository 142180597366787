import {
  Address,
  CustomerAddAddressAction,
  CustomerAddBillingAddressIdAction,
  CustomerAddShippingAddressIdAction,
  CustomerChangeAddressAction,
  CustomerRemoveAddressAction,
  CustomerSetCompanyNameAction,
  CustomerSetCustomFieldAction,
  CustomerSetFirstNameAction,
  CustomerSetLastNameAction,
  CustomerSetVatIdAction,
} from '@commercetools/platform-sdk'

import { getRandomId } from 'utils/getRandomId'

export const setFirstName = (firstName: string): CustomerSetFirstNameAction => ({
  action: 'setFirstName',
  firstName,
})

export const setLastName = (lastName: string): CustomerSetLastNameAction => ({
  action: 'setLastName',
  lastName,
})

export const setVatId = (vatId: string): CustomerSetVatIdAction => ({
  action: 'setVatId',
  vatId,
})

export const setCompanyName = (companyName: string): CustomerSetCompanyNameAction => ({
  action: 'setCompanyName',
  companyName,
})

export const addAddress = (address: Address): CustomerAddAddressAction => ({
  action: 'addAddress',
  address: {
    key: getRandomId(),
    ...address,
  },
})

export const removeAddress = (addressId: string): CustomerRemoveAddressAction => ({
  action: 'removeAddress',
  addressId,
})

export const changeAddress = (addressId: string, address: Address): CustomerChangeAddressAction => ({
  action: 'changeAddress',
  addressId,
  address,
})

export const addBillingAddressId = (addressId: string): CustomerAddBillingAddressIdAction => ({
  action: 'addBillingAddressId',
  addressId,
})

export const addShippingAddressId = (addressId: string): CustomerAddShippingAddressIdAction => ({
  action: 'addShippingAddressId',
  addressId,
})

export const addShippingAddressKey = (addressKey: string): CustomerAddShippingAddressIdAction => ({
  action: 'addShippingAddressId',
  addressKey,
})

export const setName = (value: string): CustomerSetCustomFieldAction => ({
  action: 'setCustomField',
  name: 'name',
  value,
})

export const setDefaultStore = (value: string): CustomerSetCustomFieldAction => ({
  action: 'setCustomField',
  name: 'defaultStore',
  value,
})

export const setPreferredUnitSystem = (value: string): CustomerSetCustomFieldAction => ({
  action: 'setCustomField',
  name: 'selectedUoM',
  value,
})

export const setSelectedCompanyAction = (value: string): CustomerSetCustomFieldAction => ({
  action: 'setCustomField',
  name: 'selectedCompany',
  value: { typeId: 'customer', id: value },
})

export const setLastLoginDate = (value: Date): CustomerSetCustomFieldAction => ({
  action: 'setCustomField',
  name: 'lastLogin',
  value,
})

export const setIsBundlesWizardCompleted = (): CustomerSetCustomFieldAction => ({
  action: 'setCustomField',
  name: 'isBundlesWizardCompleted',
  value: true,
})
