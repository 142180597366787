import { Button, Container, ContainerProps, Flex, Heading } from '@chakra-ui/react'
import router from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { CartIcon } from 'components/elements/Icons/CartIcon'

type Props = ContainerProps & {
  showButton: boolean
}

export function EmptyCart({ showButton, ...props }: Props) {
  const { t } = useTranslation('common')

  return (
    <Container
      alignSelf="center"
      justifySelf="center"
      {...props}
      m={{ base: 0, md: 10 }}
      height="full"
      p={0}
      background="primary.white"
    >
      <Flex p={13} direction="column" alignItems="center" justifyContent="center">
        <Flex
          boxSize="11.5rem"
          fontSize="sm"
          borderRadius="50%"
          bgColor="secondary.beige.100"
          alignItems="center"
          justifyContent="center"
        >
          <CartIcon boxSize="5.75rem" color="primary.red" />
        </Flex>
        <Heading as="h1" mb={12} mt={8} fontSize={{ base: 'lg', sm: 'lg' }}>
          {t('empty-cart-text')}
        </Heading>
        {showButton && <Button onClick={() => router.push('/')}>{t('start-shopping-text')}</Button>}
      </Flex>
    </Container>
  )
}
