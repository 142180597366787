import type { QueryFunctionContext } from 'react-query'

import client from 'bom/client'

type Arguments = QueryFunctionContext<
  [
    queryFnName: 'searchForEquipmentProducts',
    companyNumber: string | undefined,
    searchQuery: string,
    equipmentId: string,
    limit: number,
    offset: number,
    positionId?: string,
  ]
>

export const searchForEquipmentProducts = ({
  signal,
  queryKey: [, companyNumber, searchQuery, equipmentId, limit, offset, positionId],
}: Arguments): Promise<BOM.EquipmentSearchResult> => {
  if (positionId) {
    return client<BOM.EquipmentSearchResult>(
      // eslint-disable-next-line max-len
      `component/customers/${companyNumber}/equipments/${equipmentId}/positions/${positionId}/components?q=${searchQuery}&limit=${limit}&offset=${offset}`,
      { signal },
    )
  } else {
    return client<BOM.EquipmentSearchResult>(
      // eslint-disable-next-line max-len
      `component/customers/${companyNumber}/equipments/${equipmentId}/components?q=${searchQuery}&limit=${limit}&offset=${offset}`,
      { signal },
    )
  }
}
