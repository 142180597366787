import { Button, ButtonProps, chakra, SystemStyleInterpolation } from '@chakra-ui/react'

import { useCart } from 'commercetools/hooks/use-cart'
import { CartIcon } from 'components/elements/Icons/CartIcon'

const styles = {
  button: {
    position: 'relative',
    verticalAlign: 'middle',
  },
  icon: {
    boxSize: 5,
    color: 'white',
  },
  text: {
    display: { base: 'none', lg: 'inherit' },
    color: 'white',
  },
  dot: {
    height: { base: 5, lg: 8 },
    width: { base: 5, lg: 8 },
    fontSize: { base: '2xs', lg: 'sm' },
    lineHeight: '1rem',
    borderRadius: '50%',
    color: 'white',
    backgroundColor: 'red',
    position: 'absolute',
    top: { base: '-.4rem', md: '-.2rem', lg: '-.6rem' },
    left: '1.8rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
} as const satisfies SystemStyleInterpolation

export function CartButton(props: ButtonProps) {
  const [cart] = useCart()

  const cartLineItemsCount = cart.data?.lineItems?.reduce((a, i) => a + i.quantity, 0) ?? 0

  return (
    <Button {...props} variant="menu" leftIcon={<CartIcon {...styles.icon} />} {...styles.button}>
      {cartLineItemsCount > 0 && <chakra.span {...styles.dot}>{cartLineItemsCount}</chakra.span>}
    </Button>
  )
}
