import type { SystemStyleInterpolation } from '@chakra-ui/react'

export const beforeElement = {
  content: '""',
  position: 'absolute',
  bottom: '0',
  left: '0',
  right: '0',
  width: '3rem',
  height: '3px',
  bg: 'primary.red',
  margin: 'auto',
  transition: 'transform 0.3s',
} as const satisfies SystemStyleInterpolation

const opened = { _before: { transform: `scale(1)` } }

export const buttonStyles = {
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  color: 'black',
  textDecoration: 'none',
  position: 'relative',
  textAlign: 'center',
  px: 2,
  minH: '10',
  _before: {
    lg: {
      ...beforeElement,
      transform: `scale(0)`,
    },
  },
  _hover: {
    textDecoration: 'none',
    ...opened,
  },
  _focusVisible: {
    textDecoration: 'none',
    ...opened,
  },
  _active: opened,
  sx: {
    '&[aria-expanded="true"]': opened,
  },
} as const satisfies SystemStyleInterpolation
