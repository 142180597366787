import {
  Box,
  Container,
  Flex,
  Grid,
  HStack,
  SystemStyleInterpolation,
  SystemStyleObject,
  useDisclosure,
} from '@chakra-ui/react'
import router from 'next/router'
import { FunctionComponent, useState } from 'react'

import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'
import { AccountButton } from 'components/modules/Account/AccountButton'
import { CartButton } from 'components/modules/Cart/CartButton'
import { CheckoutMenuButton } from 'components/modules/Checkout/CheckoutMenuButton'
import Logo from 'components/modules/Header/Logo'
import MobileMenu from 'components/modules/Header/MobileMenu'
import Navigation from 'components/modules/Header/Navigation'
import Sites from 'components/modules/Header/Sites'
import { QuickSearchForm } from 'components/modules/Search/QuickSearch/QuickSearchForm'
import { SearchButton } from 'components/modules/Search/SearchButton'
import { RoutePaths } from 'config/routes'

export const HEADER_HEIGHT = {
  base: '48px' /* 48px */,
  lg: '154px' /* 80px top header + 74px navigation menu */,
} as const

// Active site link is underlined, but on hover the active underline should be hidden
const sx = {
  'ul:hover a:not(:hover)': {
    _before: {
      lg: {
        transform: 'scale(0)',
        transition: 'transform 0.3s',
      },
    },
  },
} as const satisfies SystemStyleObject

const styles = {
  header: {
    w: 'full',
    alignItems: 'center',
    zIndex: 100,
    boxShadow: { base: '0px 4px 8px 0px rgba(0, 0, 0, 0.15);', lg: 'none' },
    direction: 'column',
    pos: { base: 'sticky', lg: 'inherit' },
    top: 0,
    bgColor: { base: 'primary.black', lg: 'primary.white' },
    height: HEADER_HEIGHT,
  },
  menu: {
    alignItems: 'center',
    gridTemplateColumns: {
      base: 'auto 1fr',
      lg: '1fr auto 1fr',
    },
    gridAutoFlow: 'dense',
  },
  logo: {
    gridColumn: { base: 2, lg: 1 },
  },
  buttons: {
    justifyContent: { md: 'flex-end' },
    gridColumn: { base: 1, lg: 3 },
    display: { base: 'none', lg: 'flex' },
  },
  mobileButtons: {
    justifyContent: { lg: 'flex-end' },
    gridColumn: 3,
    display: { base: 'flex', lg: 'none' },
  },
  headerWrapper: {
    h: HEADER_HEIGHT,
    py: { base: 0, lg: 4 },
    pl: { base: 4, lg: 10 },
    pr: { base: 0, lg: 6 },
    maxW: 'desktop',
  },
  mobileSearchWrapper: {
    width: 'full',
    display: { base: 'flex', lg: 'none' },
    bg: 'primary.black',
    p: 4,
  },
  navigationWrapper: {
    display: { base: 'none', lg: 'flex' },
    h: '74px',
    backgroundColor: 'primary.black',
    maxW: 'full',
    pt: 8,
    pb: 7,
    px: { base: 6, lg: 10 },
    justifyContent: 'center',
    alignItems: 'center',
  },
} as const satisfies SystemStyleInterpolation

type Props = {
  isCheckout?: boolean
}

const Header: FunctionComponent<Props> = ({ isCheckout }: Props) => {
  const businessUnit = useBusinessUnit()
  const { isOpen, onClose, onToggle } = useDisclosure()
  const [isSearchOpen, setIsSearchOpen] = useState(false)

  return (
    <Flex
      as="header"
      {...styles.header}
      {...(isCheckout && {
        bgColor: 'primary.black',
        h: { base: HEADER_HEIGHT.base, lg: '70px' },
      })}
    >
      <Container
        {...styles.headerWrapper}
        {...(isCheckout && {
          py: { base: 0, lg: '11px' },
          pl: { base: 4, lg: 5 },
          pr: { base: 0, lg: 6 },
        })}
      >
        <Grid {...styles.menu} sx={sx}>
          <Flex {...styles.logo}>
            {!isCheckout && <MobileMenu isOpen={isOpen} onClose={onClose} onToggle={onToggle} />}
            {!isOpen && <Logo adjustMobile isCheckout={isCheckout} />}
          </Flex>

          {isCheckout ? (
            <Box justifyContent="flex-start" gridColumn={4}>
              <CheckoutMenuButton />
            </Box>
          ) : (
            <>
              <Sites />
              <HStack spacing={2} {...styles.buttons}>
                <AccountButton currentCompany={businessUnit.current} />
              </HStack>
              {!isOpen && (
                <HStack {...styles.mobileButtons}>
                  <CartButton onClick={() => router.push(RoutePaths.cart)} pr={2} />
                  <SearchButton onClick={() => setIsSearchOpen(!isSearchOpen)} pl={2} />
                </HStack>
              )}
            </>
          )}
        </Grid>
      </Container>

      {isSearchOpen && (
        <Box {...styles.mobileSearchWrapper}>
          <QuickSearchForm />
        </Box>
      )}

      {!isCheckout && (
        <Container {...styles.navigationWrapper}>
          <Navigation />
        </Container>
      )}
    </Flex>
  )
}

export default Header
