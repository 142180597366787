import {
  chakra,
  ChakraProps,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  useOutsideClick,
} from '@chakra-ui/react'
import debounce from 'lodash/debounce'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useMemo, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { useEquipment } from 'bom/hooks/use-equipment'
import { useEquipmentProductSearch } from 'bom/hooks/use-equipment-product-search'
import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'
import { useProductSearch } from 'commercetools/hooks/use-product-search'
import { CloseIcon } from 'components/elements/Icons/CloseIcon'
import { SearchIcon } from 'components/elements/Icons/SearchIcon'
import { QuickSearchDrawer } from 'components/modules/Search/QuickSearch/QuickSearchDrawer'
import { QuickSearchEquipmentMenu } from 'components/modules/Search/QuickSearch/QuickSearchEquipmentMenu'
import { events } from 'gtm/events'
import { gtagEvent } from 'gtm/gtag'

type SearchForm = {
  searchQuery: string
}

export const QuickSearchForm = (props: ChakraProps) => {
  const router = useRouter()
  const [quantityDialogIsOpen, setQuantityDialogIsOpen] = useState(false)
  const [availabilityDialogIsOpen, setAvailabilityDialogIsOpen] = useState(false)
  const businessUnit = useBusinessUnit()
  const { t, lang } = useTranslation('common')
  const equipmentId = router.query?.equipmentId?.toString() ?? ''
  const positionId = router.query?.positionId?.toString() ?? ''
  const form = useForm<SearchForm>({ mode: 'onChange' })
  const { handleSubmit, register, setValue, getValues } = form
  const [searchQuery, setSearchQuery] = useState('')
  const SEARCH_INPUT_NAME = 'searchQuery'
  const hasSearchQuery = searchQuery.length > 2

  const { data: equipments } = useEquipment()

  const { data: equipmentSearchResult, isLoading: equipmentSearchIsLoading } = useEquipmentProductSearch(
    searchQuery,
    equipmentId,
    1,
    3,
    positionId,
  )

  const { data: productsResponse, isFetching } = useProductSearch(
    {
      limit: 3,
      searchQuery: equipmentSearchResult?.equipmentSkus ? '' : searchQuery,
      language: lang,
      sort: equipmentSearchResult ? '' : `name.${lang} asc`,
      filter: [
        'variants.sku:exists',
        `variants.attributes.erpLegalEntity.key:${businessUnit.erpLegalEntity}`,
        ...(equipmentSearchResult ? [`variants.sku:"${equipmentSearchResult?.equipmentSkus}"`] : []),
      ],
    },
    { enabled: hasSearchQuery && !equipmentSearchIsLoading },
  )

  const onSubmit = async (searchForm: SearchForm) => {
    router.push({
      pathname: '/search',
      query: {
        searchQuery: searchForm.searchQuery,
        ...(router.query.equipmentId && { equipmentId: router.query.equipmentId }),
        defaultIndex: 0,
      },
    })
    setSearchQuery('')
  }

  const handleSearchQueryDebounced = debounce((searchText: string) => {
    setSearchQuery(searchText)
    setValue(SEARCH_INPUT_NAME, searchText)
  }, 100)

  const updateTrackingDebounced = useMemo(
    () =>
      debounce(
        (searchText) =>
          gtagEvent({
            event: events.quickSearch,
            searchTerm: searchText,
          }),
        700,
        { trailing: true },
      ),
    [],
  )

  const handleOnChange = (searchText: string) => {
    handleSearchQueryDebounced(searchText)
    updateTrackingDebounced(searchText)
  }

  const clearSearchQuery = () => {
    setSearchQuery('')
    setValue(SEARCH_INPUT_NAME, '')
  }

  const handleOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      handleSearchQueryDebounced.cancel()
      updateTrackingDebounced.cancel()
      onSubmit({ searchQuery: getValues(SEARCH_INPUT_NAME) })
    }
  }

  const ref = useRef<HTMLDivElement>(null)
  useOutsideClick({
    ref: ref,
    handler: () => {
      //Do not close quick search if confirm remove dialog or availability dialog is open
      if (!quantityDialogIsOpen && !availabilityDialogIsOpen) {
        clearSearchQuery()
      }
    },
  })

  const updateSelectedEquipment = (equipment: BOM.Equipment | undefined) => {
    router.push(
      {
        pathname: router.asPath.split('?')[0],
        query: {
          ...(router.query.searchQuery && { searchQuery: router.query.searchQuery }),
          ...(equipment?.id && equipment?.id !== '-1' && { equipmentId: equipment?.id }),
          ...(positionId && positionId !== '-1' && { positionId: positionId }),
        },
      },
      undefined,
      { shallow: true },
    )
  }

  return (
    <FormProvider {...form}>
      <chakra.form display="flex" autoComplete="off" onSubmit={handleSubmit(onSubmit)} {...props} width="100%">
        <InputGroup
          position="relative"
          zIndex="dropdown"
          ref={ref}
          height={12}
          backgroundColor="white"
          borderRadius={hasSearchQuery ? '4px 4px 0 0' : '4px'}
          alignItems="center"
          justifyContent="start"
        >
          <InputLeftElement
            justifyContent="start"
            backgroundColor="white"
            borderRadius="4px"
            width="fit-content"
            height="100%"
            position="static"
          >
            <QuickSearchEquipmentMenu
              updateSelectedEquipment={updateSelectedEquipment}
              selectedEquipmentId={router.query.equipmentId?.toString()}
              equipments={equipments}
            />
          </InputLeftElement>

          <Input
            position="static"
            width="100%"
            variant="ghost"
            backgroundColor="white"
            border="none"
            {...register('searchQuery')}
            fontSize="sm"
            height={10}
            paddingStart={5}
            paddingEnd={0}
            onChange={(event) => handleOnChange(event.target.value)}
            onKeyDown={(event) => handleOnEnter(event)}
            color="primary.black"
            placeholder={t('quick-search-placeholder')}
            _placeholder={{ color: 'primary.black' }}
          />
          {hasSearchQuery && (
            <IconButton
              aria-label="clear"
              icon={<CloseIcon color="primary.red" boxSize={2} />}
              variant="ghost"
              size="xs"
              onClick={clearSearchQuery}
            />
          )}
          <InputRightElement flexShrink={0} backgroundColor="white" position="static" height={12} borderRadius="4px">
            <IconButton
              type="submit"
              aria-label="search"
              icon={isFetching ? <Spinner size="sm" /> : <SearchIcon boxSize={4} />}
              variant="ghost"
              color="primary.black"
              size="sm"
            />
          </InputRightElement>
          <QuickSearchDrawer
            searchQuery={searchQuery}
            searchIsActive={hasSearchQuery}
            productsResponse={productsResponse}
            isFetching={isFetching}
            setQuantityDialogIsOpen={setQuantityDialogIsOpen}
            setAvailabilityDialogIsOpen={setAvailabilityDialogIsOpen}
          />
        </InputGroup>
      </chakra.form>
    </FormProvider>
  )
}
