import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Heading,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import Router from 'next/router'
import useTranslation from 'next-translate/useTranslation'

import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'
import { useCart } from 'commercetools/hooks/use-cart'
import { cartQueryHasLineItems } from 'commercetools/utils/cartQueryHasLineItems'
import { AddToCartFromFileLink } from 'components/elements/AddToCartFromFileLink/AddToCartFromFileLink'
import { CloseButton } from 'components/elements/CloseButton/CloseButton'
import { CartLineItem } from 'components/modules/Cart/CartLineItem'
import { EmptyCart } from 'components/modules/Cart/EmptyCart'
import { Price } from 'components/modules/Price/Price'
import { RoutePaths } from 'config/routes'
import isCheckoutDisabled from 'utils/isCheckoutDisabled'

export type CartDrawerProps = Omit<DrawerProps, 'children'>

export function CartDrawer({ onClose, ...props }: CartDrawerProps) {
  const { t } = useTranslation('common')
  const [cart] = useCart()
  const businessUnit = useBusinessUnit()
  const isMobile = useBreakpointValue({ base: true, md: false })

  const totalQuantity = cart?.data?.lineItems ? cart.data?.lineItems?.length : 0
  const onGoToCartPage = async () => {
    onClose()
    Router.push(RoutePaths.cart)
  }

  const isCheckoutDisabledForOrganization = isCheckoutDisabled(businessUnit.current)

  return (
    <Drawer size="md" placement="right" onClose={onClose} preserveScrollBarGap={true} {...props}>
      <DrawerOverlay zIndex="modal" />
      <DrawerContent p={6}>
        <DrawerHeader p={0}>
          <Stack spacing={6}>
            <HStack justifyContent="space-between" spacing={8}>
              <Heading as="h2" size="lg">
                {t('cart-drawer-title')}
              </Heading>
              <CloseButton onClick={onClose} />
            </HStack>
            {cartQueryHasLineItems(cart) && (
              <Button variant="secondary" onClick={onGoToCartPage}>
                {isCheckoutDisabledForOrganization
                  ? t('continue-to-cart-button-title')
                  : t('continue-to-cart-and-checkout-button-title')}
              </Button>
            )}
          </Stack>
          <HStack mt={9} mb={8}>
            {!isMobile && <AddToCartFromFileLink>{t('add-items-from-file')}</AddToCartFromFileLink>}
          </HStack>
          {!cartQueryHasLineItems(cart) && (
            <Stack spacing={6}>
              <EmptyCart showButton={false} />
            </Stack>
          )}
        </DrawerHeader>
        <DrawerBody flexGrow={1}>
          {cartQueryHasLineItems(cart) ? (
            <>
              <HStack justifyContent="space-between" spacing={8}>
                <Text fontSize="md" fontWeight="normal">
                  {totalQuantity} {totalQuantity && totalQuantity > 1 ? t('common:line-items') : t('common:line-item')}
                </Text>
                <Price fontWeight="normal" price={cart.data?.totalPrice} />
              </HStack>
              <Divider borderBottomWidth={4} borderColor="primary.grey.100" my={6} />
              {cart.data?.lineItems.map((lineItem, index, { length }) => (
                <CartLineItem
                  variant="mini-cart"
                  key={index}
                  lineItem={lineItem}
                  borderBottomWidth={length - 1 === index ? 0 : 1}
                  mb={length - 1 === index ? 0 : 4}
                />
              ))}
              <Divider borderBottomWidth={4} borderColor="primary.grey.100" my={6} />
            </>
          ) : null}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
