export const events = {
  addToCart: 'add_to_cart',
  addBundleToCart: 'add_bundle_to_cart',
  addShippingInfo: 'add_shipping_info',
  beginCheckout: 'begin_checkout',
  purchase: 'purchase',
  removeFromCart: 'remove_from_cart',
  reorder: 'reorder',
  viewCart: 'view_cart',
  viewItem: 'view_item',
  viewItemList: 'view_item_list',
  quickSearch: 'search',
  changeBranch: 'change_branch',
  requestPrice: 'request_price',
}
