/**
 * Replace double or more slashes with single slash.
 * @example
 * ```ts
 * normalizePathname('//foo//bar//baz//') // '/foo/bar/baz/'
 * ```
 */
function normalizePathname(pathname: string): string {
  return pathname.replace(/\/+/g, '/')
}

export default normalizePathname
