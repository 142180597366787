import React, { FunctionComponent, ReactNode } from 'react'
import { QueryClient as ReactQueryClient, QueryClientProvider } from 'react-query'

interface QueryClientProps {
  children: ReactNode
}

const TAG = 'QueryClient'

export const queryClient = new ReactQueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
})

const QueryClient: FunctionComponent<QueryClientProps> = ({ children }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
)

QueryClient.displayName = TAG

export default QueryClient
