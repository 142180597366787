import React from 'react'

import { BranchesProvider } from 'commercetools/hooks/use-branches'
import { BusinessUnitProvider } from 'commercetools/hooks/use-business-unit'
import { CartProvider } from 'commercetools/hooks/use-cart'
import { CustomerProvider } from 'commercetools/hooks/use-customer'
import { PriceChannelsProvider } from 'commercetools/hooks/use-price-channel'
import LoadProgressBar from 'components/elements/LoadProgressBar'
import ErrorBoundary from 'components/modules/ErrorBoundary/ErrorBoundary'
import Head from 'components/modules/Head'

export interface LayoutMainProps {}
const LayoutMain: LayoutComponent<LayoutMainProps> = (_props, page) => (
  <>
    <LoadProgressBar />
    <Head />
    <CustomerProvider>
      <BusinessUnitProvider>
        <PriceChannelsProvider>
          <BranchesProvider>
            <CartProvider>
              <ErrorBoundary>{page}</ErrorBoundary>
            </CartProvider>
          </BranchesProvider>
        </PriceChannelsProvider>
      </BusinessUnitProvider>
    </CustomerProvider>
  </>
)

function getLayoutMain(props: LayoutMainProps = {}): GetLayout {
  return (page) => LayoutMain(props, page)
}

export default getLayoutMain
