import { Icon, IconProps } from '@chakra-ui/react'

export function TowTruckIcon(props: IconProps) {
  return (
    <Icon width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M27.826087,28.9809244 C25.9050965,28.9809244 24.3478261,30.5381949 24.3478261,32.4591853 C24.3478261,34.3801757 25.9050965,35.9374462 27.826087,35.9374462 C29.7470774,35.9374462 31.3043478,34.3801757 31.3043478,32.4591853 C31.3043478,30.5381949 29.7470774,28.9809244 27.826087,28.9809244 Z M27.826087,31.299765 C28.4664171,31.299765 28.9855072,31.8188552 28.9855072,32.4591853 C28.9855072,33.0995154 28.4664171,33.6186056 27.826087,33.6186056 C27.1857568,33.6186056 26.6666667,33.0995154 26.6666667,32.4591853 C26.6666667,31.8188552 27.1857568,31.299765 27.826087,31.299765 Z" />
      <path d="M9.27536232,28.9809244 C7.35437188,28.9809244 5.79710145,30.5381949 5.79710145,32.4591853 C5.79710145,34.3801757 7.35437188,35.9374462 9.27536232,35.9374462 C11.1963528,35.9374462 12.7536232,34.3801757 12.7536232,32.4591853 C12.7536232,30.5381949 11.1963528,28.9809244 9.27536232,28.9809244 Z M9.27536232,31.299765 C9.91569246,31.299765 10.4347826,31.8188552 10.4347826,32.4591853 C10.4347826,33.0995154 9.91569246,33.6186056 9.27536232,33.6186056 C8.63503217,33.6186056 8.11594203,33.0995154 8.11594203,32.4591853 C8.11594203,31.8188552 8.63503217,31.299765 9.27536232,31.299765 Z" />
      <path d="M24.073856,12.7490402 C25.3342411,12.7486632 26.4913239,13.4295657 27.105504,14.5203236 L27.1858494,14.6715783 L30.8838647,22.0707246 C33.6770689,22.4275182 35.8479365,24.7682542 35.9390504,27.6338858 L35.942029,27.821504 L35.942029,30.1403446 C35.942029,32.0064497 34.4724742,33.5293217 32.6275059,33.6148196 L32.4637681,33.6186055 L30.1449275,33.6186055 C29.5045974,33.6186055 28.9855072,33.0995153 28.9855072,32.4591852 C28.9855072,31.8565215 29.4453241,31.3612539 30.0332676,31.3050724 L30.1449275,31.2997649 L32.4637681,31.2997649 C33.0664318,31.2997649 33.5616995,30.8399481 33.6178809,30.2520045 L33.6231884,30.1403446 L33.6231884,27.821504 C33.6231884,25.9005137 32.0659179,24.3432432 30.1449275,24.3432432 C29.7423382,24.3432432 29.3714275,24.1347242 29.1611417,23.7973864 L29.1078704,23.7022512 L25.1118446,15.7086539 C24.9317379,15.3485609 24.5792822,15.1101006 24.1832079,15.0729591 L24.0742029,15.0678809 L20.8695652,15.0678809 C20.2669016,15.0678809 19.7716339,15.5276978 19.7154524,16.1156412 L19.7101449,16.2273011 L19.7101449,23.1838229 C19.7101449,23.7864865 19.2503281,24.2817542 18.6623845,24.3379357 L18.5507246,24.3432432 L2.31884058,24.3432432 C1.71617696,24.3432432 1.22090924,24.8030601 1.16472779,25.3910036 L1.15942029,25.5026635 L1.15942029,30.1403446 C1.15942029,30.7430083 1.61923713,31.238276 2.20718066,31.2944574 L2.31884058,31.2997649 L6.95652174,31.2997649 C7.59685188,31.2997649 8.11594203,31.8188551 8.11594203,32.4591852 C8.11594203,33.0618489 7.65612515,33.5571165 7.06818165,33.613298 L6.95652174,33.6186055 L2.31884058,33.6186055 C0.452735559,33.6186055 -1.0701365,32.1490508 -1.1556344,30.3040824 L-1.15942029,30.1403446 L-1.15942029,25.5026635 C-1.15942029,23.6365585 0.310134531,22.1136864 2.15510284,22.0281885 L2.31884058,22.0244026 L17.3913043,22.0243478 L17.3913043,16.2273011 C17.3913043,14.3611962 18.8608592,12.8383241 20.7058275,12.7528262 L20.8695652,12.7490402 L24.073856,12.7490402 Z" />
      <path d="M25.5072464,31.299765 C26.1475765,31.299765 26.6666667,31.8188552 26.6666667,32.4591853 C26.6666667,33.061849 26.2068498,33.5571166 25.6189063,33.6132981 L25.5072464,33.6186056 L11.5942029,33.6186056 C10.9538728,33.6186056 10.4347826,33.0995154 10.4347826,32.4591853 C10.4347826,31.8565216 10.8945995,31.361254 11.482543,31.3050725 L11.5942029,31.299765 L25.5072464,31.299765 Z" />
      <path d="M30.1449275,22.0244027 C30.7852577,22.0244027 31.3043478,22.5434928 31.3043478,23.183823 C31.3043478,23.7864867 30.844531,24.2817543 30.2565874,24.3379358 L30.1449275,24.3432433 L18.5507246,24.3432433 C17.9103945,24.3432433 17.3913043,23.8241531 17.3913043,23.183823 C17.3913043,22.5811593 17.8511212,22.0858916 18.4390647,22.0297102 L18.5507246,22.0244027 L30.1449275,22.0244027 Z" />
      <path d="M2.31884058,10.4301998 C2.92150425,10.4301998 3.41677193,10.8900167 3.47295337,11.4779602 L3.47826087,11.5896201 L3.47826087,12.9476329 L3.56483061,12.9788657 C4.82201345,13.4613797 5.72793481,14.6527054 5.79331556,16.0635635 L5.79710145,16.2273012 C5.79710145,18.1482917 4.23983101,19.7055621 2.31884058,19.7055621 C0.397850146,19.7055621 -1.15942029,18.1482917 -1.15942029,16.2273012 C-1.15942029,15.5869711 -0.640330145,15.067881 2.22044605e-16,15.067881 C0.602663666,15.067881 1.09793135,15.5276978 1.15411279,16.1156413 L1.15942029,16.2273012 C1.15942029,16.8676314 1.67851043,17.3867215 2.31884058,17.3867215 C2.95917072,17.3867215 3.47826087,16.8676314 3.47826087,16.2273012 C3.47826087,15.6246376 3.01844399,15.1293699 2.43050049,15.0731885 L2.31884058,15.067881 C1.71617691,15.067881 1.22090923,14.6080641 1.16472779,14.0201206 L1.15942029,13.9084607 L1.15942029,11.5896201 C1.15942029,10.9492899 1.67851043,10.4301998 2.31884058,10.4301998 Z" />
      <path d="M2.33796302,-1.08769458 C3.00187845,-1.28908226 3.716047,-1.05701231 4.13325861,-0.520126167 L4.20792105,-0.415919357 L19.5153607,22.5406024 C19.8706016,23.0733561 19.726699,23.7932181 19.1939452,24.148459 C18.6925299,24.4828034 18.0253717,24.3749999 17.6524509,23.917 L17.5860886,23.8270436 L3.47439614,2.66357488 L3.47515945,6.95193893 C3.47515945,7.55460259 3.01534257,8.04987027 2.42739907,8.10605172 L2.31573916,8.11135922 C1.71307549,8.11135922 1.21780781,7.65154234 1.16162637,7.06359884 L1.15630915,6.95193893 L1.15630915,0.515728441 C1.15184266,-0.22138424 1.63256675,-0.873724381 2.33796302,-1.08769458 Z" />
    </Icon>
  )
}
