import { extendTheme, theme as base } from '@chakra-ui/react'

import { AccountMenu } from './components/account-menu'
import { Alert } from './components/alert'
import { Button } from './components/button'
import { Checkbox } from './components/checkbox'
import { Container } from './components/container'
import { Divider } from './components/divider'
import { Drawer } from './components/drawer'
import { Form } from './components/form'
import { FormError } from './components/form-error'
import { Heading } from './components/heading'
import { Input } from './components/input'
import { Link } from './components/link'
import { Menu } from './components/menu'
import { Modal } from './components/modal'
import { Popover } from './components/popover'
import { Radio } from './components/radio'
import { ScrollableFlex } from './components/scrollable-flex'
import { Select } from './components/select'
import { Switch } from './components/switch'
import { Table } from './components/table'
import { Tabs } from './components/tabs'
import { colors } from './foundations/colors'
import { fontSizes } from './foundations/font-sizes'
import { sizes } from './foundations/sizes'
import { space } from './foundations/space'
import { global } from './global'

const overrides = {
  config: {
    cssVarPrefix: 'nm',
  },
  styles: {
    global,
  },
  colors,
  space,
  sizes,
  fonts: {
    heading: `Proxima Nova, ${base.fonts?.heading}`,
    body: `Proxima Nova, ${base.fonts?.body}`,
  },
  fontSizes,
  components: {
    Alert,
    Drawer,
    Container,
    Modal,
    Tabs,
    Button,
    Heading,
    Select,
    Link,
    Form,
    FormError,
    Input,
    Checkbox,
    Table,
    Radio,
    Popover,
    Menu,
    Divider,
    AccountMenu,
    ScrollableFlex,
    Switch,
  },
}

export default extendTheme(overrides)
