import { Alert, AlertDescription, AlertIcon, Flex, FlexProps } from '@chakra-ui/react'

export function CheckoutDisabledAlert({ ...props }: FlexProps) {
  return (
    <Alert status="warning" variant="warning" minH={16} w="full" justifyContent="center">
      <Flex maxW="desktop" w="full" {...props}>
        <AlertIcon />
        <AlertDescription>{process.env.NEXT_PUBLIC_TOAST_MESSAGE}</AlertDescription>
      </Flex>
    </Alert>
  )
}
