import { Flex, Link, SystemStyleInterpolation } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import type { FunctionComponent } from 'react'

import { HEADER_HEIGHT } from 'components/modules/Header'
import Logo from 'components/modules/Header/Logo'
import useNormetSitesLinks from 'hooks/useNormetSitesLinks'

const TAG = 'LoginHeader'

const styles = {
  header: {
    alignItems: 'center',
    bgColor: 'primary.white',
    justifyContent: 'center',
    h: { base: HEADER_HEIGHT.base, md: '70px' },
    py: 7,
  },
  menu: { w: 'full', justifyContent: 'space-between', maxW: 'desktop', px: { base: 6, lg: 10 } },
  link: { display: { base: 'none', md: 'block' } },
} as const satisfies SystemStyleInterpolation

const LoginHeader: FunctionComponent = () => {
  const { t } = useTranslation()
  const { landing } = useNormetSitesLinks({ addUTMParams: true })

  return (
    <Flex data-testid={TAG} as="header" {...styles.header}>
      <Flex {...styles.menu}>
        <Logo />
        {landing.href && (
          <Link variant="ghost" {...styles.link} rel="noopener noreferrer" href={landing.href}>
            {t('back-to-normet-com')}
          </Link>
        )}
      </Flex>
    </Flex>
  )
}

LoginHeader.displayName = TAG

export default LoginHeader
