export const Button = {
  defaultProps: {
    variant: 'primary',
  },
  baseStyle: {
    fontSize: 'lg',
    cursor: 'pointer',
    color: 'black',
    textDecoration: 'none',
    fontWeight: '700',
    _focus: {
      boxShadow: 'none',
    },
  },
  variants: {
    close: {
      position: 'static',
      bgColor: 'secondary.beige.100',
      borderRadius: '50%',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      color: 'primary.red',
    },
    ghost: {
      px: 0,
      py: 0,
      borderRadius: 'none',
      fontWeight: 'normal',
      _hover: {
        bg: 'transparent',
      },
      _active: {
        bg: 'transparent',
      },
    },
    menu: {
      minWidth: 0,
      h: { base: 'auto', md: 9 },
      textAlign: 'left',
      borderRadius: 0,
      fontSize: 'sm',
    },
    outline: {
      color: 'secondary.grey.900',
      backgroundColor: 'primary.white',
      borderColor: 'secondary.grey.900',
      borderRadius: 'sm',
      borderWidth: 2,
      _hover: {
        color: 'primary.white',
        backgroundColor: 'primary.red',
        borderColor: 'primary.red',
        _disabled: {
          backgroundColor: 'primary.white',
          borderColor: 'secondary.grey.900',
        },
      },
    },
    primary: {
      color: 'primary.white',
      backgroundColor: 'primary.red',
      borderRadius: 'sm',
      _hover: {
        bg: 'secondary.red',
        _disabled: {
          bg: 'primary.grey.500',
        },
      },
      _disabled: {
        opacity: 1,
        bg: 'primary.grey.500',
        color: 'secondary.grey.900',
      },
    },
    secondary: {
      color: 'primary.red',
      backgroundColor: 'primary.white',
      borderColor: 'primary.red',
      borderWidth: 2,
      borderRadius: 'sm',
      _hover: {
        color: 'primary.white',
        backgroundColor: 'primary.red',
        _disabled: {
          bg: 'primary.grey.500',
          color: 'secondary.grey.900',
        },
      },
      _disabled: {
        opacity: 1,
        borderColor: 'primary.white',
        bg: 'primary.grey.500',
        color: 'secondary.grey.900',
      },
    },
    link: {
      color: 'primary.red',
      backgroundColor: 'transparent',
      borderRadius: 'none',
      fontWeight: 'normal',
      textAlign: 'inherit',
      padding: 0,
      justifyContent: 'unset',
      minW: 'unset',
      _hover: {
        bg: 'transparent',
        textDecoration: 'underline',
      },
      _active: {
        bg: 'transparent',
        textDecoration: 'underline',
      },
    },
  },
  sizes: {
    lg: { h: '14', minW: '14', fontSize: 'md' },
    md: { h: '12', minW: '24', fontSize: 'md' },
    xs: { h: '8', minW: '8', fontSize: 'md' },
    '2xs': { h: '6', minW: '6', fontSize: 'md' },
  },
}
