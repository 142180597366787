import { commonSizes } from './common-sizes'

export const Input = {
  parts: ['field'],
  baseStyle: {},
  sizes: {
    md: {
      field: {
        ...commonSizes.input.md,
        fontSize: 'sm',
      },
      group: {
        '--input-height': commonSizes.input.md.h,
      },
    },
  },
  variants: {
    primary: {
      _focus: {
        boxShadow: 'none',
      },
    },
    outline: {
      field: {
        borderRadius: 0,
        borderColor: 'greyCCC',
        _focus: {
          borderColor: 'grey999',
          boxShadow: 'none',
        },
      },
    },
  },
  defaultProps: {
    variant: 'outline',
    size: 'md',
  },
}
