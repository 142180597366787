export const Alert = {
  parts: ['container'],
  baseStyle: {
    container: {
      borderRadius: '2px',
    },
  },
  variants: {
    error: {
      container: {
        color: 'primary.black',
        bg: 'secondary.beige.100',
      },
    },
    success: {
      container: {
        color: 'white',
        bg: 'secondary.green',
      },
    },
    warning: {
      container: {
        color: 'primary.black',
        bg: 'secondary.beige.100',
      },
    },
  },
}
