import { Icon, IconProps } from '@chakra-ui/react'

export function CheckIcon(props: IconProps) {
  return (
    <Icon width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M25.86 4.365a3.411 3.411 0 0 1 5.52 4.008l-.062.085-13.216 17.624a6.831 6.831 0 0 1-10.172.835l-.109-.106L1 19.989a3.411 3.411 0 0 1 4.748-4.898l.076.074 6.815 6.815.003.002h.008l13.21-17.617Z"
        fillRule="nonzero"
      />
    </Icon>
  )
}
