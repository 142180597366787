export const intl = {
  lang: 'en',
  currency: {
    code: 'AUD',
    symbol: '$',
    locale: 'en-AU',
  },
  country: 'AU',
  format: { date: 'yyyy-dd-MM' },
}
