import { Alert, AlertDescription, AlertIcon, AlertProps, AlertTitle, useToast } from '@chakra-ui/react'
import { ReactNode, useCallback } from 'react'

import { CheckIcon } from 'components/elements/Icons/CheckIcon'
import { InfoIcon } from 'components/elements/Icons/InfoIcon'

export type ToastProps = Omit<AlertProps, 'status' | 'id'> & {
  duration?: number
  status?: 'success' | 'error' | 'warning'
  title?: string
  description?: ReactNode
  position?: 'top' | 'bottom' | 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left'
  flexDirection?: string
}

export const useToastAlert = (): ((options: ToastProps) => string | number | undefined) => {
  const toast = useToast()

  return useCallback(
    ({ duration = 1500, position, status = 'success', title, description, ...props }: ToastProps) =>
      toast({
        duration,
        position,
        render({ id }) {
          return (
            <Alert
              data-toast-id={id}
              variant={status}
              minW={['100%', '500px']}
              maxW="650px"
              p={[4, 5]}
              fontSize="md"
              {...props}
            >
              <AlertIcon as={status === 'success' ? CheckIcon : InfoIcon} />
              {title && (
                <AlertTitle fontWeight="normal" pr={10}>
                  {title}
                </AlertTitle>
              )}
              {description && <AlertDescription w="full">{description}</AlertDescription>}
            </Alert>
          )
        },
      }),
    [toast],
  )
}
