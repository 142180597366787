export const Modal = {
  baseStyle: {
    dialogContainer: {},
    dialog: {
      p: 4,
      bg: 'primary.white',
      borderRadius: '0',
      boxShadow: '0 0 16px 0 rgba(0,0,0,0.15)',
    },
    header: {
      px: 0,
      py: 0,
      fontSize: '2xl',
      fontWeight: 'bold',
      lineHeight: '8',
      flex: '1',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    closeButton: {
      _focus: {
        boxShadow: 'none',
      },
      position: 'static',
      bgColor: 'secondary.beige.100',
      borderRadius: '50%',
      color: 'primary.red',
      boxSize: 6,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
    },

    body: { px: 0, py: 4 },
    footer: {
      borderStyle: 'solid',
      borderTopWidth: '.25rem',
      borderTopColor: 'gray.100',
      paddingBlockStart: '5',
      px: 0,
      paddingBlockEnd: 0,
      justifyContent: 'space-between',
      gap: 4,

      '& button[type="submit"]': {
        paddingInline: 8,
      },
    },
  },
  variants: {
    warning: {
      p: 100,
      dialog: {
        borderColor: 'secondary.beige.100',
        borderWidth: '4px',
      },
    },
  },
}
