import { chakra } from '@chakra-ui/react'

import { AvailabilityStatus } from 'commercetools/utils/product/getAvailabilityStatus'

type Props = {
  availabilityStatus: AvailabilityStatus
  isMainAvailabilityStatus?: boolean
}

export function AvailabilityCircle({ availabilityStatus, isMainAvailabilityStatus }: Props) {
  const hasBorder = availabilityStatus === AvailabilityStatus.AvailableInOther && isMainAvailabilityStatus
  return (
    <chakra.span
      boxSize={2}
      minW={2}
      fontSize="sm"
      borderRadius="50%"
      bgColor={
        availabilityStatus === AvailabilityStatus.AvailableInCurrent
          ? 'secondary.green'
          : hasBorder
            ? 'transparent'
            : 'secondary.beige.600'
      }
      borderColor="secondary.green"
      borderWidth={hasBorder ? 1 : 0}
    />
  )
}
