import { default as NextHead } from 'next/head'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import normalizePathname from 'utils/normalizePathname'

const TAG = 'CanonicalURL'

/**
 * Separated component because this will optimize Head rendering.
 */
const CanonicalURL: FunctionComponent = () => {
  const { lang } = useTranslation()
  const { asPath } = useRouter()
  const cleanPath = asPath.split('#')[0].split('?')[0]
  const siteUrl = process.env.NEXT_PUBLIC_SITE_URL || process.env.SITE_URL
  // Non-home page should have trailing slash.
  const pathname = siteUrl + normalizePathname(`/${lang}/${cleanPath.endsWith('/') ? cleanPath : `${cleanPath}/`}`) // But home page should not have trailing slash.
  // But home page should not have trailing slash.
  const canonicalUrl = cleanPath === '/' ? `${siteUrl}/${lang}/` : pathname

  if (!siteUrl) {
    console.error('Site URL is invalid. Please set environment variable `NEXT_PUBLIC_SITE_URL` or `SITE_URL`.')
    return null
  }

  if (typeof window !== 'undefined' && location.origin !== siteUrl) {
    console.error(
      `Site URL and location.origin do not match.
       Please set correct environment variable 'NEXT_PUBLIC_SITE_URL' or 'SITE_URL'.`,
    )
    return null
  }

  return (
    <NextHead>
      <link key="canonical" rel="canonical" href={canonicalUrl} />
    </NextHead>
  )
}

CanonicalURL.displayName = TAG

export default CanonicalURL
