import { useMemo } from 'react'

import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'
import { RoutePaths } from 'config/routes'
import getNormetAnalyticsPortalURL from 'utils/getNormetAnalyticsPortalURL'
import getNormetLandingURL from 'utils/getNormetLandingURL'
import getNormetPortalURL from 'utils/getNormetPortalURL'
import getSiteURL from 'utils/getSiteURL'
import { hasBusinessUnitAccessToAnalyticsPortal } from 'utils/hasAccessToAnalyticsPortal'

export type SiteKey = 'portal' | 'store' | 'landing' | 'analytics'

interface Options {
  /**
   * @default false
   */
  addUTMParams?: boolean
}

export const UTM_PARAMS = {
  utm_source: 'store',
  utm_medium: 'referral',
  utm_content: getSiteURL(),
}

function useNormetSitesLinks({ addUTMParams = false }: Options) {
  const { current } = useBusinessUnit()

  const hasAccessToAnalyticsPortal = hasBusinessUnitAccessToAnalyticsPortal(current)

  return useMemo(() => {
    const landingUrl = getNormetLandingURL()
    if (landingUrl && addUTMParams) {
      landingUrl.search = new URLSearchParams(UTM_PARAMS).toString()
    }

    const portalUrl = getNormetPortalURL()
    if (portalUrl && addUTMParams) {
      portalUrl.search = new URLSearchParams(UTM_PARAMS).toString()
    }

    const analyticsUrl = getNormetAnalyticsPortalURL(hasAccessToAnalyticsPortal)

    return {
      portal: { siteKey: 'portal', href: portalUrl?.toString() || '' },
      store: { siteKey: 'store', href: RoutePaths.home },
      analytics: { siteKey: 'analytics', href: analyticsUrl?.toString() || '' },
      landing: { siteKey: 'landing', href: landingUrl?.toString() || '' },
    } as const
  }, [addUTMParams, hasAccessToAnalyticsPortal])
}

export default useNormetSitesLinks
