import type { Channel } from '@commercetools/platform-sdk'
import { createContext, FunctionComponent, ReactNode, useContext, useEffect, useMemo } from 'react'
import { QueryStatus, useQuery } from 'react-query'

import { fetchPriceChannelById } from 'commercetools/api/channels'
import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'
import { useCustomer } from 'commercetools/hooks/use-customer'
import getCompanyName from 'utils/getCompanyName'

interface PriceChannelsProviderProps {
  children: ReactNode
}

export interface UsePriceChannelResult {
  status: QueryStatus
  current: Channel | undefined
  id: string
}

export const PriceChannelsContext = createContext<UsePriceChannelResult>(undefined!)

export const PriceChannelsProvider: FunctionComponent<PriceChannelsProviderProps> = ({ children }) => {
  const [customer] = useCustomer()
  const businessUnit = useBusinessUnit()
  const priceChannelId = businessUnit.current?.custom?.fields?.ctPriceListId?.id

  const response = useQuery(['fetchPriceChannelById', priceChannelId], fetchPriceChannelById, {
    enabled: customer.isSuccess && !!customer.data?.id && businessUnit.status === 'success' && !!priceChannelId,
  })

  useEffect(() => {
    if (businessUnit.status === 'success' && !priceChannelId) {
      // eslint-disable-next-line no-console
      console.warn(
        'No price channel selected for business unit. Please set it in the Commercetools!',
        getCompanyName(businessUnit.current),
      )
    }
  }, [businessUnit, priceChannelId])

  const contextValue = useMemo<UsePriceChannelResult>(
    () =>
      ({
        current: response.data,
        id: response.data?.id || '',
        status: response.status,
      }) satisfies UsePriceChannelResult,
    [response.data, response.status],
  )

  return <PriceChannelsContext.Provider value={contextValue}>{children}</PriceChannelsContext.Provider>
}

export const usePriceChannels = (): UsePriceChannelResult => useContext(PriceChannelsContext)
