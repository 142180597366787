export const Menu = {
  parts: ['list', 'divider', 'item'],
  baseStyle: {
    divider: {
      borderColor: 'primary.grey.500',
    },
    item: {
      _focus: {
        boxShadow: 'none',
        backgroundColor: 'primary.white',
      },
    },
    list: {
      fontSize: 'sm',
      flex: '1 1 auto',
      p: 4,
      borderRadius: 'none',
      border: 'none',
      boxShadow: '0 0 16px 0 rgba(0,0,0,0.15)',
    },
  },
}
