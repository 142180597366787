export const Popover = {
  baseStyle: {
    content: {
      px: 0,
      borderRadius: 'none',
      _focus: {
        boxShadow: 'none',
      },
    },
  },
  variants: {
    round: {
      content: {
        borderRadius: '2px',
        border: 'none',
        boxShadow: '0 0 16px 0 rgba(0,0,0,0.15)',
        _focus: {
          boxShadow: '0 0 16px 0 rgba(0,0,0,0.15)',
        },
      },
    },
  },
}
