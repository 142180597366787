export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID || ''

export function gtagPageView(url: string | undefined) {
  window['dataLayer']?.push({
    event: 'pageView',
    page_path: url,
  })
}

export function gtagEvent(params: Record<string, unknown>) {
  window['dataLayer']?.push({ ...params, _clear: true })
}
