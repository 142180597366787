import { commonSizes } from './common-sizes'

export const Select = {
  defaultProps: { iconColor: 'red' },
  parts: ['field', 'icon'],
  baseStyle: {
    field: {
      _hover: {
        cursor: 'pointer',
        borderColor: 'black',
      },
    },
  },
  sizes: {
    md: {
      icon: {
        fontSize: 'lg',
      },
      field: {
        ...commonSizes.input.md,
      },
    },
  },
  variants: {
    outline: {
      field: {
        borderRadius: 'sm',
        borderWidth: 1,
        borderColor: 'greyCCC',
        backgroundColor: 'white',
        _focus: {
          borderColor: 'grey999',
          boxShadow: 'none',
        },
      },
    },
  },
}
