export const Radio = {
  parts: ['control', 'label'],

  baseStyle: {
    control: {
      _checked: {
        bg: 'white',
        borderColor: 'primary.black',
        _hover: {
          bg: 'transparent',
        },
        _focus: {
          boxShadow: 'none',
        },
        _before: {
          bg: 'primary.black',
          w: '50%',
          h: '50%',
        },
      },
    },
  },
  defaultProps: {
    colorScheme: 'gray',
  },
  variants: {
    primary: {
      control: {
        bg: 'white',
        borderColor: 'greyCCC',
        borderWidth: '1px',
        _checked: {
          borderColor: 'greyCCC',
          _hover: {
            bg: 'transparent',
          },
          _focus: {
            boxShadow: 'none',
          },
          _before: {
            bg: 'primary.red',
            w: '50%',
            h: '50%',
          },
        },
        _disabled: {
          backgroundColor: 'white',
          borderColor: 'greyCCC',
        },
      },
      label: {
        fontWeight: 'bold',
        _disabled: {
          fontWeight: 'bold',
        },
      },
    },
  },
  sizes: {
    lg: {
      control: { w: 6, h: 6 },
      label: { fontSize: 'sm' },
    },
  },
}
