import { Icon, IconProps } from '@chakra-ui/react'

export function PlusIcon(props: IconProps) {
  return (
    <Icon width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M16.25,0 C17.4482607,0 18.4277457,0.951558068 18.4961805,2.15141121 L18.5,2.28571429 L18.5,29.7142857 C18.5,30.9766509 17.4926407,32 16.25,32 C15.0517393,32 14.0722543,31.0484419 14.0038195,29.8485888 L14,29.7142857 L14,2.28571429 C14,1.02334914 15.0073593,0 16.25,0 Z" />
      <path d="M29.7142857,13.5 C30.9766509,13.5 32,14.5073593 32,15.75 C32,16.9482607 31.0484419,17.9277457 29.8485888,17.9961805 L29.7142857,18 L2.28571429,18 C1.02334914,18 0,16.9926407 0,15.75 C0,14.5517393 0.951558068,13.5722543 2.15141121,13.5038195 L2.28571429,13.5 L29.7142857,13.5 Z" />
    </Icon>
  )
}
