import { ErpLegalEntity } from 'commercetools/types'

type IntlObject = {
  lang: string
  currency: {
    code: string
    symbol: string
    locale: string
  }
  country: string
  format: { date: string }
}

export const intlMap = new Map<ErpLegalEntity, IntlObject>([
  [
    ErpLegalEntity.AU,
    {
      lang: 'en',
      currency: {
        code: 'AUD',
        symbol: '$',
        locale: 'en-AU',
      },
      country: 'AU',
      format: { date: 'yyyy-dd-MM' },
    },
  ],
  [
    ErpLegalEntity.US,
    {
      lang: 'en',
      currency: {
        code: 'USD',
        symbol: '$',
        locale: 'en-US',
      },
      country: 'US',
      format: { date: 'yyyy-dd-MM' },
    },
  ],
  [
    ErpLegalEntity.CA,
    {
      lang: 'en',
      currency: {
        code: 'CAD',
        symbol: '$',
        locale: 'en-CA',
      },
      country: 'CA',
      format: { date: 'yyyy-dd-MM' },
    },
  ],
])

export const getIntlConfig = (erpLegalEntity: string): IntlObject | undefined => intlMap.get(parseInt(erpLegalEntity))
