import { Icon, IconProps } from '@chakra-ui/react'

export function CartIcon(props: IconProps) {
  return (
    <Icon width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M29.588,6.66666664 C30.2113516,6.66666664 30.799068,6.95714148 31.1774878,7.4524204 C31.5471508,7.93623841 31.6758206,8.56138924 31.5288033,9.15074735 L31.5178327,9.19278109 L28.7805306,19.2273333 C28.2326014,21.2373707 26.4199268,22.6381583 24.3417228,22.6662367 L24.2786667,22.6666666 L7.82533333,22.6666666 C7.4571435,22.6666666 7.15866667,22.3681898 7.15866667,22 C7.15866667,21.6399921 7.44402526,21.3466334 7.80089313,21.333773 L7.82533333,21.3333333 L24.2785806,21.3333333 C25.7667099,21.3331411 27.0724449,20.3469341 27.4820307,18.9199292 L27.4941673,18.8765522 L30.2314694,8.84199999 C30.2861185,8.64152391 30.2441656,8.42703039 30.1180107,8.26191789 C29.9976201,8.10434958 29.8136077,8.00894515 29.6162755,8.00059757 L29.588,7.99999997 L4.73733333,7.99999997 C4.3691435,7.99999997 4.07066667,7.70152314 4.07066667,7.33333331 C4.07066667,6.97332547 4.35602526,6.6799667 4.71289313,6.66710632 L4.73733333,6.66666664 L29.588,6.66666664 Z" />
      <path d="M1.17066667,0 C2.73113811,0 4.08041963,1.08223048 4.42231819,2.60053227 L4.43236959,2.64667422 L8.87370133,23.7453333 C9.06589695,24.6583111 9.86302237,25.3153795 10.7923998,25.3329714 L10.8306667,25.3333333 L26,25.3333333 C26.3681898,25.3333333 26.6666667,25.6318102 26.6666667,26 C26.6666667,26.3600078 26.3813081,26.6533666 26.0244402,26.666227 L26,26.6666667 L10.8306667,26.6666667 C9.27019523,26.6666667 7.9209137,25.5844362 7.57901514,24.0661344 L7.56896375,24.0199924 L3.12763201,2.92133333 C2.93543638,2.00835559 2.13831096,1.35128719 1.2089335,1.3336953 L1.17066667,1.33333333 L0.666666667,1.33333333 C0.298476833,1.33333333 0,1.0348565 0,0.666666667 C0,0.30665883 0.285358592,0.0133000632 0.642226467,0.000439677462 L0.666666667,0 L1.17066667,0 Z" />
      <path d="M10,26.6666667 C8.52724067,26.6666667 7.33333333,27.860574 7.33333333,29.3333333 C7.33333333,30.8060927 8.52724067,32 10,32 C11.4727593,32 12.6666667,30.8060927 12.6666667,29.3333333 C12.6666667,27.860574 11.4727593,26.6666667 10,26.6666667 Z M10,28 C10.7363797,28 11.3333333,28.5969537 11.3333333,29.3333333 C11.3333333,30.069713 10.7363797,30.6666667 10,30.6666667 C9.26362033,30.6666667 8.66666667,30.069713 8.66666667,29.3333333 C8.66666667,28.5969537 9.26362033,28 10,28 Z" />
      <path d="M22,27.0239322 C21.1749304,27.5002863 20.6666667,28.3806251 20.6666667,29.3333333 C20.6666667,30.8060927 21.860574,31.9999999 23.3333333,31.9999999 C24.8060927,31.9999999 26,30.8060927 26,29.3333333 C26,28.3806251 25.4917362,27.5002863 24.6666667,27.0239322 C23.8415971,26.547578 22.8250695,26.547578 22,27.0239322 Z M24,28.1786327 C24.4125348,28.4168098 24.6666667,28.8569792 24.6666667,29.3333333 C24.6666667,30.069713 24.069713,30.6666666 23.3333333,30.6666666 C22.5969536,30.6666666 22,30.069713 22,29.3333333 C22,28.8569792 22.2541319,28.4168098 22.6666667,28.1786327 C23.0792014,27.9404556 23.5874652,27.9404556 24,28.1786327 Z" />
    </Icon>
  )
}
