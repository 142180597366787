import { InteractionStatus } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'

import { AuthInitContextValue, AuthStatus } from 'components/modules/Auth/AuthInit/context'

type UseMSALSession = AuthInitContextValue['status']

/**
 * Custom hook to check the status of the MSAL session.
 * @returns The status of the MSAL session.
 */
function useMSALSessionStatus(): UseMSALSession {
  const msal = useMsal()
  const user = msal.instance.getActiveAccount()

  if (user) {
    return AuthStatus.Authenticated
  } else if (msal.inProgress !== InteractionStatus.None) {
    return AuthStatus.Loading
  }

  return AuthStatus.Unauthenticated
}

export default useMSALSessionStatus
