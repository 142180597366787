const NORMET_SITE_URL = process.env.NORMET_SITE_URL

/**
 * Retrieves the Normet landing URL from the environment variable `NORMET_SITE_URL`.
 *
 * @returns {URL | undefined} The Normet landing URL as a URL object if the environment variable is set and valid, otherwise `undefined`.
 *
 * @remarks
 * - If the `NORMET_SITE_URL` environment variable is not set, an error message is logged and `undefined` is returned.
 * - If the `NORMET_SITE_URL` cannot be parsed into a valid URL, an error message is logged and `undefined` is returned.
 */
function getNormetLandingURL(): URL | undefined {
  if (!NORMET_SITE_URL) {
    console.error('Could not find URL. Please set environment variable NORMET_SITE_URL.')
    return undefined
  }

  try {
    return new URL(NORMET_SITE_URL)
  } catch (error) {
    console.error('Could not create a URL from NORMET_SITE_URL:', error)
    return undefined
  }
}

export default getNormetLandingURL
