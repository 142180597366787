import { ContactFormSubject, ITEMS_MISSING_PRICE_LIMIT } from 'pages/contact-form'

function getRequestPriceUrl(skus?: ReadonlyArray<string | undefined> | string | undefined) {
  const items = (Array.isArray(skus) ? skus : [skus]).filter(Boolean)

  return (
    '/contact-form?' +
    new URLSearchParams({
      subject: `${ContactFormSubject.RequestPrice}`,
      skus: items.slice(0, ITEMS_MISSING_PRICE_LIMIT).join('","'),
    }).toString()
  )
}

export default getRequestPriceUrl
