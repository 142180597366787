import { Icon, SystemStyleInterpolation, Text, HStack } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import ChevronIcon from 'public/icons/chevron-down.svg?component'
import MoreIcon from 'public/icons/more.svg?component'

const TAG = 'MobileSitesMenuButton'

const styles = {
  wrapper: { justifyContent: 'space-between', width: 'full', h: 12 },
  text: { fontWeight: 'bold' },
  icon: { ml: -1, mr: 1, boxSize: 6 },
} as const satisfies SystemStyleInterpolation

const MobileSitesMenuButton: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <HStack {...styles.wrapper}>
      <HStack spacing={3}>
        <Icon aria-hidden="true" as={MoreIcon} {...styles.icon} />
        <Text {...styles.text}>{t('normet-websites')}</Text>
      </HStack>
      <Icon aria-hidden="true" as={ChevronIcon} boxSize={4} />
    </HStack>
  )
}

MobileSitesMenuButton.displayName = TAG

export default MobileSitesMenuButton
