import { Channel, ChannelPagedQueryResponse } from '@commercetools/platform-sdk'
import { QueryFunctionContext } from 'react-query'

import { buildURLSearchParams } from 'commercetools/utils/buildURLSearchParams'

import client from '../client'

type ArgumentsBranches = QueryFunctionContext<[queryFnName: 'fetchProjectBranches', erpLegalEntity: string | undefined]>

export function fetchProjectBranches({
  signal,
  queryKey: [, erpLegalEntity],
}: ArgumentsBranches): Promise<ChannelPagedQueryResponse> {
  const params = { where: `custom(fields(erpLegalEntity="${erpLegalEntity}"))` }
  return client.get<ChannelPagedQueryResponse>(`/channels?${buildURLSearchParams(params)}`, void 0, { signal })
}

type ArgumentsPriceChannelById = QueryFunctionContext<
  [queryFnName: 'fetchPriceChannelById', channelId: string | undefined]
>
export function fetchPriceChannelById({
  signal,
  queryKey: [_, channelId],
}: ArgumentsPriceChannelById): Promise<Channel> {
  return client.get<Channel>(`/channels/${channelId}`, void 0, { signal })
}
