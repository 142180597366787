import React from 'react'

import LoadProgressBar from 'components/elements/LoadProgressBar'
import GuardNotAuthorized from 'components/modules/Auth/GuardNotAuthorized'
import Head from 'components/modules/Head'
import SEO from 'components/modules/SEO'

export interface LayoutNotAuthorizedProps {
  seo?: Partial<SEO>
}

const LayoutNotAuthorized: LayoutComponent<LayoutNotAuthorizedProps> = ({ seo }, page) => (
  <>
    <LoadProgressBar />
    <Head />
    <SEO {...seo} />
    <GuardNotAuthorized>{page}</GuardNotAuthorized>
  </>
)

function getLayoutNotAuthorized(props: LayoutNotAuthorizedProps = {}): GetLayout {
  return (page) => LayoutNotAuthorized(props, page)
}

export default getLayoutNotAuthorized
