import { Link, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'

/**
 * Allows user to change the cookie consent settings, managed by Cookiebot.
 * Cookiebot is a third-party service that was added to the website by the Google Tag Manager.
 */
function onCookieConsentClick() {
  if (window.Cookiebot && typeof window.Cookiebot.renew === 'function') {
    return window.Cookiebot.renew()
  }
  console.error('Cookiebot is not configured. Please check the Google Tag Manager configuration.')
}

export const CookiebotRenewLink = () => {
  const { t } = useTranslation('common')

  return (
    <Link as={Text} onClick={onCookieConsentClick}>
      {t('cookiebot-renew-link-text')}
    </Link>
  )
}
