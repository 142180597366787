import { SystemStyleInterpolation, Flex, List, Stack, ListItem, Divider, Link } from '@chakra-ui/react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'
import { AccountButton } from 'components/modules/Account/AccountButton'
import { BranchesButton } from 'components/modules/Branches/BranchesButton'
import type { MenuType } from 'components/modules/Header/MobileMenu'
import MobileSitesMenuButton from 'components/modules/Header/MobileMenu/MobileSitesMenu/MobileSitesMenuButton'
import { RoutePaths } from 'config/routes'

interface MobileMenuProps {
  setActiveMenu: (menu: MenuType) => void
  onClose: () => void
}

const LINKS = [
  { href: RoutePaths.myEquipment, title: 'equipment' },
  { href: RoutePaths.myOrderHistoryB2B, title: 'order-history' },
  { href: RoutePaths.myOrderTemplate, title: 'order-templates' },
]

const TAG = 'MobileMainMenu'

const styles = {
  list: {
    minW: '100vw',
    borderWidth: 0,
    boxShadow: 'none',
    py: 3,
  },
  divider: {
    borderBottomWidth: '1px',
    margin: 0,
  },
  menuItem: {
    px: 4,
    py: 2,
    direction: 'column',
    textAlign: 'start',
    alignItems: 'flex-start',
    justifyContent: 'center',
    cursor: 'pointer',
    _hover: { bg: 'gray.100' },
    _focus: { bg: 'transparent' },
    _active: { bg: 'transparent' },
  },
} as const satisfies SystemStyleInterpolation

const MobileMainMenu: FunctionComponent<MobileMenuProps> = ({ setActiveMenu }) => {
  const { t } = useTranslation()
  const businessUnit = useBusinessUnit()
  const router = useRouter()
  const cleanPath = router.asPath.split('#')[0].split('?')[0]

  return (
    <Stack as={List} {...styles.list}>
      {LINKS.map(({ href, title }) => {
        return (
          <React.Fragment key={title}>
            <Link
              as={NextLink}
              href={href}
              variant="ghost"
              aria-current={cleanPath === href ? 'page' : undefined}
              {...styles.menuItem}
              rel="noreferrer"
            >
              {t(title)}
            </Link>
          </React.Fragment>
        )
      })}

      <Divider {...styles.divider} />
      <ListItem as={Flex} onClick={() => setActiveMenu('profile')} {...styles.menuItem}>
        <AccountButton currentCompany={businessUnit.current} />
      </ListItem>
      <Divider {...styles.divider} />
      <ListItem as={Flex} onClick={() => setActiveMenu('branches')} {...styles.menuItem} py={4}>
        <BranchesButton currentCompany={businessUnit.current} />
      </ListItem>
      <Divider {...styles.divider} />
      <ListItem as={Flex} onClick={() => setActiveMenu('sites')} {...styles.menuItem} py={1}>
        <MobileSitesMenuButton />
      </ListItem>
      <Divider {...styles.divider} />
    </Stack>
  )
}

MobileMainMenu.displayName = TAG

export default MobileMainMenu
