import { Button, Icon, Menu, MenuButton, MenuList, MenuProps, Skeleton, Text, useMediaQuery } from '@chakra-ui/react'
import { BusinessUnit } from '@commercetools/platform-sdk'
import useTranslation from 'next-translate/useTranslation'

import { useBranches } from 'commercetools/hooks/use-branches'
import { getLocalizedString } from 'commercetools/utils/getLocalizedString'
import { LocalizationIcon } from 'components/elements/Icons/LocalizationIcon'
import { MenuDrawer } from 'components/elements/MenuDrawer/MenuDrawer'
import { BranchesSelect } from 'components/modules/Branches/BranchesSelect'
import ChevronIcon from 'public/icons/chevron-down.svg?component'
import { breakpoints } from 'theme/foundations/breakpoints'

type BranchesButtonProps = Omit<MenuProps, 'children'> & {
  currentCompany?: BusinessUnit
}

const styles = {
  menu: { fontWeight: 'normal', w: { base: 'full', lg: '160px' }, p: 0 },
  rightIcon: { boxSize: 4, display: { base: 'block', lg: 'none' } },
  leftIcon: { color: { base: 'inherit', lg: 'primary.white' }, mr: '6px' },
  skeleton: { h: 4, w: '100px' },
  text: { fontWeight: { base: 'bold', lg: 'inherit' }, color: { base: 'inherit', lg: 'primary.white' }, maxW: '150px' },
  menuList: { display: { base: 'none', lg: 'inherit' } },
} as const

export const BranchesButton = function ({ currentCompany, ...props }: BranchesButtonProps) {
  const { t, lang } = useTranslation('common')
  const { all, current, status } = useBranches()

  const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.lg})`)

  return (
    <Menu closeOnBlur={isDesktop} gutter={0} {...props}>
      {({ isOpen, onClose }) => (
        <>
          <MenuButton
            style={{ display: all.length === 1 ? 'none' : '' }}
            isActive={isOpen}
            as={Button}
            variant="menu"
            iconSpacing={3}
            leftIcon={<LocalizationIcon aria-hidden="true" {...styles.leftIcon} />}
            rightIcon={<Icon aria-hidden="true" as={ChevronIcon} {...styles.rightIcon} />}
            {...styles.menu}
          >
            {status === 'loading' ? (
              <Skeleton {...styles.skeleton} />
            ) : (
              <Text isTruncated {...styles.text}>
                {getLocalizedString(current?.name || '', lang)}
              </Text>
            )}
          </MenuButton>
          {status === 'success' && (
            <>
              <MenuDrawer title={t('account')} isOpen={isOpen} onClose={onClose}>
                <BranchesSelect currentCompany={currentCompany} onClose={onClose} />
              </MenuDrawer>
              <MenuList {...styles.menuList}>
                <BranchesSelect currentCompany={currentCompany} onClose={onClose} isOpen={isOpen} />
              </MenuList>
            </>
          )}
        </>
      )}
    </Menu>
  )
}
