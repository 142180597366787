import { Icon, Img, Link, useBreakpointValue } from '@chakra-ui/react'
import NextLink from 'next/link'
import type { FunctionComponent } from 'react'

import { RoutePaths } from 'config/routes'
import LogoIcon from 'public/icons/logo.svg?component'
import LogoReversedIcon from 'public/icons/logoReversed.svg?component'

interface LogoProps {
  isCheckout?: boolean
  adjustMobile?: boolean
}

const TAG = 'Logo'

const Logo: FunctionComponent<LogoProps> = ({ adjustMobile, isCheckout }) => {
  const isMobile = useBreakpointValue<boolean>({ base: true, lg: false }, { fallback: 'lg' })

  return (
    <Link
      as={NextLink}
      display="inline-block"
      py={{ base: 4, lg: 0 }}
      href={RoutePaths.home}
      aria-label="Normet Store"
      data-testid={TAG}
    >
      {!isMobile && isCheckout ? (
        <Img width={{ base: 77, md: 116 }} src="/images/logo-reverse.png" alt="Normet" />
      ) : (
        <Icon
          as={(isMobile && adjustMobile) || isCheckout ? LogoReversedIcon : LogoIcon}
          aria-hidden="true"
          width={{ base: 77, lg: 116 }}
          height="auto"
          display="flex"
        />
      )}
    </Link>
  )
}

Logo.displayName = TAG

export default Logo
