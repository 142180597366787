import { Icon, IconProps } from '@chakra-ui/react'

export function ChevronRightIcon(props: IconProps) {
  return (
    <Icon width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g stroke="none" strokeWidth="1" fill="currentColor" fillRule="evenodd">
        <g transform="translate(-224.000000, -230.000000)" fill="currentColor" fillRule="nonzero">
          <g transform="translate(240.000000, 246.000000) rotate(-90.000000) translate(-240.000000, -246.000000) translate(224.000000, 230.000000)">
            <g transform="translate(0.000000, 5.652385)">
              <path d="M0.336136596,4.28475134 L14.9978073,20.9057544 L14.9978073,20.9057544 C15.2646611,21.1878131 15.6358356,21.347615 16.0241242,21.347615 C16.4124129,21.347615 16.7835874,21.1878131 17.0504412,20.9057544 L31.7121119,4.28475134 C31.9297594,4.00985748 32.0303244,3.66027443 31.9920165,3.31174956 C31.9628208,2.95297077 31.7897514,2.62125442 31.51218,2.39206294 L29.0463536,0.326100253 C28.7742295,0.0907133686 28.4184999,-0.0246583902 28.060023,0.00620925625 C27.7032858,0.0320172739 27.3718892,0.200116993 27.1403364,0.47271696 L16.2507137,13.068425 C16.1104576,13.2073407 15.8844757,13.2073407 15.7442196,13.068425 L4.85459693,0.47271696 C4.63913131,0.215353007 4.33449776,0.0487565299 4.00155427,0.00620925625 C3.64493751,-0.0283219923 3.28943497,0.0821720388 3.01522369,0.312771462 L0.482753303,2.39206294 C0.205181922,2.62125442 0.0321125239,2.95297077 0.00291680735,3.31174956 C-0.0206534255,3.66760154 0.0993656637,4.01805729 0.336136596,4.28475134 Z" />
            </g>
          </g>
        </g>
      </g>
    </Icon>
  )
}
