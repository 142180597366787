import { ErrorObject } from '@commercetools/platform-sdk'
import { useQuery, UseQueryResult } from 'react-query'

import { searchForEquipmentProducts } from 'bom/api/product'
import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'

const DEFAULT_PAGE_SIZE = 20

export function useEquipmentProductSearch(
  searchQuery: string,
  equipmentId: string,
  page = 1,
  limit = DEFAULT_PAGE_SIZE,
  position?: string,
): UseQueryResult<BOM.EquipmentSearchResult, ErrorObject> {
  const businessUnit = useBusinessUnit()

  return useQuery(
    [
      'searchForEquipmentProducts',
      businessUnit.erpAccountNumber,
      searchQuery,
      equipmentId,
      limit,
      limit * (page - 1),
      position,
    ],
    searchForEquipmentProducts,
    {
      enabled: Boolean(businessUnit.erpAccountNumber) && !!equipmentId,
      select: (data) => {
        return {
          ...data,
          equipmentSkus: data.components
            ?.map((product: BOM.EquipmentProduct) => `${businessUnit.erpLegalEntity}-${product.id}`)
            .join('","'),
        }
      },
      onError: (error) => {
        console.error(error)
      },
    },
  )
}
