import { Box, Flex, Link, Stack, SystemStyleInterpolation, Text } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { FunctionComponent } from 'react'

import { CookiebotRenewLink } from 'components/elements/CookiebotRenewLink/CookiebotRenewLink'

type FooterProps = {
  bgColor?: string
  hideContactLink?: boolean
}

const styles = {
  footer: { w: 'full', display: 'flex', justifyContent: 'center', px: { base: 6, lg: 10 } },
  wrapper: {
    marginTop: 6,
    borderTop: '4px solid #333333',
    w: 'full',
    justifyContent: 'space-between',
    direction: { base: 'column', md: 'row' },
    maxW: '1360px',
    py: 4,
    gap: 2,
  },
  stack: { py: 4, direction: { base: 'column-reverse', md: 'row' }, w: 'full', justifyContent: 'space-between' },
  links: { direction: { base: 'column', md: 'row' }, spacing: { base: '2', md: '10' } },
} as const satisfies SystemStyleInterpolation

const Footer: FunctionComponent<FooterProps> = ({ bgColor = 'primary.grey.100', hideContactLink }) => {
  const router = useRouter()
  const { t } = useTranslation('common')

  const copyright = `${new Date().getFullYear()} Normet`

  const redirectToContact = () => router.push('/contact-form/')

  return (
    <Box as="footer" bgColor={bgColor} {...styles.footer}>
      <Flex {...styles.wrapper}>
        <Text>&copy;&nbsp;{copyright}</Text>
        <Stack {...styles.links}>
          {!hideContactLink && <Link onClick={redirectToContact}>{t('customer-service')}</Link>}
          <Link href="/files/Privacy_Notice_Customer_and_Supplier.pdf" target="_blank">
            {t('privacy.title')}
          </Link>
          <CookiebotRenewLink />
        </Stack>
      </Flex>
    </Box>
  )
}

export default Footer
