import { LineItem } from '@commercetools/platform-sdk'

import { getLocalizedString } from 'commercetools/utils/getLocalizedString'
import { events } from 'gtm/events'
import { gtagEvent } from 'gtm/gtag'
import { formatDecimalPrice } from 'utils/formatDecimalPrice'

export const trackRemoveProductFromCart = (lineItem: LineItem | undefined, lang: string, quantity?: number) => {
  const { currencyCode, centAmount, fractionDigits } = lineItem?.price?.value || {}
  const formattedPrice = formatDecimalPrice(centAmount, fractionDigits)
  const _quantity = quantity || lineItem?.quantity || 0

  gtagEvent({
    event: events.removeFromCart,
    ecommerce: {
      currency: currencyCode,
      value: _quantity * formattedPrice,
      items: [
        {
          item_id: lineItem?.variant?.sku,
          item_name: getLocalizedString(lineItem?.name ?? '', lang),
          quantity: _quantity,
          price: formattedPrice,
        },
      ],
    },
  })
}
