import { ProductProjection, ProductVariant } from '@commercetools/platform-sdk'

import { getVariantAttributeValue } from './getVariantAttributeValue'

const getProductVariantFromQueryResponse = (
  product: ProductProjection,
  erpLegalEntity: string,
): ProductVariant | undefined => {
  return product?.masterVariant &&
    getVariantAttributeValue<{ key: string; label: string }>(product?.masterVariant, 'erpLegalEntity')?.key ===
      erpLegalEntity
    ? product.masterVariant
    : product.variants?.find(
        (_) => getVariantAttributeValue<{ key: string; label: string }>(_, 'erpLegalEntity')?.key === erpLegalEntity,
      )
}

const getProductVariantFromSearchResponse = (product: ProductProjection): ProductVariant | undefined => {
  return product.masterVariant?.isMatchingVariant
    ? product.masterVariant
    : product.variants.find((_) => _.isMatchingVariant)
}

export const getProductVariant = (
  product: ProductProjection | undefined,
  erpLegalEntity?: string,
): ProductVariant | undefined => {
  if (!product) {
    return
  }
  return erpLegalEntity
    ? getProductVariantFromQueryResponse(product, erpLegalEntity)
    : getProductVariantFromSearchResponse(product)
}
