import { Menu, MenuButton, Stack, SystemStyleInterpolation, IconButton, Icon } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useEffect, useMemo, useRef } from 'react'

import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'
import { ChevronRightIcon } from 'components/elements/Icons/ChevronRightIcon'
import { CloseIcon } from 'components/elements/Icons/CloseIcon'
import { AccountMenu } from 'components/modules/Account/AccountMenu'
import { BranchesSelect } from 'components/modules/Branches/BranchesSelect'
import MobileMainMenu from 'components/modules/Header/MobileMenu/MobileMainMenu'
import MobileSitesMenu from 'components/modules/Header/MobileMenu/MobileSitesMenu'
import MenuIcon from 'public/icons/menu.svg?component'
import { breakpoints } from 'theme/foundations/breakpoints'

export type MenuType = 'main' | 'profile' | 'sites' | 'branches'

const SCREEN_SIZE_LG = Number(breakpoints.lg.replace('px', ''))

interface MobileMenuProps {
  onClose: () => void
  onToggle: () => void
  isOpen: boolean
}

const TAG = 'MobileMenu'

const styles = {
  menuButton: { display: { lg: 'none' } },
  button: {
    color: 'primary.white',
    borderRightWidth: 1,
    borderRadius: 0,
    borderColor: 'rgba(255,255,255,0.15)',
    minW: 6,
    h: 12,
    pr: 4,
  },
  menu: {
    w: 'full',
    h: '100vh',
    bg: 'primary.white',
    pos: 'fixed',
    mx: '0! important',
    left: 0,
    top: 12,
    zIndex: 'modal',
    pt: 2,
    pb: 6,
    overflowY: 'auto',
  },
  menuWrapper: {
    fontSize: 'sm',
  },
  listItem: {
    px: 4,
    fontSize: '14px',
    lineHeight: '24px',
    cursor: 'pointer',
    _hover: { textDecoration: 'underline' },
  },
  accountMenu: { px: 4, py: 6 },
  chevronIcon: { boxSize: 4, transform: 'rotate(180deg)' },
} as const satisfies SystemStyleInterpolation

const MobileMenu: FunctionComponent<MobileMenuProps> = ({ isOpen, onClose, onToggle }) => {
  const { t } = useTranslation()
  const btnRef = useRef<HTMLButtonElement>(null)
  const [activeMenu, setActiveMenu] = React.useState<MenuType>('main')

  const businessUnit = useBusinessUnit()

  const showBackButton = useMemo(
    () => activeMenu === 'profile' || activeMenu === 'sites' || activeMenu === 'branches',
    [activeMenu],
  )

  const handleToggle = () => {
    onToggle()
    // "Reset" the menu to initial state on close
    if (isOpen) {
      setActiveMenu('main')
    }
  }

  useEffect(() => {
    const closeOnResize = () => {
      if (window.innerWidth < SCREEN_SIZE_LG) {
        setActiveMenu('main')
        onClose()
      }
    }
    window.addEventListener('resize', closeOnResize)
    closeOnResize()
    return () => window.removeEventListener('resize', closeOnResize)
  }, [onClose])

  const menu = useMemo(() => {
    const onOptionSelected = () => {
      onClose()
      setActiveMenu('main')
    }

    switch (activeMenu) {
      case 'main':
        return <MobileMainMenu setActiveMenu={setActiveMenu} onClose={onClose} />
      case 'profile':
        return <AccountMenu currentCompany={businessUnit.current} variant="mobile" {...styles.accountMenu} />
      case 'branches':
        return <BranchesSelect currentCompany={businessUnit.current} onClose={onClose} />
      case 'sites':
        return <MobileSitesMenu onSelect={onOptionSelected} />
      default:
        return null
    }
  }, [activeMenu, businessUnit, onClose])

  return (
    <Menu closeOnBlur={false} gutter={0}>
      <MenuButton
        as={IconButton}
        isActive={isOpen}
        data-testid={`${TAG}-button`}
        ref={btnRef}
        variant="ghost"
        aria-haspopup="dialog"
        aria-expanded={isOpen}
        onClick={handleToggle}
        {...styles.menuButton}
        {...styles.button}
        {...(!isOpen && { border: 'none' })}
        // Hover state is not removed: https://github.com/chakra-ui/chakra-ui/issues/6173#issuecomment-1247459629
        sx={{ _hover: {} }}
      >
        <Icon
          aria-hidden="true"
          as={isOpen ? CloseIcon : MenuIcon}
          boxSize={isOpen ? 4 : 6}
          // Aligning the icon to the center of the button
          {...(!isOpen && { mt: 1.5 })}
        />
      </MenuButton>

      {isOpen && showBackButton && (
        <IconButton
          variant="ghost"
          aria-label={t('components.header.mobileButton.goBack')}
          icon={<Icon aria-hidden="true" as={ChevronRightIcon} {...styles.chevronIcon} />}
          onClick={() => setActiveMenu('main')}
          {...styles.button}
          pl={4}
        />
      )}

      <Stack display={{ base: isOpen ? 'flex' : 'none', lg: 'none' }} {...styles.menu}>
        <Stack spacing={5} {...styles.menuWrapper}>
          {menu}
        </Stack>
      </Stack>
    </Menu>
  )
}

export default MobileMenu
