export const AccountMenu = {
  parts: ['divider', 'link'],
  baseStyle: {
    fontSize: 'sm',
    link: {
      color: 'primary.black',
      fontSize: 'inherit',
      _hover: {
        color: 'primary.red',
        textDecoration: 'none',
        fontWeight: 'bold',
      },
      _activeLink: {
        color: 'primary.red',
        fontWeight: 'bold',
      },
    },
    divider: {
      borderBottomWidth: 4,
    },
  },
  sizes: {
    sm: {
      link: {
        py: 1,
      },
    },
    md: {
      fontSize: 'md',
      p: 6,
      link: {
        py: 2,
      },
    },
  },
  defaultProps: {
    size: 'sm',
  },
}
