import { Link, Stack, StackProps, Text, VStack } from '@chakra-ui/react'
import { LineItem } from '@commercetools/platform-sdk'
import NextLink from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import { getLocalizedString } from 'commercetools/utils/getLocalizedString'
import { getAttributeValue } from 'commercetools/utils/product/getAttributeValue'
import { Availability } from 'components/modules/Availability/Availability'
import { Price } from 'components/modules/Price/Price'
import { QuantityInput } from 'components/modules/QuantityInput/QuantityInput'
import { pageTypes } from 'gtm/pageTypes'

type Props = StackProps & {
  lineItem: LineItem
  variant?: 'basic' | 'mini-cart'
}

export function CartLineItem({ lineItem, variant = 'basic', ...props }: Props) {
  const isMiniCart = variant === 'mini-cart'
  const { lang } = useTranslation()
  const partNumber = getAttributeValue(lineItem?.variant?.attributes, 'itemNumber')
  const slug = getLocalizedString(lineItem.productSlug, lang) || lineItem?.productSlug?.en

  return (
    <Stack
      justifyContent="space-between"
      direction={{ base: 'column', md: 'row' }}
      width="full"
      pb={isMiniCart ? 4 : 0}
      minH="75px"
      mb={4}
      borderBottomWidth={1}
      borderColor="primary.grey.500"
      {...props}
    >
      <VStack spacing={{ base: 1, md: isMiniCart ? 1 : 2 }} alignItems="start">
        <Text>{partNumber}</Text>
        <Link
          as={NextLink}
          variant="ghost"
          fontSize="sm"
          fontWeight="bold"
          textTransform="uppercase"
          href={`/product/${slug}`}
        >
          {getLocalizedString(lineItem.name, lang)}
        </Link>
        <Availability fontSize="xs" partNumber={partNumber} availability={lineItem.variant.availability} />
      </VStack>
      <Stack
        direction={isMiniCart ? 'column' : 'row'}
        spacing={2}
        alignItems="start"
        justifyContent="start"
        alignSelf={{ base: 'end', md: 'start' }}
        w={{ base: 'full', md: 'min-content' }}
      >
        <Price
          alignSelf={isMiniCart ? 'end' : 'start'}
          mt={isMiniCart ? '0px' : '12px'}
          mr={{ base: 'auto', md: isMiniCart ? 0 : 4 }}
          price={lineItem.price.value}
        />

        <QuantityInput
          showExceedsQuantityInStockMessage
          showTrashButton
          gtagPageType={isMiniCart ? pageTypes.miniCart : pageTypes.cart}
          isMiniCart={isMiniCart}
          lineItem={lineItem}
        />
      </Stack>
    </Stack>
  )
}
