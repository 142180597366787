import { Button, ButtonProps, Icon, SystemStyleInterpolation } from '@chakra-ui/react'

import SearchMobileIcon from 'public/icons/searchMobile.svg?component'

const styles = {
  button: { position: 'relative', verticalAlign: 'middle' },
  icon: { boxSize: 5, color: 'white' },
} as const satisfies SystemStyleInterpolation

const TAG = 'SearchButton'

export function SearchButton(props: ButtonProps) {
  return (
    <Button
      {...props}
      data-testid={TAG}
      variant="menu"
      leftIcon={<Icon as={SearchMobileIcon} {...styles.icon} />}
      {...styles.button}
    />
  )
}
