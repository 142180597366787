import type { AccountInfo } from '@azure/msal-browser'
import { createContext } from 'react'

export enum AuthStatus {
  Authenticated = 'authenticated',
  Unauthenticated = 'unauthenticated',
  Loading = 'loading',
}

export interface AuthInitBase {
  /**
   * Invoke this function to invalidate the current customer object.
   */
  invalidate: () => Promise<void>
}

export interface Authenticated {
  /**
   * Contains the user's information from Azure.
   */
  user: AccountInfo
  /**
   * Indicates the status of the authentication.
   */
  status: AuthStatus.Authenticated
}

interface Unauthenticated {
  user: null
  status: AuthStatus.Unauthenticated
}

interface Loading {
  user: null | AccountInfo
  status: AuthStatus.Loading
}

export type AuthInitContextValue = AuthInitBase & (Authenticated | Unauthenticated | Loading)

export const initialValue: AuthInitContextValue = {
  user: null,
  status: AuthStatus.Loading,
  invalidate: Promise.resolve,
} as AuthInitContextValue

export const AuthInitContext = createContext<AuthInitContextValue>(initialValue)
