import { Container, Flex, Heading, Stack, Text } from '@chakra-ui/react'

import { InfoIcon } from 'components/elements/Icons/InfoIcon'

type Props = {
  title?: string | undefined
  subtitle?: string | undefined
  infoText?: string
}

const TAG = 'Subheader'

export function Subheader({ title, subtitle, infoText }: Props) {
  return (
    <Flex id={TAG} as="header" w="100%" py={10} h="88px" ml="auto" mr="auto" bg="primary.white">
      <Container alignItems="center" display="flex" justifyContent="space-between">
        <Stack alignItems="start" width="100%">
          {title && <Heading as="h1">{title}</Heading>}
          {subtitle && <Text>{subtitle}</Text>}
        </Stack>

        {infoText && (
          <Flex display={{ base: 'none', md: 'inherit' }} direction="row" minW="max-content" gap={2}>
            <InfoIcon mt={1} boxSize={4} />
            <Text>{infoText}</Text>
          </Flex>
        )}
      </Container>
    </Flex>
  )
}

Subheader.displayName = TAG
