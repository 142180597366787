import { Button } from '@chakra-ui/react'

import { CloseIcon } from 'components/elements/Icons/CloseIcon'

type ButtonProps = {
  onClick: () => void
}

export function CloseButton(props: ButtonProps) {
  return (
    <Button variant="close" size="2xs" {...props} onClick={props.onClick} _hover={{ background: 'primary.grey.100' }}>
      <CloseIcon boxSize={2} />
    </Button>
  )
}
