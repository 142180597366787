import type { BusinessUnit } from '@commercetools/platform-sdk'
import { createContext, FunctionComponent, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react'
import { QueryStatus, useMutation, useQuery, useQueryClient } from 'react-query'

import setCustomerBusinessUnit, { getMyBusinessUnits } from 'commercetools/api/businessUnits'
import { useCustomer } from 'commercetools/hooks/use-customer'
import { STORAGE_KEY_SELECTED_COMPANY_ID } from 'config/cookie'
import useStateCache, { getStorageKey } from 'hooks/useStateCache'

interface BusinessUnitProviderProps {
  children: ReactNode
}

type UseBusinessUnitValue = {
  status: QueryStatus
  all: BusinessUnit[]
  current: BusinessUnit | undefined
  id: string
  key: string
  setCurrentCompanyId: (id: string, userId: string) => void
  // For easier development
  storeKey: string
  erpLegalEntity: string
  erpAccountNumber: string // aka customerNumber
}

const BusinessUnitContext = createContext<UseBusinessUnitValue>([] as unknown as UseBusinessUnitValue)

export const BusinessUnitProvider: FunctionComponent<BusinessUnitProviderProps> = ({ children }) => {
  const [customer] = useCustomer()
  const response = useQuery(['getMyBusinessUnits'], getMyBusinessUnits, { enabled: customer.isSuccess })

  const [businessUnitIdSaved] = useStateCache(STORAGE_KEY_SELECTED_COMPANY_ID, '')

  const fields = (customer.isSuccess ? customer.data.custom?.fields : {}) || {}
  const customerBU = fields.ctSelectedBusinessUnit?.id || /* Backward compatibility */ fields.selectedCompany?.id || ''
  const businessUnitId: string = customerBU || businessUnitIdSaved || ''

  const myBusinessUnits = useMemo<BusinessUnit[]>(() => response.data?.results || [], [response.data?.results])

  const queryClient = useQueryClient()
  /**
   * We want to update the business unit id in Commercetools when the user is authenticated
   * and value in customer custom field is empty.
   */
  const { mutate } = useMutation({
    mutationFn: setCustomerBusinessUnit,
    onSuccess: () => queryClient.invalidateQueries('customer'),
  })

  const setCurrentCompanyId = useCallback<UseBusinessUnitValue['setCurrentCompanyId']>((businessUnitId, userId) => {
    localStorage.setItem(getStorageKey(STORAGE_KEY_SELECTED_COMPANY_ID, userId), JSON.stringify(businessUnitId))
  }, [])

  const customerVersion = customer.data?.version
  useEffect(() => {
    if (businessUnitId && customer.isSuccess && customerVersion && !customerBU) {
      mutate({ businessUnitId, customerVersion: customerVersion })
    }
  }, [businessUnitId, customerVersion, customer.isSuccess, customerBU, mutate])

  const current = useMemo<BusinessUnit | undefined>(() => {
    const defaultBusinessUnit = myBusinessUnits[0]
    return myBusinessUnits.find((bu) => bu.id === businessUnitId) || defaultBusinessUnit
  }, [businessUnitId, myBusinessUnits])

  const value = useMemo<UseBusinessUnitValue>(() => {
    const fields = current?.custom?.fields || {}

    return {
      all: myBusinessUnits,
      current,
      id: current?.id || businessUnitId,
      key: current?.key || '',
      status: response.status,
      setCurrentCompanyId: setCurrentCompanyId,
      storeKey: current?.stores?.[0]?.key || '',
      erpAccountNumber: fields.erpAccountNumber || '',
      erpLegalEntity: fields.erpLegalEntity || '',
    } satisfies UseBusinessUnitValue
  }, [businessUnitId, current, myBusinessUnits, response.status, setCurrentCompanyId])

  return <BusinessUnitContext.Provider value={value}>{children}</BusinessUnitContext.Provider>
}

export const useBusinessUnit = (): UseBusinessUnitValue => useContext(BusinessUnitContext)
