import { CentPrecisionMoney, TypedMoney } from '@commercetools/platform-sdk'
import currency from 'currency.js'

type FormattedPrice = {
  currencySymbol: string
  formattedValueWithoutDecimals: string
  decimals: string
}

export const getFormattedPrice = (
  price: TypedMoney | CentPrecisionMoney | undefined,
  lang: string,
): FormattedPrice | undefined => {
  if (!price) {
    return undefined
  }

  const currencySymbol = ['CAD', 'USD', 'AUD'].includes(price?.currencyCode) ? '$ ' : ''
  const options = { fromCents: true, precision: price.fractionDigits }
  const priceValue = currency(price.centAmount, options)?.value
  const formattedValueWithoutDecimals = Math.trunc(priceValue)?.toLocaleString(lang)
  const cents = currency(price.centAmount, options).cents().toString()
  const decimals = cents.length < options.precision ? cents.padStart(options.precision, '0') : cents

  return {
    currencySymbol,
    formattedValueWithoutDecimals,
    decimals,
  }
}
