import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'

import { useCustomer } from 'commercetools/hooks/use-customer'
import { COOKIE_PREFIX } from 'config/cookie'

type StorageType = keyof WindowLocalStorage | keyof WindowSessionStorage

const DEFAULT_USER = 'anonymous'
const DEFAULT_STORAGE = 'localStorage'

const getStorageType = (storageType: StorageType): Storage =>
  storageType === 'localStorage' ? localStorage : sessionStorage

export function getStorageKey(key: string, userId: string | undefined = DEFAULT_USER): string {
  const localAccountId = userId || DEFAULT_USER
  return `${COOKIE_PREFIX}.${localAccountId}.${key}`
}

export const getCachedValue = <VALUE = string>(
  key: string,
  defaultValue: VALUE,
  storageType: StorageType = DEFAULT_STORAGE,
  userId: string | undefined = DEFAULT_USER,
): VALUE => {
  const storageKey = getStorageKey(key, userId)

  if (typeof window === 'undefined') {
    return defaultValue
  }

  try {
    const value = getStorageType(storageType).getItem(storageKey) || ''
    return (JSON.parse(value) as VALUE) || defaultValue
  } catch (error) {
    return defaultValue
  }
}

/**
 * Hook for values cached in storage (local or session). It does two things:
 * 1. On first component load checks if value is available in localStorage
 * 2. When value is changed, ensures it is also set to localStorage
 *
 * @example
 * ```ts
 * const [cachedValue, setCachedValue] = useCachedValue<string>('key', 'defaultValue');
 * ```
 */
function useStateCache<VALUE = string>(
  /**
   * Key in storage
   */
  key: string,
  /**
   * Default cached value
   */
  defaultValue: VALUE,
  /**
   * Storage type: localStorage or sessionStorage
   *
   * @default 'localStorage'
   */
  storageType: StorageType = DEFAULT_STORAGE,
  /**
   * If `useCustomer` is empty, hook will use this value as userId
   * @default 'anonymous'
   */
  userId: string | undefined = DEFAULT_USER,
): [value: VALUE, setValue: Dispatch<SetStateAction<VALUE>>, getValue: () => VALUE] {
  const [customer] = useCustomer()
  const localAccountId = customer?.data?.id || userId || DEFAULT_USER
  const storageKey = `${COOKIE_PREFIX}.${localAccountId}.${key}`

  const [cachedValue, setCachedValue] = useState<VALUE>(getCachedValue(key, defaultValue, storageType, userId))

  useEffect(() => {
    setCachedValue(getCachedValue(key, defaultValue, storageType, localAccountId))
  }, [key, defaultValue, storageType, localAccountId])

  /**
   * Sets the value in storage and updates the state.
   */
  const setValue = useCallback<Dispatch<SetStateAction<VALUE>>>(
    (setState) => {
      function saveValue(value: VALUE) {
        const valueToSave = JSON.stringify(value)
        getStorageType(storageType).setItem(storageKey, valueToSave)
      }

      if (setState instanceof Function) {
        return setCachedValue((prevValue) => {
          const newValue = setState(prevValue)
          saveValue(newValue)
          return newValue
        })
      } else {
        const value = setState
        if (typeof value !== 'undefined') {
          setCachedValue(value)
          saveValue(value)
        }
      }
    },
    [storageKey, storageType],
  )

  /**
   * Handles changes in localStorage.
   * If the key is the same as the one we're using, we update the value.
   */
  useEffect(() => {
    function onStorageChange(event: StorageEvent): void {
      if (event.key === storageKey) {
        const value = event.newValue || ''
        try {
          setCachedValue(JSON.parse(value) as VALUE)
        } catch {
          // Ignore - we don't want to break the app if there's a problem with parsing
        }
      }
    }

    if (storageType === 'localStorage') {
      window.addEventListener('storage', onStorageChange)
      return () => {
        window.removeEventListener('storage', onStorageChange)
      }
    }
  }, [storageKey, storageType])

  const getValue = useCallback<() => VALUE>(
    () => getCachedValue(key, defaultValue, storageType, userId),
    [defaultValue, key, storageType, userId],
  )

  return [cachedValue, setValue, getValue]
}

export default useStateCache
