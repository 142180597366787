import { ConcurrentModificationError } from '@commercetools/platform-sdk'

import { getAccessToken } from 'commercetools/token'
import { tokenRequest } from 'config/auth'
import { appInsights } from 'logging/appInsight'

const isServer = () => typeof window === 'undefined'

export const ABORT_ERROR_NAME = 'AbortError'

interface FetchConfigType<TData = unknown> extends RequestInit {
  method: string
  token?: string
  data?: TData
}

async function http<TResponse, TData>(url: string, fetchConfig: FetchConfigType<TData>): Promise<TResponse> {
  const headers: HeadersInit = {}
  return new Promise(async (resolve, reject) => {
    if (typeof fetchConfig.data === 'object') {
      headers['Content-Type'] = 'application/json'
    }

    if (fetchConfig.token) {
      headers['Authorization'] = 'Bearer ' + fetchConfig.token
    } else {
      const accessToken = await getAccessToken(tokenRequest)
      headers['Authorization'] = 'Bearer ' + accessToken
    }

    return (
      fetch(url, {
        headers,
        signal: fetchConfig.signal,
        method: fetchConfig.method,
        body: typeof fetchConfig.data === 'object' ? JSON.stringify(fetchConfig.data) : null,
      })
        .then(async (response) => {
          try {
            const data = await response.json()
            // Handle concurrent modification error by re-fetching with current version
            if (data?.statusCode === 409) {
              // Find current version in errors array
              const error: ConcurrentModificationError = data.errors?.find(
                (error: ConcurrentModificationError) => error?.code === 'ConcurrentModification',
              )

              // Use current version to fetch again
              if (fetchConfig.data) {
                Object.assign(fetchConfig.data, { version: error.currentVersion })
              }
              return http<TResponse, TData>(url, fetchConfig).then(resolve).catch(reject)
            } else {
              return response.ok ? resolve(data) : reject(data)
            }
          } catch (error) {
            // Data parsing error encountered
            reject({ error })
          }
        })
        // Network error encountered
        .catch((error) => {
          /**
           * For aborted (cancelled) requests, do not log error on the console or App Insights
           * @link https://nodejs.org/api/errors.html#abort_err
           */
          if (error.name !== ABORT_ERROR_NAME) {
            console.error(error)
            appInsights.trackException({ exception: new Error(error) })
          }
          reject({ error })
        })
    )
  })
}

const pointUrlToCorrectEndpoint = (url: string): string => {
  return (isServer() ? process.env.CTP_API_URL + '/' + process.env.CTP_PROJECT_KEY : '/api/commerce') + url
}

const client = {
  get: <TResponse>(url: string, token?: string, init?: RequestInit): Promise<TResponse> =>
    http<TResponse, null>(pointUrlToCorrectEndpoint(url), { token, method: 'GET', ...init }),

  post: <TResponse, TData>(url: string, data: TData, token?: string, init?: RequestInit): Promise<TResponse> =>
    http<TResponse, TData>(pointUrlToCorrectEndpoint(url), { data, token, method: 'POST', ...init }),

  delete: <TResponse>(url: string, token?: string, init?: RequestInit): Promise<TResponse> =>
    http<TResponse, null>(pointUrlToCorrectEndpoint(url), { token, method: 'DELETE', ...init }),
}

export default client
