import type { BusinessUnit } from '@commercetools/platform-sdk'

/**
 * Checks if the business unit aka company has access to the Analytics Portal
 */
export function hasBusinessUnitAccessToAnalyticsPortal(
  businessUnit: DeepPartial<BusinessUnit> | undefined | null,
): boolean {
  return businessUnit?.custom?.fields?.ctAllowAnalyticsPortalAccess ?? false
}

/**
 * Checks if the customer has access to the the Analytics Portal
 */
export function hasCustomerAccessToAnalyticsPortal(
  businessUnits: ReadonlyArray<DeepPartial<BusinessUnit> | undefined | null> | undefined | null,
): boolean {
  return !!businessUnits?.filter(Boolean)?.some(hasBusinessUnitAccessToAnalyticsPortal)
}
