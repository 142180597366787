import { useMsal } from '@azure/msal-react'
import { Button, Stack } from '@chakra-ui/react'
import { default as NextLink } from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

import Error from 'components/elements/Error'
import { RoutePaths } from 'config/routes'

const TAG = 'NoAccessError'

const NoAccessError: FunctionComponent = () => {
  const { t } = useTranslation()

  const { instance } = useMsal()
  const logOut = () => {
    const account = instance.getActiveAccount()
    instance.logoutRedirect({ account, postLogoutRedirectUri: RoutePaths.login })
  }

  return (
    <Error
      title={t('auth.no-access.title')}
      description={t('auth.no-access.description')}
      button={
        <Stack>
          <Button variant="primary" as={NextLink} href={process.env.NORMET_NO_ACCESS_TO_STORE_URL}>
            {t('auth.no-access.button')}
          </Button>
          <Button variant="primary" onClick={logOut}>
            {t('sign-out')}
          </Button>
        </Stack>
      }
      p="0"
      m="0"
    />
  )
}

NoAccessError.displayName = TAG

export default NoAccessError
