import { Button, Container, Flex, Heading } from '@chakra-ui/react'
import Router from 'next/router'
import Trans from 'next-translate/Trans'
import React, { ErrorInfo, PureComponent, ReactNode } from 'react'

import { TowTruckIcon } from 'components/elements/Icons/TowTruckIcon'
import { appInsights } from 'logging/appInsight'

interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
  route: string
}

const TAG = 'ErrorBoundary'

class ErrorBoundary extends PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)

    this.state = {
      hasError: false,
      route: '',
    }
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true, route: Router.route }
  }

  componentDidUpdate(): void {
    if (this.state.hasError && this.state.route !== Router.route) {
      this.setState({ hasError: false, route: '' })
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(`[${TAG}]`, error)
    console.error(`[${TAG}]`, errorInfo)
    appInsights.trackException({ exception: error }, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container alignSelf="center" justifySelf="center" height="100vh" p={0} background="primary.white">
          <Flex p={13} direction="column" alignItems="center" justifyContent="center" textAlign="center">
            <Flex
              boxSize="11.5rem"
              fontSize="sm"
              borderRadius="50%"
              bgColor="secondary.beige.100"
              alignItems="center"
              justifyContent="center"
            >
              <TowTruckIcon boxSize="5.75rem" color="primary.red" />
            </Flex>
            <Heading as="h1" mb={12} mt={8} fontSize={{ base: 'lg', sm: 'lg' }}>
              <Trans i18nKey="common:general-error" />
            </Heading>
            <Button onClick={() => (window.location.href = '/')}>
              <Trans i18nKey="common:continue-shopping-text" />
            </Button>
          </Flex>
        </Container>
      )
    }

    return this.props.children
  }
}
export default ErrorBoundary
