export const Drawer = {
  parts: ['body'],
  baseStyle: {
    body: {
      py: [1, 2],
      pl: 0,
      pr: 4,
      mr: -4,
    },
  },
}
