const NORMET_ANALYTICS_PORTAL_URL = process.env.NORMET_ANALYTICS_PORTAL_URL
const NORMET_ANALYTICS_PORTAL_ARTICLE_URL = process.env.NORMET_ANALYTICS_PORTAL_ARTICLE_URL

/**
 * Retrieves the Normet Analytics Portal URL based on the company's access rights.
 *
 * @param currentCompanyHasAccessToAnalytics - A boolean indicating whether the current company has access to the Analytics Portal.
 * @returns The URL of the Normet Analytics Portal if the company has access, or the article URL if not. Returns `undefined` if the URLs are not properly set or if an error occurs while creating the URL.
 *
 * @remarks
 * - If `currentCompanyHasAccessToAnalytics` is `true`, the function attempts to return the URL specified by the `NORMET_ANALYTICS_PORTAL_URL` environment variable.
 * - If `currentCompanyHasAccessToAnalytics` is `false`, the function attempts to return the URL specified by the `NORMET_ANALYTICS_PORTAL_ARTICLE_URL` environment variable.
 * - Logs an error and returns `undefined` if the required environment variables are not set or if the URL creation fails.
 */
function getNormetAnalyticsPortalURL(currentCompanyHasAccessToAnalytics: boolean): URL | undefined {
  if (currentCompanyHasAccessToAnalytics) {
    if (!NORMET_ANALYTICS_PORTAL_URL) {
      console.error('Could not find URL. Please set environment variable NORMET_ANALYTICS_PORTAL_URL.')
      return undefined
    }

    try {
      return new URL(NORMET_ANALYTICS_PORTAL_URL)
    } catch (error) {
      console.error('Could not create a URL from NORMET_ANALYTICS_PORTAL_URL:', error)
      return undefined
    }
  }

  if (!NORMET_ANALYTICS_PORTAL_ARTICLE_URL) {
    console.error('Could not find URL. Please set environment variable NORMET_ANALYTICS_PORTAL_ARTICLE_URL.')
    return undefined
  }

  try {
    return new URL(NORMET_ANALYTICS_PORTAL_ARTICLE_URL)
  } catch (error) {
    console.error('Could not create a URL from NORMET_ANALYTICS_PORTAL_ARTICLE_URL:', error)
    return undefined
  }
}

export default getNormetAnalyticsPortalURL
