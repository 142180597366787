import { Container, Flex } from '@chakra-ui/react'
import { FunctionComponent } from 'react'
import React from 'react'

import Footer from 'components/modules/Footer'
import LoginHeader from 'components/modules/Login/LoginHeader'

const BG_IMAGE_URL = '/images/login-bg.jpg'

interface LoginLayoutProps {
  children: NonNullable<React.ReactNode>
}

const styles = {
  container: { display: 'flex', flexDirection: 'column', maxW: 'full', h: '100vh', p: 0 },
  wrapper: {
    flexDirection: 'column',
    maxW: 'full',
    h: 'full',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: { lg: `url(${BG_IMAGE_URL})` },
    boxShadow: { lg: 'inset 0 0 0 100vw rgba(0,0,0,0.6)' },
    backgroundSize: { lg: 'cover' },
    backgroundPosition: { lg: 'center' },
  },
  modal: {
    maxW: '428px',
    direction: 'column',
    bgColor: 'primary.white',
    padding: { base: 6, md: 10 },
    zIndex: 'modal',
    my: 6,
  },
} as const

const LoginLayout: FunctionComponent<LoginLayoutProps> = ({ children }) => {
  return (
    <Container id="LoginLayout" {...styles.container}>
      <LoginHeader />
      <Flex {...styles.wrapper}>
        <Flex {...styles.modal}>{children}</Flex>
      </Flex>
      <Footer bgColor="primary.white" hideContactLink />
    </Container>
  )
}

export default LoginLayout
