import { LocalizedString, ProductVariant } from '@commercetools/platform-sdk'

import { getVariantAttributeValue } from './getVariantAttributeValue'

import { getLocalizedString } from '../getLocalizedString'

export const getProductVariantDescription = (
  name: string | LocalizedString,
  variant: ProductVariant | undefined,
  lang: string,
): string | undefined => {
  const description = variant
    ? getLocalizedString(getVariantAttributeValue(variant, 'variantDescription'), lang)
    : undefined
  return description && description.toLowerCase() !== getLocalizedString(name, lang)?.toLowerCase()
    ? description
    : undefined
}
