import { Button, Center, Container, Flex, Heading, Spinner, useDisclosure } from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, ReactNode } from 'react'

import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'
import { TowTruckIcon } from 'components/elements/Icons/TowTruckIcon'
import hasBusinessUnitAccessToStore from 'utils/hasBusinessUnitAccessToStore'

interface CompanyAccessProps {
  children?: ReactNode
}

const TAG = 'CompanyAccess'

const ChangeCompanyModal = dynamic(
  () => import('components/elements/ChangeCompanyModal').then((modal) => modal.ChangeCompanyModal),
  { ssr: false },
)

/**
 * Guard to handle situation when the customer has access to the store (one of his companies has access)
 * but the selected one doesn't have access to the store. User is already logged in here.
 * Handling situation when the customer has access to the store in general is done in @see RouteGuard
 */
const CompanyAccess: FunctionComponent<CompanyAccessProps> = ({ children }) => {
  const { t } = useTranslation('common')

  const { current, status } = useBusinessUnit()
  const hasAccess = hasBusinessUnitAccessToStore(current)

  const modal = useDisclosure()

  if (status === 'loading' || status === 'idle') {
    return (
      <Center my={10}>
        <Spinner size="xl" />
      </Center>
    )
  }

  if (!hasAccess) {
    return (
      <>
        <Container
          alignSelf="center"
          justifySelf="center"
          m={{ base: 0, md: 10 }}
          width="desktop-content"
          height="full"
          p={0}
          background="primary.white"
          id="CompanyAccess"
        >
          <Flex p={13} direction="column" alignItems="center" justifyContent="center" textAlign="center">
            <Flex
              boxSize="11.5rem"
              fontSize="sm"
              borderRadius="50%"
              bgColor="secondary.beige.100"
              alignItems="center"
              justifyContent="center"
            >
              <TowTruckIcon boxSize="5.75rem" color="primary.red" />
            </Flex>
            <Heading as="h1" mb="12" mt="8" fontSize={{ base: 'lg', sm: 'lg' }}>
              {t('company-doesnt-have-access')}
            </Heading>
            <Button onClick={modal.onOpen}>{t('change-company')}</Button>
          </Flex>
        </Container>
        <ChangeCompanyModal {...modal} />
      </>
    )
  }

  return <>{children}</>
}

CompanyAccess.displayName = TAG

export default CompanyAccess
