import {
  Address,
  CartAddLineItemAction,
  CartAddPaymentAction,
  CartChangeLineItemQuantityAction,
  CartRecalculateAction,
  CartRemoveLineItemAction,
  CartRemovePaymentAction,
  CartSetBillingAddressAction,
  CartSetCustomerEmailAction,
  CartSetCustomerIdAction,
  CartSetCustomFieldAction,
  CartSetLineItemDistributionChannelAction,
  CartSetLineItemPriceAction,
  CartSetShippingAddressAction,
  CartSetShippingAddressCustomTypeAction,
  CartSetShippingMethodAction,
  ChannelResourceIdentifier,
  LineItemDraft,
  TypedMoney,
} from '@commercetools/platform-sdk'

import { getRandomId } from 'utils/getRandomId'

export const addLineItem = ({ sku, quantity = 1, distributionChannel }: LineItemDraft): CartAddLineItemAction => ({
  action: 'addLineItem',
  quantity: Number(quantity),
  sku: sku?.toString(),
  distributionChannel,
})

export const removeLineItem = (id: string): CartRemoveLineItemAction => ({
  action: 'removeLineItem',
  lineItemId: id,
})

export const setLineItemDistributionChannel = (
  lineItemId: string,
  distributionChannel: ChannelResourceIdentifier,
): CartSetLineItemDistributionChannelAction => ({
  action: 'setLineItemDistributionChannel',
  lineItemId,
  distributionChannel,
})

export const changeLineItemQuantity = (lineItemId: string, quantity: number): CartChangeLineItemQuantityAction => ({
  action: 'changeLineItemQuantity',
  lineItemId,
  quantity: Number(quantity),
})

export const setLineItemPrice = (id: string, externalPrice: TypedMoney): CartSetLineItemPriceAction => ({
  action: 'setLineItemPrice',
  lineItemId: id,
  externalPrice,
})

export const setCustomerId = (customerId: string): CartSetCustomerIdAction => ({
  action: 'setCustomerId',
  customerId,
})

export const setCustomerEmail = (email: string): CartSetCustomerEmailAction => ({
  action: 'setCustomerEmail',
  email,
})

export const unsetShippingAddress = (): CartSetShippingAddressAction => ({
  action: 'setShippingAddress',
})

export const setShippingAddress = ({
  address,
  country,
}: {
  address?: Address
  country: string
}): CartSetShippingAddressAction => ({
  action: 'setShippingAddress',
  address: {
    key: getRandomId(),
    country: country,
    ...address,
  },
})

const ADDRESS_TYPE = 'address-normetBusinessUnit'
export const setShippingAddressCustomType = (erpLocationId: string): CartSetShippingAddressCustomTypeAction => ({
  action: 'setShippingAddressCustomType',
  type: {
    key: ADDRESS_TYPE,
    typeId: 'type',
  },
  fields: {
    erpLocationId,
  },
})

export const unsetBillingAddress = (): CartSetBillingAddressAction => ({
  action: 'setBillingAddress',
})

export const unsetShippingMethod = (): CartSetShippingMethodAction => ({
  action: 'setShippingMethod',
})

export const setShippingMethod = (id: string): CartSetShippingMethodAction => ({
  action: 'setShippingMethod',
  shippingMethod: {
    typeId: 'shipping-method',
    id,
  },
})

export const addPayment = (id: string): CartAddPaymentAction => ({
  action: 'addPayment',
  payment: {
    typeId: 'payment',
    id,
  },
})

export const removePayment = (id: string): CartRemovePaymentAction => ({
  action: 'removePayment',
  payment: {
    typeId: 'payment',
    id,
  },
})

export const recalculate = (): CartRecalculateAction => ({
  action: 'recalculate',
  updateProductData: true,
})

export const setCartCustomField = (name: string, value: string | boolean): CartSetCustomFieldAction => ({
  action: 'setCustomField',
  name,
  value,
})
