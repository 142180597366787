import { LocalizedString, Price } from '@commercetools/platform-sdk'

import { getLocalizedString } from 'commercetools/utils/getLocalizedString'
import { events } from 'gtm/events'
import { gtagEvent } from 'gtm/gtag'
import { formatDecimalPrice } from 'utils/formatDecimalPrice'

import { pageTypes } from './pageTypes'

type Props = {
  sku: string | undefined
  price: Price | undefined
  name: LocalizedString | undefined
  lang: string
  gtagPageType?: string
  quantity?: number
}

export const trackAddProductToCart = ({
  sku,
  price,
  name,
  lang,
  gtagPageType = pageTypes.productListPage,
  quantity = 1,
}: Props) => {
  const { currencyCode, centAmount, fractionDigits } = price?.value || {}
  const formattedPrice = formatDecimalPrice(centAmount, fractionDigits)

  gtagEvent({
    event: events.addToCart,
    ecommerce: {
      currency: currencyCode,
      value: quantity * formattedPrice,
      item_page_type: gtagPageType,
      items: [
        {
          item_id: sku,
          item_name: getLocalizedString(name, lang) || name?.en,
          quantity,
          price: formattedPrice,
        },
      ],
    },
  })
}
