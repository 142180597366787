import type { Channel } from '@commercetools/platform-sdk'
import useTranslation from 'next-translate/useTranslation'
import { createContext, FunctionComponent, ReactNode, useContext, useEffect, useMemo } from 'react'
import { QueryStatus, useQuery } from 'react-query'

import { fetchProjectBranches } from 'commercetools/api/channels'
import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'
import { useCustomer } from 'commercetools/hooks/use-customer'
import { getLocalizedString } from 'commercetools/utils/getLocalizedString'
import useStateCache from 'hooks/useStateCache'
import { byTextAscending } from 'utils/byTextAscending'

const STORAGE_KEY_SELECTED_BRANCH_ID = 'selectedChannelId'

interface BranchesProviderProps {
  children: ReactNode
}

export interface UseBranchResult {
  status: QueryStatus
  all: Channel[]
  current: Channel | undefined
  id: string
  setCurrentBranchId: (id: string) => void
}

export const BranchesContext = createContext<UseBranchResult>(undefined!)

export const BranchesProvider: FunctionComponent<BranchesProviderProps> = ({ children }) => {
  const { lang } = useTranslation('common')

  const [customer] = useCustomer()
  const isCustomerFetched = customer.isSuccess && customer.data?.id !== undefined

  const businessUnit = useBusinessUnit()

  const [selectedBranchId, setCurrentBranchId] = useStateCache(STORAGE_KEY_SELECTED_BRANCH_ID, '')
  const fields = businessUnit.current?.custom?.fields || {}
  const userBranchId = selectedBranchId || fields.ctDefaultNormetBranch?.id || fields.defaultNormetBranch?.id

  const response = useQuery(['fetchProjectBranches', businessUnit.erpLegalEntity], fetchProjectBranches, {
    enabled: !!businessUnit.erpLegalEntity && isCustomerFetched,
  })

  const all = useMemo<UseBranchResult['all']>(
    () => (response.data?.results || []).sort(byTextAscending((branch) => getLocalizedString(branch.name, lang) ?? '')),
    [lang, response.data?.results],
  )

  const current = useMemo<Channel | undefined>(() => {
    const defaultBranch = all[0]
    return all.find((branch) => branch.id === userBranchId) || defaultBranch
  }, [all, userBranchId])

  const id = current?.id || ''

  useEffect(() => {
    if (!selectedBranchId && response.isSuccess && isCustomerFetched) {
      setCurrentBranchId(id)
    }
  }, [id, isCustomerFetched, response.isSuccess, selectedBranchId, setCurrentBranchId])

  const contextValue = useMemo<UseBranchResult>(() => {
    return {
      all,
      current,
      id,
      status: response.status,
      setCurrentBranchId,
    } satisfies UseBranchResult
  }, [all, current, id, response.status, setCurrentBranchId])

  return <BranchesContext.Provider value={contextValue}>{children}</BranchesContext.Provider>
}

export const useBranches = (): UseBranchResult => useContext(BranchesContext)
