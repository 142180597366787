import { ErrorObject } from '@commercetools/platform-sdk'
import { useQuery, UseQueryResult } from 'react-query'

import { getCustomerEquipment } from 'bom/api/equipment'
import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'

export function useEquipment(): UseQueryResult<BOM.Equipment[], ErrorObject> {
  const businessUnit = useBusinessUnit()

  return useQuery(['getCustomerEquipment', businessUnit.erpAccountNumber], getCustomerEquipment, {
    enabled: Boolean(businessUnit.erpAccountNumber),
    onError: console.error,
  })
}
