import { Icon, IconProps } from '@chakra-ui/react'

export function TrashCanIcon(props: IconProps) {
  return (
    <Icon width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M30.9787234,5.44680851 C31.5427589,5.44680851 32,5.90404962 32,6.46808511 C32,7.02222524 31.5586617,7.47328528 31.008309,7.4889414 L30.9787234,7.4893617 L1.0212766,7.4893617 C0.457241107,7.4893617 0,7.0321206 0,6.46808511 C0,5.91394498 0.441338292,5.46288493 0.991691019,5.44722882 L1.0212766,5.44680851 L30.9787234,5.44680851 Z" />
      <path d="M19.0638298,0 L12.9361702,0 C11.2440637,0 9.87234043,1.37172332 9.87234043,3.06382979 L9.87234043,6.46808511 C9.87234043,7.0321206 10.3295815,7.4893617 10.893617,7.4893617 L21.106383,7.4893617 C21.6704185,7.4893617 22.1276596,7.0321206 22.1276596,6.46808511 L22.1276596,3.06382979 C22.1276596,1.37172332 20.7559363,0 19.0638298,0 Z M19.0638298,2.04255319 L19.0934154,2.0429735 C19.6437681,2.05862961 20.0851064,2.50968966 20.0851064,3.06382979 L20.0851064,5.44680851 L11.9148936,5.44680851 L11.9148936,3.06382979 C11.9148936,2.4997943 12.3721347,2.04255319 12.9361702,2.04255319 L19.0638298,2.04255319 Z" />
      <path d="M12.9361702,12.5957447 C13.4903103,12.5957447 13.9413704,13.037083 13.9570265,13.5874357 L13.9574468,13.6170213 L13.9574468,23.8297872 C13.9574468,24.3938227 13.5002057,24.8510638 12.9361702,24.8510638 C12.3820301,24.8510638 11.93097,24.4097255 11.9153139,23.8593728 L11.9148936,23.8297872 L11.9148936,13.6170213 C11.9148936,13.0529858 12.3721347,12.5957447 12.9361702,12.5957447 Z" />
      <path d="M19.0638298,12.5957447 C19.6179699,12.5957447 20.06903,13.037083 20.0846861,13.5874357 L20.0851064,13.6170213 L20.0851064,23.8297872 C20.0851064,24.3938227 19.6278653,24.8510638 19.0638298,24.8510638 C18.5096897,24.8510638 18.0586296,24.4097255 18.0429735,23.8593728 L18.0425532,23.8297872 L18.0425532,13.6170213 C18.0425532,13.0529858 18.4997943,12.5957447 19.0638298,12.5957447 Z" />
      <path d="M27.2340426,5.44680851 L4.76595745,5.44680851 C4.16834835,5.44680851 3.69842731,5.95767161 3.74823397,6.55320157 L5.64099993,29.1846909 C5.75837994,30.7710291 7.09001512,32.0043592 8.6904388,32.0000192 L23.3123404,32.0000192 C24.8940084,32.0043157 26.2149608,30.795572 26.3558591,29.2227391 L28.251766,6.55320157 C28.3015727,5.95767161 27.8316517,5.44680851 27.2340426,5.44680851 Z M5.87612596,7.4893617 L26.1237651,7.4893617 L24.3235531,29.014458 C24.2837559,29.5514542 23.843837,29.9588971 23.3151197,29.9574621 L8.68765957,29.9574621 L8.65876153,29.9571294 C8.14989968,29.9442838 7.73129855,29.5566293 7.67973806,29.0528217 L5.87612596,7.4893617 Z" />
    </Icon>
  )
}
