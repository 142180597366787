import type { SilentRequest } from '@azure/msal-browser'

import { RoutePaths } from 'config/routes'
import { msalInstance } from 'utils/Providers/Auth'

export async function getAccessToken(tokenRequest: SilentRequest): Promise<string> {
  const account = msalInstance.getActiveAccount()

  if (!account) {
    return Promise.reject(new Error('No active account'))
  }

  return msalInstance
    .acquireTokenSilent({ ...tokenRequest, account })
    .then((response) => response.accessToken)
    .catch(function () {
      msalInstance.logoutRedirect({ account, postLogoutRedirectUri: RoutePaths.login })
      return Promise.reject(new Error('There is a problem with access token'))
    })
}
