import { BusinessUnitPagedQueryResponse } from '@commercetools/platform-sdk'
import { QueryFunctionContext } from 'react-query'

import client from 'commercetools/client'

export const MAX_BU_LIMIT = 500

type Arguments = QueryFunctionContext<[queryFnName: 'getMyBusinessUnits']>

/**
 * @returns my business unit.
 * @link https://docs.commercetools.com/api/projects/me-business-units
 */
export function getMyBusinessUnits({ signal }: Arguments): Promise<BusinessUnitPagedQueryResponse> {
  return client.get<BusinessUnitPagedQueryResponse>(`/me/business-units?limit=${MAX_BU_LIMIT}`, void 0, { signal })
}

interface Variables {
  customerVersion: number
  businessUnitId: string
}

/**
 * Sets a business unit in a customer custom fields.
 * @link https://docs.commercetools.com/api/projects/me-profile#set-customfield
 */
function setCustomerBusinessUnit({ businessUnitId, customerVersion }: Variables) {
  return client.post('/me', {
    actions: [
      {
        action: 'setCustomField',
        name: 'ctSelectedBusinessUnit',
        value: { typeId: 'business-unit', id: businessUnitId },
      },
    ],
    version: customerVersion,
  })
}

export default setCustomerBusinessUnit
