import { useMsal } from '@azure/msal-react'
import { Button, Divider, Link, Stack, StackProps, Text, useDisclosure, useMultiStyleConfig } from '@chakra-ui/react'
import type { BusinessUnit } from '@commercetools/platform-sdk'
import dynamic from 'next/dynamic'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'

import { RoutePaths } from 'config/routes'
import getCompanyName from 'utils/getCompanyName'

const ChangeCompanyModal = dynamic(
  () => import('components/elements/ChangeCompanyModal').then((modal) => modal.ChangeCompanyModal),
  { ssr: false },
)

type Props = StackProps & {
  variant?: 'default' | 'mobile'
  size?: string
  currentCompany?: BusinessUnit
}

const LINKS = [
  { href: RoutePaths.myEquipment, title: 'equipment' },
  { href: RoutePaths.myQuotations, title: 'quotations' },
  { href: RoutePaths.myOrderHistory, title: 'legacy-orders' },
  { href: RoutePaths.myOrderHistoryB2B, title: 'order-history' },
  { href: RoutePaths.myOrderTemplate, title: 'order-templates', hasDivider: true },
  { href: RoutePaths.myProfile, title: 'profile' },
]

const styles = {
  menu: { bg: 'primary.white', fontSize: 'sm', minW: 0 },
  text: { textTransform: 'uppercase', fontWeight: 'bold', mb: '-8px' },
  link: { lineHeight: 16 / 14, color: 'primary.black' },
} as const

export const AccountMenu = function ({ variant = 'default', size, currentCompany, ...props }: Props) {
  const { t } = useTranslation('common')
  const router = useRouter()
  const cleanPath = router.asPath.split('#')[0].split('?')[0]
  const { instance } = useMsal()
  const getAriaCurrent = (href: string) => (cleanPath === href ? 'page' : undefined)

  const stylesConfig = useMultiStyleConfig('AccountMenu', { size })

  const modal = useDisclosure()

  const logOut = () => {
    const account = instance.getActiveAccount()
    instance.logoutRedirect({ account, postLogoutRedirectUri: RoutePaths.login })
  }

  return (
    <>
      <Stack {...styles.menu} {...props}>
        <Text {...styles.text}>{getCompanyName(currentCompany)}</Text>
        <Button variant="link" sx={stylesConfig.link} onClick={modal.onOpen}>
          {t('change-company')}
        </Button>

        {variant === 'default' ? (
          <>
            <Divider sx={stylesConfig.divider} />
            {LINKS
              // Hide the link for legacy orders unless the path is an active path
              .filter((link) => (link.title === 'legacy-orders' ? router.pathname === RoutePaths.myOrderHistory : true))
              .map(({ href, title, hasDivider }) => {
                return (
                  <React.Fragment key={title}>
                    <Link as={NextLink} href={href} sx={stylesConfig.link} aria-current={getAriaCurrent(href)}>
                      {t(title)}
                    </Link>
                    {hasDivider && <Divider m={0} />}
                  </React.Fragment>
                )
              })}
            <Divider sx={stylesConfig.divider} />
          </>
        ) : (
          <>
            <Divider my={3} />
            <Link
              as={NextLink}
              href={RoutePaths.myProfile}
              sx={stylesConfig.link}
              aria-current={getAriaCurrent(RoutePaths.myProfile)}
            >
              {t('profile')}
            </Link>
            <Divider sx={stylesConfig.divider} my={3} />
          </>
        )}

        <Button variant="link" sx={stylesConfig.link} {...styles.link} onClick={logOut}>
          {t('sign-out')}
        </Button>
      </Stack>

      <ChangeCompanyModal {...modal} />
    </>
  )
}
