import { Button, Flex, Stack, Text, useMediaQuery } from '@chakra-ui/react'
import { ReactNode } from 'react'

import { CloseIcon } from 'components/elements/Icons/CloseIcon'

type Props = {
  title: string
  isOpen: boolean
  onClose: () => void
  children: ReactNode
}

export const MenuDrawer = function ({ title, isOpen, onClose, children }: Props) {
  const [isSmallerThan768] = useMediaQuery('(max-width: 768px)')
  return (
    <Stack
      display={isOpen && isSmallerThan768 ? 'flex' : 'none'}
      w="full"
      h="100vh"
      bg="white"
      pos="fixed"
      mx="0!important"
      left={0}
      top={0}
      zIndex="modal"
    >
      <Flex
        w="100%"
        justifyContent="space-between"
        color="white"
        align="center"
        h={12}
        px={4}
        marginLeft="auto"
        marginRight="auto"
        backgroundColor="primary.black"
      >
        <Text fontSize="sm">{title}</Text>
        <Button
          variant="ghost"
          minW="0"
          onClick={onClose}
          borderLeftWidth={1}
          pl={4}
          borderLeftColor="rgba(255,255,255,0.15)"
        >
          <CloseIcon boxSize={4} />
        </Button>
      </Flex>
      {children}
    </Stack>
  )
}
