import { EventType, PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { BusinessUnit, BusinessUnitPagedQueryResponse } from '@commercetools/platform-sdk'
import { setCookie } from 'cookies-next'
import Router from 'next/router'
import React, { FunctionComponent, ReactNode } from 'react'

import { setLastLoginDate } from 'commercetools/api/actions/customer'
import { MAX_BU_LIMIT } from 'commercetools/api/businessUnits'
import { fetchCustomer, updateCustomer } from 'commercetools/api/customer'
import client from 'commercetools/client'
import { ErpLegalEntityLocaleMap } from 'commercetools/types'
import AuthInit from 'components/modules/Auth/AuthInit'
import { msalConfig } from 'config/auth'
import { STORAGE_KEY_SELECTED_COMPANY_ID } from 'config/cookie'
import { getCachedValue } from 'hooks/useStateCache'
import CustomNavigationClient from 'utils/Providers/Auth/NavigationClient'
import { queryClient } from 'utils/Providers/QueryClient'

interface AuthProps {
  children: ReactNode
}

function proceedToHomePage(company: BusinessUnit): Promise<boolean> {
  const erpLegalEntity = parseInt(company?.custom?.fields?.erpLegalEntity)
  const locale = ErpLegalEntityLocaleMap.get(erpLegalEntity) || ''
  setCookie('NEXT_LOCALE', locale, { path: '/', secure: true, sameSite: 'strict' })
  return Router.push(`/${locale}`)
}

const TAG = 'Auth'

export const msalInstance = new PublicClientApplication(msalConfig)

msalInstance.initialize().then(() => {
  const accounts = msalInstance.getAllAccounts()
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0])
  }

  // This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents()

  msalInstance.addEventCallback(async (event) => {
    if (
      (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      event.payload &&
      'account' in event.payload &&
      event.payload.account
    ) {
      const account = event.payload.account
      msalInstance.setActiveAccount(account)

      const sourceCustomer = await fetchCustomer('', account.idToken)

      updateCustomer({ version: sourceCustomer.version, actions: setLastLoginDate(new Date()) })
        .then(async (customer) => {
          queryClient.setQueryData(['customer', customer.id], customer)

          const commercetoolsCompanyId =
            customer.custom?.fields?.ctSelectedBusinessUnit?.id || customer.custom?.fields?.selectedCompany?.id

          const companies = await client.get<BusinessUnitPagedQueryResponse>(
            `/me/business-units?limit=${MAX_BU_LIMIT}`,
            account.idToken,
          )
          const companyId =
            commercetoolsCompanyId || getCachedValue(STORAGE_KEY_SELECTED_COMPANY_ID, '', void 0, customer.id)

          const currentCompany = companies.results?.find((company: BusinessUnit) => company.id === companyId)

          const chosenCompany = currentCompany || companies.results[0]
          proceedToHomePage(chosenCompany)
        })
        .finally(() => {
          queryClient.invalidateQueries('customer')
        })
    }
  })
})

const navigationClient = new CustomNavigationClient()
msalInstance.setNavigationClient(navigationClient)

const Auth: FunctionComponent<AuthProps> = ({ children }) => (
  <MsalProvider instance={msalInstance}>
    <AuthInit>{children}</AuthInit>
  </MsalProvider>
)

Auth.displayName = TAG

export default Auth
