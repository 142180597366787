import type { BusinessUnit } from '@commercetools/platform-sdk'

import getCustomerFullName from './getCustomerFullName'

function getCompanyName(businessUnit: Partial<BusinessUnit> | undefined | null): string {
  if (!businessUnit) {
    return ''
  }
  return businessUnit.name || getCustomerFullName(businessUnit)
}

export default getCompanyName
