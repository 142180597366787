import type { BusinessUnit } from '@commercetools/platform-sdk'

import hasBusinessUnitAccessToStore from 'utils/hasBusinessUnitAccessToStore'

/**
 * Checks if the customer has access to the store
 */
export function hasCustomerAccessToStore(
  businessUnits: ReadonlyArray<DeepPartial<BusinessUnit> | undefined | null> | undefined | null,
): boolean {
  return !!businessUnits?.filter(Boolean)?.some(hasBusinessUnitAccessToStore)
}
