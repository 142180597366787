import { Customer } from '@commercetools/platform-sdk'

import { UpdateActionValue } from 'commercetools/types'

import client from '../client'

export async function fetchCustomer(id?: string, token?: string): Promise<Customer> {
  return client.get<Customer>(id ? `/customers/${id}` : '/me', token)
}

export async function updateCustomer({ id, version, actions }: UpdateActionValue, token?: string): Promise<Customer> {
  const url = id ? `/customers/${id}` : '/me'
  return client.post<Customer, UpdateActionValue>(
    url,
    {
      version,
      actions: Array.isArray(actions) ? actions : [actions],
    },
    token,
  )
}
