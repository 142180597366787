/**
 * Routes that are accessible only when user is logged in
 */
export const LOGGED_IN_ROUTES = {
  home: '/',
  addToCartFromFile: '/add-to-cart-from-file',
  cart: '/cart',
  checkout: '/checkout',
  checkoutOrderConfirmation: '/checkout/order-confirmation/[id]',
  contactForm: '/contact-form',
  search: '/search',
  product: '/product/[slug]',
  productBundle: '/product-bundle/[slug]',
  requestQuotation: '/request-quotation',
  requestQuotationConfirmation: '/request-quotation/confirmation/',
  // My pages
  myProfile: '/my-pages/profile',
  myEquipment: '/my-pages/equipment',
  myQuotations: '/my-pages/quotations',
  myOrderHistory: '/my-pages/legacy-orders',
  myOrderHistoryB2B: '/my-pages/order-history',
  myOrderTemplate: '/my-pages/order-template',
  myOrderTemplateItem: '/my-pages/order-template/[id]',
} as const satisfies Record<string, string>

/**
 * Routes that are accessible only when user is NOT logged in
 */
export const NOT_LOGGED_IN_ROUTES = {
  login: '/login',
} as const satisfies Record<string, string>

/**
 * Routes that are accessible only in development environment
 */
const DEV_ROUTES = {
  maintenance: '/maintenance',
} as const satisfies Record<string, string>

/**
 * API routes.
 * Should always match the routes structure in the `pages/api` folder.
 */
export const API_ROUTES = {
  // TODO: add routes here
} as const satisfies Record<string, string>

export const RoutePaths = {
  ...NOT_LOGGED_IN_ROUTES,
  ...LOGGED_IN_ROUTES,
  ...API_ROUTES,
  ...DEV_ROUTES,
} as const satisfies Record<string, string>

export type RoutePathsKeys = keyof typeof RoutePaths
export type RoutePathsValues = (typeof RoutePaths)[RoutePathsKeys]
