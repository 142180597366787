import { Cart, CartUpdateAction, ErrorResponse, LineItem, ProductProjection } from '@commercetools/platform-sdk'
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react'
import { useMutation, useQuery, useQueryClient, UseQueryResult } from 'react-query'

import { setLineItemDistributionChannel, recalculate } from 'commercetools/api/actions/cart'
import { UpdateCartValue, updateBuCart, createBUCart, fetchBuCart } from 'commercetools/api/cart'
import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'
import { usePriceChannels } from 'commercetools/hooks/use-price-channel'
import { getProductVariant } from 'commercetools/utils/product/getProductVariant'
import { intl } from 'config/intl'
import { arrayHasValue } from 'utils/arrayHasValue'
import { getIntlConfig } from 'utils/getIntlConfig'

import { useCustomer } from './use-customer'

const CART_MUTATION_KEY = 'cart-mutation'
const CART_AND_ORDER_CUSTOM_TYPE_KEY = 'order-normetCartAndOrder'

export type UseCartResult = [
  cart: UseQueryResult<Cart, ErrorResponse> & {
    isUpdating: boolean
    cartQueryKey: string[]
  },
  updateActiveCart: (actions: CartUpdateAction | CartUpdateAction[], message?: string) => Promise<Cart>,
  createActiveCart: (companyId: string, customerId: string | undefined) => Promise<Cart>,
  getLineItemIfExists: (product: ProductProjection) => LineItem | undefined,
]

export const CartContext = createContext<UseCartResult>(undefined!)

interface CartProviderProps {
  children: ReactNode
}

export const CartProvider = ({ children }: CartProviderProps) => {
  const queryClient = useQueryClient()
  const [customer] = useCustomer()
  const businessUnit = useBusinessUnit()
  const { id: priceChannel } = usePriceChannels()

  const cartQueryKey = useMemo(() => {
    return customer.data?.id ? ['customer-cart', customer.data?.id, businessUnit.key, businessUnit.storeKey] : []
  }, [customer.data?.id, businessUnit.key, businessUnit.storeKey])

  const cart = useQuery<Cart, ErrorResponse, Cart>(
    cartQueryKey,
    () => fetchBuCart(customer.data?.id, businessUnit.key, businessUnit.storeKey),
    {
      enabled: customer.data?.id !== undefined && !!businessUnit.key && !!businessUnit.storeKey,
      retry: false,
      onError(error) {
        const ignoredStatusCodes = [403, 404]
        if (!ignoredStatusCodes.includes(error.statusCode)) {
          console.debug('Could not fetch cart:', error.message)
        }
      },
    },
  )

  const cartMutation = useMutation<Cart, ErrorResponse, UpdateCartValue>(updateBuCart, {
    mutationKey: CART_MUTATION_KEY,

    onSuccess(data: Cart) {
      queryClient.setQueryData(cartQueryKey, data)
    },
    onError(error: ErrorResponse) {
      if (error.statusCode !== 409) {
        console.debug({ title: error?.message, status: 'error' })
      }
      queryClient.invalidateQueries(cartQueryKey)
    },
  })

  const createActiveCart = useCallback(
    async (companyId: string, customerId: string | undefined): Promise<Cart> => {
      if (!customerId || !companyId) {
        throw new Error('Customer id or company Id not set when creating cart')
      }

      const intlConfig = getIntlConfig(businessUnit.erpLegalEntity) || intl

      const cart = await createBUCart(
        {
          customerId: customerId,
          currency: intlConfig.currency.code,
          businessUnit: {
            typeId: 'business-unit',
            id: companyId,
          },
          custom: {
            type: {
              typeId: 'type',
              key: CART_AND_ORDER_CUSTOM_TYPE_KEY,
            },
          },
        },
        businessUnit.storeKey,
      )
      if (cart?.id) {
        queryClient.setQueryData(cartQueryKey, cart)
      }
      return cart
    },
    [businessUnit.erpLegalEntity, businessUnit.storeKey, queryClient, cartQueryKey],
  )

  const updateActiveCart = useCallback(
    async (actions: CartUpdateAction | CartUpdateAction[], message?: string): Promise<Cart> => {
      const queryState = queryClient.getQueryState<Cart, ErrorResponse>(cartQueryKey)
      if (queryState?.status === 'loading') {
        await queryClient.fetchQuery(cartQueryKey)
      }
      const currentCartData = queryClient.getQueryData<Cart>(cartQueryKey)
      const cart = currentCartData ? currentCartData : await createActiveCart(businessUnit.id, customer?.data?.id)
      const { id, version } = cart

      return await cartMutation.mutateAsync(
        { id, version, actions, storeKey: businessUnit.storeKey },
        {
          onSuccess() {
            if (message) {
              alert({ title: message })
            }
          },
        },
      )
    },
    [
      queryClient,
      cartQueryKey,
      createActiveCart,
      businessUnit.id,
      businessUnit.storeKey,
      customer?.data?.id,
      cartMutation,
    ],
  )

  const getLineItemIfExists = useCallback(
    (product: ProductProjection): LineItem | undefined => {
      return cart.data?.lineItems?.find((lineItem) => lineItem.variant?.sku === getProductVariant(product)?.sku)
    },
    [cart],
  )

  const updateLineItemsChannel = useCallback(async () => {
    const actions = cart?.data?.lineItems?.map((lineItem) =>
      setLineItemDistributionChannel(lineItem?.id, { id: priceChannel, typeId: 'channel' }),
    )
    if (arrayHasValue(actions)) {
      await updateActiveCart(actions)
      recalculate()
    }

    /*eslint-disable */
  }, [priceChannel, businessUnit.current])

  useEffect(() => {
    if (priceChannel) {
      updateLineItemsChannel()
    }
  }, [priceChannel, businessUnit.current])

  useEffect(() => {
    if (cart.error?.statusCode === 404) cart.remove()
  })

  const value = useMemo<UseCartResult>(
    () => [
      Object.assign(cart, {
        isUpdating: cartMutation.isLoading,
        cartQueryKey,
      }),
      updateActiveCart,
      createActiveCart,
      getLineItemIfExists,
    ],
    [cart, cartMutation.isLoading, cartQueryKey, updateActiveCart, createActiveCart, getLineItemIfExists],
  )

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

export const useCart = (): UseCartResult => {
  return useContext(CartContext)
}
