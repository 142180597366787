import type { AccountInfo } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import { FunctionComponent, ReactNode, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'

import {
  AuthInitContext,
  AuthInitContextValue,
  AuthStatus,
  initialValue,
} from 'components/modules/Auth/AuthInit/context'
import authReducer, { ACTION_LOGIN, ACTION_LOGOUT } from 'components/modules/Auth/AuthInit/reducer'
import useMSALSessionStatus from 'components/modules/Auth/hooks/useMSALSession'
import setStateInHtml from 'components/modules/Auth/utils/setStateInHtml'

interface AuthInitProps {
  children: ReactNode
}

const TAG = 'AuthInit'

/**
 * Regular expression pattern for matching the active account in MSAL.
 * The pattern matches strings in the format "msal.{accountId}.active-account".
 */
const REGEX_MSAL_ACTIVE_ACCOUNT = /^msal\.(.+)\.active-account$/

const AuthInit: FunctionComponent<AuthInitProps> = ({ children }) => {
  const msal = useMsal()
  const user = msal.instance.getActiveAccount()

  const msalStatus = useMSALSessionStatus()

  const [value, dispatch] = useReducer<typeof authReducer>(authReducer, initialValue)

  const status = useRef<AuthStatus | 'initial'>('initial')

  useEffect(() => {
    if (msalStatus === AuthStatus.Authenticated) {
      status.current = AuthStatus.Authenticated
      dispatch({ type: ACTION_LOGIN, payload: { user: user as AccountInfo } })
      setStateInHtml(true)
    }

    if (msalStatus === AuthStatus.Unauthenticated) {
      dispatch({ type: ACTION_LOGOUT })
      setStateInHtml(false)
    }

    /**
     * We don't want to add `user` to the dependencies array, because it's a reference to the object and it will change on every render.
     * We are also sure that `user` is defined when `msalStatus` is `Authenticated`.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msalStatus])

  const invalidate = useCallback<AuthInitContextValue['invalidate']>(async () => {
    dispatch({ type: ACTION_LOGIN, payload: { user: user as AccountInfo } })
    /**
     * We don't want to add `user` to the dependencies array, because it's a reference to the object and it will change on every render.
     * Invalidate should be called only when the `user` is authenticated.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Handles changes in localStorage. That will be invoked e.g. when user logs in/out in another tab.
   * Thanks to that, we will force re-render of this component.
   * So `instance.getActiveAccount()` and `useMSALSessionStatus()` will return the new value.
   */
  const [, setForceUpdate] = useState({})
  useEffect(() => {
    const onStorageChange = (event: StorageEvent): void => {
      if (event.key && REGEX_MSAL_ACTIVE_ACCOUNT.test(event.key)) {
        setForceUpdate({})
      }
    }

    window.addEventListener('storage', onStorageChange)
    return () => {
      window.removeEventListener('storage', onStorageChange)
    }
  }, [])

  const context = useMemo<AuthInitContextValue>(() => ({ ...value, invalidate }), [value, invalidate])

  return <AuthInitContext.Provider value={context}>{children}</AuthInitContext.Provider>
}

AuthInit.displayName = TAG

export default AuthInit
