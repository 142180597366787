import { Flex, HStack, Link, List, SystemStyleInterpolation, useDisclosure, useMediaQuery } from '@chakra-ui/react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { FunctionComponent } from 'react'
import React from 'react'

import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'
import { BranchesButton } from 'components/modules/Branches/BranchesButton'
import { CartButton } from 'components/modules/Cart/CartButton'
import { CartDrawer } from 'components/modules/Cart/CartDrawer'
import { QuickSearchForm } from 'components/modules/Search/QuickSearch/QuickSearchForm'
import { RoutePaths } from 'config/routes'
import { breakpoints } from 'theme/foundations/breakpoints'

const styles = {
  nav: {
    display: { base: 'none', md: 'flex' },
    minW: '0',
    justifyContent: 'center',
    w: 'full',
    maxW: 'desktop',
  },
  list: {
    direction: 'row',
    px: { base: 0, '2xl': 10 },
    maxW: '33%',
    w: 'full',
    spacing: { base: '6', xl: '10' },
    mr: { md: 6 },
  },
  link: { color: 'primary.white', textDecoration: 'none' },
  search: { flexBasis: '100%', maxW: '47%' },
  buttons: {
    py: 1,
    maxW: '20%',
    w: 'full',
    justifyContent: 'flex-end',
    alignItems: 'center',
    ml: { md: 6 },
  },
} as const satisfies SystemStyleInterpolation

const LINKS = [
  { href: RoutePaths.myEquipment, title: 'my-equipment' },
  { href: RoutePaths.myOrderHistoryB2B, title: 'order-history' },
  { href: RoutePaths.myOrderTemplate, title: 'order-templates' },
]

const Navigation: FunctionComponent = () => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const cartDrawer = useDisclosure()
  const businessUnit = useBusinessUnit()
  const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.lg})`)

  const onOpenCartDrawer = async () => {
    // Disable minicart on cart page and mobile
    if (!router.pathname.includes(RoutePaths.cart) && isDesktop) {
      cartDrawer.onOpen()
    } else {
      router.push(RoutePaths.cart)
    }
  }

  const getAriaCurrent = (href: string) => {
    const cleanPath = router.asPath.split('#')[0].split('?')[0]
    return cleanPath === href ? 'page' : undefined
  }

  return (
    <Flex as="nav" {...styles.nav}>
      <HStack as={List} {...styles.list}>
        {LINKS.map(({ href, title }) => {
          return (
            <React.Fragment key={title}>
              <Link
                as={NextLink}
                variant="ghost"
                {...styles.link}
                href={href}
                aria-current={getAriaCurrent(href)}
                rel="noreferrer"
              >
                {t(title)}
              </Link>
            </React.Fragment>
          )
        })}
      </HStack>
      <QuickSearchForm {...styles.search} />
      <Flex {...styles.buttons}>
        <BranchesButton currentCompany={businessUnit.current} />
        <CartButton onClick={onOpenCartDrawer} />
      </Flex>
      <CartDrawer {...cartDrawer} />
    </Flex>
  )
}

export default Navigation
