import { ProductVariantAvailability } from '@commercetools/platform-sdk'

export enum AvailabilityStatus {
  AvailableInCurrent,
  AvailableInOther,
  NotAvailable,
}

export const getAvailabilityStatus = function (
  availability: ProductVariantAvailability | undefined,
  selectedChannelId: string,
): AvailabilityStatus {
  if (!availability || !availability.channels || selectedChannelId === '') {
    return AvailabilityStatus.NotAvailable
  }
  if (availability.channels[selectedChannelId]?.isOnStock) {
    return AvailabilityStatus.AvailableInCurrent
  }

  if (Object.values(availability.channels).some((channel) => channel.isOnStock)) {
    return AvailabilityStatus.AvailableInOther
  }
  return AvailabilityStatus.NotAvailable
}
