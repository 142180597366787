import { Flex, Link, ListItem, ListItemProps, Text, VStack } from '@chakra-ui/react'
import { LineItem, ProductProjection } from '@commercetools/platform-sdk'
import truncate from 'lodash/truncate'
import NextLink from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import React, { Dispatch, SetStateAction } from 'react'

import { getLocalizedString } from 'commercetools/utils/getLocalizedString'
import { getAttributeValue } from 'commercetools/utils/product/getAttributeValue'
import { getProductName } from 'commercetools/utils/product/getProductName'
import { getProductVariant } from 'commercetools/utils/product/getProductVariant'
import { Availability } from 'components/modules/Availability/Availability'
import { Price } from 'components/modules/Price/Price'
import { QuantityInput } from 'components/modules/QuantityInput/QuantityInput'
import { pageTypes } from 'gtm/pageTypes'

type HeaderSearchResultItemProps = ListItemProps & {
  product: ProductProjection
  lineItem?: LineItem
  setQuantityDialogIsOpen: Dispatch<SetStateAction<boolean>>
  setAvailabilityDialogIsOpen: Dispatch<SetStateAction<boolean>>
}

export const QuickSearchResultItem = ({
  product,
  lineItem,
  setQuantityDialogIsOpen,
  setAvailabilityDialogIsOpen,
  ...props
}: HeaderSearchResultItemProps): JSX.Element => {
  const { lang } = useTranslation()
  const name = getProductName(product, lang)
  const formattedName = name ? truncate(name, { length: 32, omission: '...' }) : ''
  const productVariant = getProductVariant(product)
  const slug = getLocalizedString(product.slug, lang) || product?.slug?.en

  return (
    <ListItem {...props} py={2}>
      <Flex alignItems="start" width="full">
        <VStack spacing="1px" alignItems="start" w="60">
          <Text fontSize="sm">{getAttributeValue(productVariant?.attributes, 'itemNumber')}</Text>
          <Link
            as={NextLink}
            variant="ghost"
            href={`/product/${slug}`}
            fontSize="sm"
            fontWeight="bold"
            textTransform="uppercase"
          >
            {formattedName}
          </Link>
          <Availability
            setAvailabilityDialogIsOpen={setAvailabilityDialogIsOpen}
            fontSize="xs"
            partNumber={getAttributeValue(productVariant?.attributes, 'itemNumber')}
            availability={productVariant?.availability}
          />
        </VStack>
        <Price price={product.standalonePrice?.value} justifySelf="start" mr="auto" />
        <QuantityInput
          ml={6}
          gtagPageType={pageTypes.quickSearch}
          product={product}
          lineItem={lineItem}
          setQuantityDialogIsOpen={setQuantityDialogIsOpen}
        />
      </Flex>
    </ListItem>
  )
}

export const MemoizedQuickSearchResultItem = React.memo(QuickSearchResultItem)
