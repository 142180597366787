import Head from 'next/head'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent } from 'react'

interface SEOProps extends Partial<SEO> {}

const TAG = 'Head'

/**
 * SEO component which will add all required meta tags.
 *
 * Note: each `meta` tag has attribute `key`. DO NOT REMOVE `key` ATTRIBUTE!.
 * This attribute will make sure the tag is only rendered once
 *
 * @example
 * SEO using layout props:
 * ```tsx
 * // pages/index.tsx
 * import type { GetStaticProps } from 'next'
 * import React from 'react'
 *
 * const Page: NextPageWithLayout = () => {
 *   return <span>Page</span>
 * }
 *
 * // This way will work out also with `getServerSideProps`
 * export const getStaticProps: GetStaticProps<AppPageLayoutProps> = () => {
 *   const pageContent = await getHomePageData()
 *   return {
 *     props: {
 *       layout: {
 *         props: {
 *           seo: pageContent.seoMetaTags
 *         },
 *       },
 *     },
 *   }
 * }
 *
 * export default Page
 * ```
 */
const SEO: FunctionComponent<SEOProps> = ({ title, description, image, keywords }) => {
  const { t } = useTranslation('common')
  const siteName = t('seo.default.title')
  const pageTitle = title && title !== siteName ? `${title} - ${siteName}` : siteName

  return (
    <Head>
      <title key="title">{pageTitle}</title>
      <meta key="twitter:title" property="twitter:title" content={pageTitle} />
      <meta key="og:title" property="og:title" content={pageTitle} />

      {description ? (
        <>
          <meta key="description" name="description" content={description} />
          <meta key="og:description" property="og:description" content={description} />
          <meta key="twitter:description" property="twitter:description" content={description} />
        </>
      ) : null}

      {image && (
        <>
          <meta key="og:image:type" property="og:image:type" content={image.contentType} />
          {image.width && <meta key="og:image:width" property="og:image:width" content={`${image.width}`} />}
          {image.height && <meta key="og:image:height" property="og:image:height" content={`${image.height}`} />}
          <meta key="og:image:alt" property="og:image:alt" content={image.title} />
          <meta key="og:image" property="og:image" content={image.url} />

          <meta key="twitter:image" property="twitter:image" content={image.url} />
          <meta key="twitter:card" property="twitter:card" content="summary_large_image" />
        </>
      )}

      {keywords?.length ? <meta key="keywords" name="keywords" content={keywords.join(',')} /> : null}
    </Head>
  )
}

SEO.displayName = TAG

export default SEO
