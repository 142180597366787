export const FormError = {
  baseStyle: {
    text: {
      //Setting this in pixels so it does not change if scaling font size
      fontSize: '12px',
      color: 'primary.red',
      //Used so that form does not jump when error is displayed (fonsize 12 * lineheight 1.2 + margintop 8px = 23
      marginBottom: '-23px',
    },
  },
}
