import { LocalizedString } from '@commercetools/platform-sdk'

import { intl } from 'config/intl'

export function getLocalizedString(
  localizedString: LocalizedString | string | undefined,
  language: string,
): string | undefined {
  return typeof localizedString === 'string'
    ? localizedString
    : localizedString?.[language] || localizedString?.[intl.lang] || localizedString?.en || undefined
}
