import { Button, Divider, Radio, RadioGroup, Stack, SystemStyleInterpolation, Text } from '@chakra-ui/react'
import { BusinessUnit } from '@commercetools/platform-sdk'
import useTranslation from 'next-translate/useTranslation'
import { FunctionComponent, useEffect, useId } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import { useBranches } from 'commercetools/hooks/use-branches'
import { getLocalizedString } from 'commercetools/utils/getLocalizedString'
import { events } from 'gtm/events'
import { gtagEvent } from 'gtm/gtag'

interface Props {
  currentCompany?: BusinessUnit
  onClose: () => void
  isOpen?: boolean
}

interface ChangeBranchSchema {
  branch: string
}

const styles = {
  form: {
    spacing: '4',
    p: { base: 4, lg: 0 },
  },
  stack: {
    spacing: '4',
    direction: 'column',
  },
  radio: {
    size: 'lg',
    fontSize: 'xs',
    colorScheme: 'gray',
  },
  recommended: {
    color: 'secondary.grey.900',
    fontSize: 'xs',
    ml: 2,
  },
  submit: { mt: '5', w: 'full' },
} as const satisfies SystemStyleInterpolation

export const BranchesSelect: FunctionComponent<Props> = ({ currentCompany, onClose, isOpen }) => {
  const { t, lang } = useTranslation('common')
  const branches = useBranches()

  const { register, handleSubmit, setValue, watch } = useForm<ChangeBranchSchema>({
    mode: 'onChange',
    defaultValues: { branch: branches.id },
  })

  useEffect(() => {
    if (isOpen) {
      setValue('branch', branches.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const onSubmit: SubmitHandler<ChangeBranchSchema> = (values) => {
    branches.setCurrentBranchId(values.branch)
    gtagEvent({ event: events.changeBranch })
    onClose()
  }

  const fields = currentCompany?.custom?.fields || {}
  const defaultBranchId = fields.ctDefaultNormetBranch?.id || fields.defaultNormetBranch?.id || branches.id

  const sortedChannels = [
    ...branches.all.filter((channel) => channel.id === defaultBranchId),
    ...branches.all.filter((channel) => channel.id !== defaultBranchId),
  ]

  useEffect(() => {
    if (!branches.id) {
      branches.setCurrentBranchId(defaultBranchId)
    }
  }, [branches, branches.id, defaultBranchId])

  const formId = useId()

  return (
    <Stack as="form" onSubmit={handleSubmit(onSubmit)} id={formId} name={formId} {...styles.form}>
      <RadioGroup value={watch('branch')} zIndex={3}>
        <Stack {...styles.stack} divider={<Divider />}>
          {sortedChannels.map((branch) => (
            <Radio key={branch.id} value={branch.id} {...styles.radio} {...register('branch')}>
              {getLocalizedString(branch?.name || '', lang)}

              {defaultBranchId === branch.id && (
                <Text as="span" {...styles.recommended}>
                  {t('recommended')}
                </Text>
              )}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>

      <Button type="submit" {...styles.submit}>
        {t('change-warehouse')}
      </Button>
    </Stack>
  )
}
