export const ScrollableFlex = {
  baseStyle: {
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '8px',
      background: 'primary.grey.500',
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'secondary.grey.900',
      borderRadius: '8px',
    },
  },
}
