import type { QueryFunctionContext } from 'react-query'

import client from 'bom/client'

type ArgumentsEquipment = QueryFunctionContext<[queryFnName: 'getCustomerEquipment', companyNumber: string | undefined]>

export function getCustomerEquipment({
  signal,
  queryKey: [, companyNumber],
}: ArgumentsEquipment): Promise<BOM.Equipment[]> {
  return client<BOM.Equipment[]>(`equipment/customers/${companyNumber}/equipments/`, { signal })
}

type ArgumentsEquipmentForProduct = QueryFunctionContext<
  [queryFnName: 'getCustomerEquipmentForProduct', companyNumber: string | undefined, sku: string | undefined]
>

export function getCustomerEquipmentForProduct({
  signal,
  queryKey: [, companyNumber, sku],
}: ArgumentsEquipmentForProduct): Promise<BOM.Equipment[]> {
  return client<BOM.Equipment[]>(`equipment/customers/${companyNumber}/components/${sku}/equipments`, { signal })
}
