import type { ProductProjectionPagedSearchResponse } from '@commercetools/platform-sdk'

import { getStandalonePrices } from 'commercetools/api/standalone-price'
import { SearchQueryParams } from 'commercetools/types'
import { buildURLSearchParams } from 'commercetools/utils/buildURLSearchParams'
import { getProductVariant } from 'commercetools/utils/product/getProductVariant'

import client from '../client'

const DEFAULT_PAGE_SIZE = 20

export const fetchProductSearch = async (
  {
    priceCurrency,
    priceChannel,
    searchQuery,
    language,
    category: _,
    page = 1,
    limit = DEFAULT_PAGE_SIZE,
    sort,
    filter,
    ...params
  }: SearchQueryParams,
  token?: string,
  signal?: AbortSignal,
): Promise<ProductProjectionPagedSearchResponse> => {
  const searchParams: SearchQueryParams = {
    ['text.' + language]: searchQuery,
    priceCurrency,
    priceChannel,
    offset: limit * (page - 1),
    limit,
    sort,
    filter,
    localeProjection: language,
    markMatchingVariants: true,
    ...params,
  }

  const products = await client.get<ProductProjectionPagedSearchResponse>(
    `/product-projections/search?${buildURLSearchParams(searchParams)}`,
    token,
    { signal },
  )

  const skus = products.results.map((product) => getProductVariant(product)?.sku).filter((sku): sku is string => !!sku)
  const prices = skus.length
    ? await getStandalonePrices({
        signal,
        meta: {},
        queryKey: ['getStandalonePrice', skus, priceChannel],
      })
    : {}

  return {
    ...products,
    results: products.results.map((product) => {
      const sku = getProductVariant(product)?.sku
      const price = sku && prices[sku]
      return { ...product, standalonePrice: price || undefined }
    }),
  }
}
