import { Icon, IconProps } from '@chakra-ui/react'

export function AddToCartIcon(props: IconProps) {
  return (
    <Icon width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M29.729661,25.8940948 L26.5508475,25.8940948 C26.1881181,25.8940948 25.8940678,26.1881451 25.8940678,26.5508745 L25.8940678,29.729688 C25.8940678,30.8178762 25.011917,31.700027 23.9237288,31.700027 C22.8355406,31.700027 21.9533898,30.8178762 21.9533898,29.729688 L21.9533898,26.5508745 C21.9533898,26.1881451 21.6593396,25.8940948 21.2966102,25.8940948 L18.170339,25.8940948 C17.0821508,25.8940948 16.2,25.011944 16.2,23.9237558 C16.2,22.8355676 17.0821508,21.9534168 18.170339,21.9534168 L21.3491525,21.9534168 C21.7118819,21.9534168 22.0059322,21.6593666 22.0059322,21.2966372 L22.0059322,18.170366 C22.0059322,17.0821778 22.888083,16.200027 23.9762712,16.200027 C25.0644594,16.200027 25.9466102,17.0821778 25.9466102,18.170366 L25.9466102,21.3491795 C25.9466102,21.7119089 26.2406604,22.0059592 26.6033898,22.0059592 L29.729661,22.0059592 C30.8178492,22.0059592 31.7,22.88811 31.7,23.9762982 C31.7,25.0644864 30.8178492,25.9466372 29.729661,25.9466372 L29.729661,25.8940948 Z" />
      <path d="M16.88,18.6666937 C16.88,17.930314 16.2830463,17.3333603 15.5466667,17.3333603 L10.9866667,17.3333603 C10.8330021,17.336725 10.698651,17.2303637 10.6666667,17.080027 L9.13333333,10.4133603 C9.09331176,10.3196779 9.09331176,10.2137095 9.13333333,10.120027 C9.19899631,10.0410912 9.29737344,9.9968215 9.4,10.000027 L27.04,10.000027 C27.2558785,10.0031547 27.457979,10.1066696 27.5866667,10.280027 C27.712205,10.4539798 27.7422422,10.679259 27.6666667,10.880027 L26.6666667,13.880027 C26.4389447,14.5795446 26.8208019,15.331326 27.52,15.560027 C27.6523625,15.6080324 27.7925899,15.6306498 27.9333333,15.6266937 C28.5209807,15.6208083 29.0354799,15.2308721 29.2,14.6666937 L30.5333333,10.6666937 C30.7798675,9.8887083 30.6406905,9.03979698 30.1586421,8.38126097 C29.6765938,7.72272496 28.9094462,7.33348807 28.0933333,7.33336033 L8.68,7.33336033 C8.52965942,7.32730804 8.40038732,7.22496762 8.36,7.080027 L7.18666667,1.98669367 C6.91852295,0.81975638 5.87733613,-0.00543955824 4.68,0 L1.33333333,0 C0.596953667,0 0,0.596980666 0,1.33336033 C0,2.06974 0.596953667,2.66669367 1.33333333,2.66669367 L4.32,2.66669367 C4.4786021,2.65665228 4.62054262,2.76452708 4.65333333,2.920027 L9.13333333,22.3866937 C9.16265242,22.5253944 9.1045658,22.6679707 8.98666667,22.7466937 C8.1443443,23.3006635 7.64189735,24.2452637 7.65333333,25.2533603 C7.66334087,26.7191033 8.73105425,27.9630088 10.1783483,28.1950487 C11.6256424,28.4270886 13.0287078,27.5793149 13.4964309,26.190165 C13.9641541,24.801015 13.3595721,23.2772717 12.0666667,22.5866937 C11.9784702,22.5469081 11.9142221,22.4678335 11.8933333,22.3733603 L11.4533333,20.4533603 C11.4082379,20.3654741 11.4082379,20.2612465 11.4533333,20.1733603 C11.5146265,20.0944958 11.6069573,20.0459007 11.7066667,20.040027 L15.5066667,20.040027 C15.8742171,20.0512293 16.2300765,19.9101397 16.4900946,19.6501216 C16.7501127,19.3901035 16.8912023,19.0342441 16.88,18.6666937 Z" />
    </Icon>
  )
}
