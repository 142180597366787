export const Table = {
  baseStyle: {
    tbody: {
      tr: {
        _hover: {
          td: {
            bgColor: 'secondary.grey.100 !important',
          },
        },
      },
    },
  },
  variants: {
    simple: {
      tr: {
        _last: {
          td: {
            border: 'none',
          },
        },
      },
    },
    'no-borders': {
      td: {
        border: 'none',
        p: 2,
      },
    },
    responsive: {
      thead: {
        fontSize: 'xs',
        tr: {
          borderBottom: '1px solid',
          borderColor: 'primary.grey.500',
          h: 12,
          th: {
            ':nth-of-type(2)': { p: { base: '4', md: '0' } },
            _first: {
              position: { base: 'sticky', md: 'static' },
              left: '0',
              backgroundColor: 'white',
              boxShadow: { base: 'inset -8px 0px 9px -10px grey', md: 'none' },
              p: { base: '4', md: '0' },
            },
            textTransform: 'none',
            p: { base: '4', md: '0' },
          },
        },
      },
      tbody: {
        tr: {
          borderBottom: '1px solid',
          borderColor: 'primary.grey.500',
          p: 0,
          h: 16,
          td: {
            ':nth-of-type(2)': { p: { base: '4', md: '0' } },
            _first: {
              position: { base: 'sticky', md: 'static' },
              left: 0,
              backgroundColor: 'white',
              boxShadow: { base: 'inset -8px 0px 9px -10px grey', md: 'none' },
              p: { base: '4', md: '0' },
            },
            p: { base: '4', md: '0' },
          },
        },
      },
    },
    // This variant is dedicated for responsive tables where the first column is the "Add to order template" button
    'order-template-sticky': {
      thead: {
        fontSize: 'xs',
        tr: {
          borderBottom: '1px solid',
          borderColor: 'primary.grey.500',
          h: 12,
          th: {
            _first: {
              position: { base: 'sticky', md: 'static' },
              left: '0',
              backgroundColor: 'white',
              p: { base: '4', md: '0' },
            },
            ':nth-of-type(2)': {
              position: { base: 'sticky', md: 'static' },
              left: '52px',
              backgroundColor: 'white',
              boxShadow: { base: 'inset -8px 0px 9px -10px grey', md: 'none' },
              p: { base: '4', md: '0' },
            },
            textTransform: 'none',
            p: { base: '4', md: '0' },
          },
        },
      },
      tbody: {
        tr: {
          borderBottom: '1px solid',
          borderColor: 'primary.grey.500',
          p: 0,
          h: 16,
          td: {
            _first: {
              position: { base: 'sticky', md: 'static' },
              left: 0,
              backgroundColor: 'white',
              p: { base: '4', md: '0' },
            },
            ':nth-of-type(2)': {
              position: { base: 'sticky', md: 'static' },
              left: '52px',
              backgroundColor: 'white',
              boxShadow: { base: 'inset -8px 0px 9px -10px grey', md: 'none' },
              p: { base: '4', md: '0' },
            },
            p: { base: '4', md: '0' },
          },
        },
      },
    },
    beige: {
      thead: {
        tr: {
          h: '48px',
          th: {
            textTransform: 'none',
            fontSize: 'xs',
            pt: 0,
          },
        },
      },
      tbody: {
        tr: {
          h: '64px',
          bgColor: 'secondary.beige.100',
          borderBottom: '1px solid',
          borderColor: 'primary.white',
        },
      },
    },
  },
}
