import { type Configuration, type RedirectRequest, type SilentRequest } from '@azure/msal-browser'

const tenant = process.env.AZURE_AD_B2C_TENANT_NAME
const signInFlow = process.env.AZURE_AD_B2C_SIGN_IN_FLOW
const resetPasswordFlow = process.env.AZURE_AD_B2C_RESET_PASSWORD_FLOW
const clientId = process.env.AZURE_AD_B2C_CLIENT_ID
const redirectUri = process.env.AZURE_AD_B2C_REDIRECT_URI

export const msalConfig: Configuration = {
  auth: {
    clientId,
    authority: `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${signInFlow}`,
    knownAuthorities: [`${tenant}.b2clogin.com`],
    redirectUri,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
    secureCookies: true,
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
  },
}

export const loginRequest: RedirectRequest = {
  scopes: ['openid', 'profile', `https://${tenant}.onmicrosoft.com/cpimextensions/user_impersonation`],
}

export const resetPasswordRequest: RedirectRequest = {
  authority: `https://${tenant}.b2clogin.com/${tenant}.onmicrosoft.com/${resetPasswordFlow}`,
  scopes: [],
}

export const tokenRequest: SilentRequest = {
  scopes: ['openid', 'offline_access', clientId],
  forceRefresh: false,
}
