import { Icon, IconProps } from '@chakra-ui/react'

export function AttachFileIcon(props: IconProps) {
  return (
    <Icon width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M28.1388383,27.5210009 C28.1495663,29.1097948 27.3116967,30.5855749 25.9383803,31.3899445 C24.5496334,32.2033518 22.8298691,32.2033518 21.4411222,31.3899445 C20.0678058,30.5855749 19.2299362,29.1097948 19.2405624,27.5210009 L19.2412308,27.4798719 L19.2412308,18.3114907 C19.2412308,16.0462168 20.44974,13.9530173 22.4115248,12.8203803 C24.3733096,11.6877433 26.7903282,11.6877433 28.752113,12.8203803 C30.6942799,13.9416909 31.8981615,16.0044488 31.9220449,18.2435849 L31.922407,18.3114907 L31.922407,26.312044 C31.922407,26.679341 31.6246539,26.9770941 31.2573569,26.9770941 C30.898222,26.9770941 30.6055745,26.6924275 30.5927453,26.3364249 L30.5923067,26.312044 L30.5923067,18.3114907 C30.5923067,16.5214158 29.6373132,14.8673183 28.0870628,13.9722809 C26.5368125,13.0772434 24.6268253,13.0772434 23.076575,13.9722809 C21.5424731,14.8579951 20.5912914,16.4870378 20.5716416,18.2555958 L20.5713311,18.3114907 L20.5712142,27.4923398 C20.5500619,28.6204233 21.1397821,29.6719862 22.1133581,30.2422225 C23.086934,30.8124588 24.2925685,30.8124588 25.2661444,30.2422225 C26.2254031,29.6803721 26.8120128,28.6512475 26.8088258,27.5420573 L26.8081714,27.4798719 L26.8081714,19.1295024 C26.8081714,18.6913688 26.5744299,18.2865167 26.1949952,18.0674499 C25.8155604,17.8483832 25.3480774,17.8483832 24.9686427,18.0674499 C24.5994629,18.280596 24.3682075,18.6696206 24.3559769,19.094054 L24.3554664,19.1295024 L24.3554664,26.312044 C24.3554664,26.679341 24.0577134,26.9770941 23.6904163,26.9770941 C23.3312814,26.9770941 23.038634,26.6924275 23.0258048,26.3364249 L23.0253662,26.312044 L23.0253662,19.1295024 C23.0253661,18.2161698 23.5126233,17.3722156 24.3035925,16.9155493 C25.0945618,16.458883 26.0690761,16.458883 26.8600453,16.9155493 C27.6378318,17.3646045 28.1219454,18.1881456 28.1378665,19.0838951 L28.1382717,19.1295024 L28.1382717,27.4798719 L28.1388383,27.5210009 Z" />
      <path d="M16.7978366,0 C17.4864641,0 18.1476108,0.267220053 18.6429098,0.74427152 L18.6780044,0.778702706 L22.4982031,4.59890145 C22.9850668,5.0860773 23.2636093,5.74253904 23.2763014,6.42977945 L23.2767551,6.47891852 L23.2767551,9.31070204 C23.2767551,9.6779991 22.979002,9.97575219 22.611705,9.97575219 C22.2525701,9.97575219 21.9599226,9.69108553 21.9470934,9.33508298 L21.9466548,9.31070204 L21.9466548,6.47891852 C21.9466548,6.13917037 21.816453,5.81269861 21.5838,5.56631649 L21.5575295,5.53927368 L17.7376321,1.7193763 C17.4971289,1.47902715 17.1742016,1.3402414 16.8355284,1.3306347 L16.7978366,1.33010029 L2.66020058,1.33010029 C1.93657057,1.33010029 1.34790758,1.90796283 1.33049675,2.62740278 L1.33010029,2.66020058 L1.33010029,24.6068554 C1.33010029,25.3304854 1.90796283,25.9191484 2.62740278,25.9365592 L2.66020058,25.9369557 L15.9612035,25.9369557 C16.3285006,25.9369557 16.6262536,26.2347088 16.6262536,26.6020058 C16.6262536,26.9611407 16.341587,27.2537882 15.9855844,27.2666174 L15.9612035,27.267056 L2.66020058,27.267056 C1.20570425,27.267056 0.023848065,26.0997448 0.000356390874,24.6508467 L0,24.6068554 L0,2.66020058 C0,1.20570425 1.16731122,0.023848065 2.6162093,0.000356390874 L2.66020058,0 L16.7978366,0 Z" />
    </Icon>
  )
}
