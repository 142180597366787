import type { Customer, CustomerUpdateAction, ErrorResponse } from '@commercetools/platform-sdk'
import { useRouter } from 'next/router'
import { createContext, FunctionComponent, ReactNode, useCallback, useContext, useMemo } from 'react'
import { useQuery, useQueryClient, UseQueryResult } from 'react-query'

import { fetchCustomer, updateCustomer } from 'commercetools/api/customer'
import { UseCustomerMutation } from 'commercetools/types'
import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useMSALSessionStatus from 'components/modules/Auth/hooks/useMSALSession'
import { RoutePaths } from 'config/routes'
import { appInsights } from 'logging/appInsight'

interface CustomerProviderProps {
  children: ReactNode
}

type UseCustomerResult = [customer: UseQueryResult<Customer, ErrorResponse>]

const CustomerContext = createContext<UseCustomerResult>([] as unknown as UseCustomerResult)

const isInsufficientScopeError = (error: ErrorResponse): boolean =>
  (error && 'error' in error && typeof error.error === 'string' && error.error === 'insufficient_scope') || false

export const CustomerProvider: FunctionComponent<CustomerProviderProps> = ({ children }) => {
  const msalStatus = useMSALSessionStatus()

  const customer = useQuery<Customer, ErrorResponse>(['customer'], () => fetchCustomer(), {
    enabled: msalStatus === AuthStatus.Authenticated,
  })
  const router = useRouter()
  const queryClient = useQueryClient()

  if (customer.status === 'error' && isInsufficientScopeError(customer.error)) {
    queryClient.removeQueries()
    router.push(RoutePaths.login)
  }

  const value = useMemo<UseCustomerResult>(() => [customer], [customer])

  return <CustomerContext.Provider value={value}>{children}</CustomerContext.Provider>
}

export const useCustomer = (): UseCustomerResult => useContext(CustomerContext)

const state = {
  actionsQueue: [] as CustomerUpdateAction[],
  isUpdating: false,
}

export function useCustomerMutation(): UseCustomerMutation {
  const [customer] = useCustomer()
  const queryClient = useQueryClient()

  const updateCurrentCustomer = useCallback(
    async (actions: CustomerUpdateAction | CustomerUpdateAction[]): Promise<Customer> =>
      new Promise((resolve) => {
        if (state.isUpdating) {
          state.actionsQueue = state.actionsQueue.concat(actions)
        } else {
          const queryData = queryClient.getQueryData<Customer>(['customer', customer?.data?.id]) as Customer
          const version = queryData ? queryData.version : (customer?.data?.version as number)

          state.isUpdating = true
          updateCustomer({ version, actions })
            .then((customer) => {
              queryClient.setQueryData(['customer', customer.id], customer)
              state.isUpdating = false
              resolve(customer)
            })
            .catch((error) => {
              if (error?.statusCode !== 409) {
                console.error(error.message)
                appInsights.trackException({ exception: new Error(error) })
              }
            })
            .finally(() => {
              state.isUpdating = false
              queryClient.invalidateQueries(['customer'])
            })
        }
      }),
    [customer?.data, queryClient],
  )

  return {
    updateCurrentCustomer,
    isUpdating: state.isUpdating,
  }
}
