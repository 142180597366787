import { HStack, Link } from '@chakra-ui/react'
import NextLink from 'next/link'
import { ReactNode } from 'react'

import { AttachFileIcon } from 'components/elements/Icons/AttachFileIcon'

type Props = {
  children: ReactNode
}

export function AddToCartFromFileLink({ children }: Props) {
  return (
    <HStack w="max-content">
      <Link as={NextLink} fontSize="md" fontWeight="bold" whiteSpace="nowrap" href="/add-to-cart-from-file">
        {children}
      </Link>
      <AttachFileIcon color="primary.red" boxSize={5} mr={2} />
    </HStack>
  )
}
