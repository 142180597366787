import { Box, Flex, StackProps, Text } from '@chakra-ui/react'
import { TypedMoney } from '@commercetools/platform-sdk'
import useTranslation from 'next-translate/useTranslation'

import { getFormattedPrice } from 'utils/getFormattedPrice'

type Props = StackProps & {
  price: TypedMoney | undefined
  size?: string
}

export function Price({ price, size = 'md', ...props }: Props) {
  const { t, lang } = useTranslation('common')

  if (!price || price?.centAmount <= 0) {
    return (
      <Box {...props}>
        <Text opacity="0.5">{t('no-price-placeholder')}</Text>
      </Box>
    )
  }

  const { currencySymbol, formattedValueWithoutDecimals, decimals } = getFormattedPrice(price, lang) || {}

  return (
    <Flex {...props}>
      <Text
        mt={size === 'lg' ? '1' : '0.5'}
        mr={0.5}
        fontSize={size === 'lg' ? 'sm' : 'xs'}
        fontWeight="normal"
        lineHeight="none"
      >
        {currencySymbol}
      </Text>
      <Text fontSize={size === 'lg' ? 'lg' : 'md'} mr={0.5} lineHeight="none">
        {formattedValueWithoutDecimals}
      </Text>
      <Text mt={size === 'lg' ? '0.5' : 'px'} fontSize={size === 'lg' ? 'xs' : '2xs'} lineHeight="none">
        {decimals}
      </Text>
    </Flex>
  )
}
