import React from 'react'

import getLayoutMain, { LayoutMainProps } from 'components/layouts/Main'
import GuardAuthorized from 'components/modules/Auth/GuardAuthorized'

export interface LayoutAuthorizedProps extends LayoutMainProps {}

const LayoutAuthorized: LayoutComponent<LayoutAuthorizedProps> = (props, page) =>
  getLayoutMain(props)(<GuardAuthorized>{page}</GuardAuthorized>)

function getLayoutAuthorized(props: LayoutAuthorizedProps = {}): GetLayout {
  return (page) => LayoutAuthorized(props, page)
}

export default getLayoutAuthorized
