import type { BusinessUnit } from '@commercetools/platform-sdk'

/**
 * Checks if the business unit aka company has access to the store
 */
function hasBusinessUnitAccessToStore(businessUnit: DeepPartial<BusinessUnit> | undefined | null): boolean {
  const fields = businessUnit?.custom?.fields
  return fields
    ? fields.ctAllowStoreAccess ??
        /**
         * Backward compatibility
         * `??` - We use the old field only when the new one is not present
         */
        fields.allowStoreAccess
    : false
}

export default hasBusinessUnitAccessToStore
