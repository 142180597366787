import type { Customer } from '@commercetools/platform-sdk'

/**
 * Returns the customer name as a string.
 * @example
 * ```ts
 * const customer: Partial<Customer> = {
 *   title: 'Mr',
 *   firstName: 'John',
 *   middleName: 'Doe',
 *   lastName: 'Smith',
 * };
 * getCustomerFullName(customer) // 'Mr John Doe Smith'
 * ```
 */
function getCustomerFullName(customer: Partial<Customer> | undefined | null): string {
  if (!customer) {
    return ''
  }

  return [customer.title, customer.firstName, customer.middleName, customer.lastName].filter(Boolean).join(' ')
}

export default getCustomerFullName
