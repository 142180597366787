import { default as NextHead } from 'next/head'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useMemo } from 'react'

import CanonicalURL from 'components/modules/Head/CanonicalURL'
import SEO from 'components/modules/SEO'

const TAG = 'Head'

const Head: FunctionComponent = () => {
  const { t } = useTranslation('common')

  const DEFAULT_SEO_TAGS = useMemo<SEO>(
    () => ({
      title: t('seo.default.title'),
      description: t('seo.default.description'),
      keywords: t('seo.default.keywords').split(','),
      image: {
        url: '/images/og-image-default.jpg',
        width: 1200,
        height: 630,
        title: t('seo.default.title'),
        contentType: 'image/jpeg',
      },
    }),
    [t],
  )

  const SITE_TITLE = t('seo.default.pageTitle')

  return (
    <>
      <SEO {...DEFAULT_SEO_TAGS} />
      <CanonicalURL />
      <NextHead>
        <meta name="og:site_name" content={SITE_TITLE} />
        <meta name="apple-mobile-web-app-title" content={SITE_TITLE} />
        <meta name="application-name" content={SITE_TITLE} />
        <meta property="og:type" content="website" />
      </NextHead>
    </>
  )
}

Head.displayName = TAG

export default Head
