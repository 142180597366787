import { Icon, IconProps } from '@chakra-ui/react'

export function InfoIcon(props: IconProps) {
  return (
    <Icon width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8,2.14359355 C3.0495827,5.00171824 0,10.2837505 0,16 C0,21.7162494 3.04958264,26.9982817 8,29.8564065 C12.950417,32.7145312 19.0495825,32.7145312 24,29.8564065 C28.9504169,26.9982817 32,21.7162494 32,16 C32,10.2837505 28.9504169,5.00171824 24,2.14359355 C19.0990867,-0.685949936 13.0722855,-0.714245371 8.14885466,2.05870725 L8,2.14359355 Z M22.7692305,4.27534835 C26.958045,6.69376155 29.5384611,11.1631735 29.5384611,15.9999998 C29.5384611,20.8368265 26.958045,25.3062384 22.7692305,27.7246516 C18.580416,30.1430649 13.4195836,30.1430649 9.23076905,27.7246516 C5.04195451,25.3062384 2.46153843,20.8368265 2.46153843,15.9999998 C2.46153843,11.1631735 5.04195456,6.69376155 9.23076905,4.27534835 C13.4195836,1.85693512 18.580416,1.85693512 22.7692305,4.27534835 Z" />
      <path d="M15.9999998,12.3076924 C16.6678097,12.3076924 17.2113948,12.8395616 17.2302625,13.5028072 L17.230769,13.5384616 L17.230769,23.3846153 C17.230769,24.0643504 16.6797348,24.6153845 15.9999998,24.6153845 C15.3321899,24.6153845 14.7886047,24.0835153 14.7697371,23.4202697 L14.7692306,23.3846153 L14.7692306,14.7692306 L13.5384614,14.7692308 C12.8706515,14.7692308 12.3270663,14.2373616 12.3081987,13.574116 L12.3076921,13.5384616 C12.3076921,12.8706517 12.8395614,12.3270665 13.5028069,12.3081989 L13.5384614,12.3076924 L15.9999998,12.3076924 Z" />
      <path d="M18.4615382,22.1538461 C19.1412733,22.1538461 19.6923074,22.7048802 19.6923074,23.3846153 C19.6923074,24.0524252 19.1604382,24.5960104 18.4971926,24.614878 L18.4615382,24.6153845 L13.5384614,24.6153845 C12.8587263,24.6153845 12.3076921,24.0643504 12.3076921,23.3846153 C12.3076921,22.7168054 12.8395614,22.1732202 13.5028069,22.1543526 L13.5384614,22.1538461 L18.4615382,22.1538461 Z" />
      <path d="M15.9999998,6.15876937 L16.0412277,6.15922062 C17.0417907,6.18114384 17.8461536,6.999099 17.8461536,8.00492319 C17.8461536,9.02452579 17.0196024,9.85107701 15.9999998,9.85107701 C14.9803972,9.85107701 14.153846,9.02452579 14.153846,8.00492319 C14.153846,6.999099 14.9582089,6.18114384 15.9587719,6.15922062 L15.9999998,6.15876937 Z" />
    </Icon>
  )
}
