import { useContext } from 'react'

import {
  Authenticated,
  AuthInitBase,
  AuthInitContext,
  AuthInitContextValue,
} from 'components/modules/Auth/AuthInit/context'

/**
 * Returns Auth context.
 * @param isAuthorized - if true, will return Authenticated context
 */
function useAuthState(isAuthorized: true): Authenticated & AuthInitBase
function useAuthState(isAuthorized?: boolean): AuthInitContextValue
function useAuthState(): AuthInitContextValue {
  return useContext(AuthInitContext)
}

export default useAuthState
