import { ABORT_ERROR_NAME } from 'commercetools/client'
import { appInsights } from 'logging/appInsight'

function client<TData>(url: string, init?: RequestInit): Promise<TData> {
  return new Promise(async (resolve, reject) => {
    return (
      fetch('/api/bom/' + url, {
        ...init,
        headers: {
          'Content-Type': 'application/json',
          ...init?.headers,
        },
      })
        .then(async (response) => {
          try {
            const data = await response.json()
            return response.ok ? resolve(data) : reject(data)
          } catch (error) {
            // Data parsing error encountered
            reject({ error })
          }
        })
        // Network error encountered
        .catch((error) => {
          /**
           * For aborted (cancelled) requests, do not log error on the console or App Insights
           * @link https://nodejs.org/api/errors.html#abort_err
           */
          if (error.name !== ABORT_ERROR_NAME) {
            console.error(error)
            appInsights.trackException({ exception: new Error(error) })
          }
          reject({ error })
        })
    )
  })
}

export default client
