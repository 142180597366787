import {
  Button,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuProps,
  Skeleton,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import { BusinessUnit } from '@commercetools/platform-sdk'
import useTranslation from 'next-translate/useTranslation'

import { useCustomer } from 'commercetools/hooks/use-customer'
import { MenuDrawer } from 'components/elements/MenuDrawer/MenuDrawer'
import { AccountMenu } from 'components/modules/Account/AccountMenu'
import { AuthStatus } from 'components/modules/Auth/AuthInit/context'
import useAuthState from 'components/modules/Auth/hooks/useAuthState'
import ChevronIcon from 'public/icons/chevron-down.svg?component'
import AccountIcon from 'public/icons/user.svg?component'
import { breakpoints } from 'theme/foundations/breakpoints'
import getCompanyName from 'utils/getCompanyName'

type AccountButtonProps = Omit<MenuProps, 'children'> & {
  currentCompany?: BusinessUnit
}

const styles = {
  button: {
    h: 12,
    w: { base: 'full', lg: 'inherit' },
    maxW: { base: 'full', lg: '204px' },
    px: { base: 0, lg: 4 },
    sx: {
      '& > span': {
        display: 'flex',
        alignItems: 'center',
      },
      '& > span:first-of-type': {
        marginInlineEnd: 0,
      },
    },
    opacity: 1,
    _disabled: {
      opacity: 1,
    },
    _hover: {
      backgroundColor: 'secondary.grey.100',
    },
    borderRadius: '0.375rem',
  },
  leftIcon: { boxSize: 5 },
  rightIcon: { boxSize: 4, display: { base: 'block', lg: 'none' } },
  menuList: {
    display: { base: 'none', lg: 'inherit' },
    borderRadius: '0.375rem',
    mt: 2,
    p: 4,
    boxShadow: 0,
    border: '1px solid',
    borderColor: 'primary.grey.100',
  },
  welcome: {
    marginInlineStart: '4',
    direction: 'column',
    textAlign: 'start',
  },
  text: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxW: '100%',
    fontSize: 'sm',
    minW: '130px',
  },
  businessUnit: {
    fontWeight: 'normal',
  },
  skeleton: {
    w: '130px',
    h: '10',
  },
} as const

const MENU_SIZE = { base: '100%', md: '288px' }

export const AccountButton = function ({ currentCompany, ...props }: AccountButtonProps) {
  const { t } = useTranslation('common')
  const [customer] = useCustomer()
  const [isDesktop] = useMediaQuery(`(min-width: ${breakpoints.lg})`)

  const firstName = customer?.data?.firstName
  const { status } = useAuthState()

  return (
    <Menu closeOnBlur={isDesktop} gutter={0} {...props}>
      {({ isOpen, onClose }) => (
        <>
          <MenuButton
            as={Button}
            isActive={isOpen}
            variant="menu"
            iconSpacing={3}
            leftIcon={<Icon aria-hidden="true" as={AccountIcon} {...styles.leftIcon} />}
            rightIcon={<Icon aria-hidden="true" as={ChevronIcon} {...styles.rightIcon} />}
            {...styles.button}
          >
            <HStack justify="space-between" w="full">
              <Flex display="flex" maxW={{ base: 'full', lg: '8rem' }} {...styles.welcome}>
                {status === AuthStatus.Authenticated && !customer.isLoading ? (
                  <>
                    <Text as="span" {...styles.text}>
                      {firstName ? t('hi', { user: firstName }) : t('hello')}
                    </Text>
                    <Text as="span" {...styles.text} {...styles.businessUnit}>
                      {getCompanyName(currentCompany)}
                    </Text>
                  </>
                ) : (
                  <Skeleton {...styles.skeleton} />
                )}
              </Flex>
            </HStack>
          </MenuButton>
          <MenuDrawer title={t('account')} isOpen={isOpen} onClose={onClose}>
            <AccountMenu currentCompany={currentCompany} maxW={MENU_SIZE} />
          </MenuDrawer>
          <MenuList {...styles.menuList}>
            <AccountMenu currentCompany={currentCompany} maxW={MENU_SIZE} />
          </MenuList>
        </>
      )}
    </Menu>
  )
}
