import { Cart, CartDraft, CartPagedQueryResponse, UpdateAction } from '@commercetools/platform-sdk'

import { GraphQLIdsResponse } from 'commercetools/types'
import { buildURLSearchParams } from 'commercetools/utils/buildURLSearchParams'
import { normalizeQuery } from 'utils/normalizeQuery'

import client from '../client'

const expand = ['lineItems[*].supplyChannel']

export const fetchBuCartIdGraphQl = async (
  customerId: string | undefined,
  companyKey: string,
  storeKeys: string | ReadonlyArray<string>,
  token?: string,
): Promise<{ id: string }> => {
  const storeKey = Array.isArray(storeKeys) ? storeKeys[0] : storeKeys

  if (!customerId || !companyKey || !storeKey) {
    throw new Error('customer id or company key or store key not set when fetching cart')
  }

  const limit = 1
  const sort = 'createdAt desc'
  const where = `"${[
    Array.isArray(companyKey)
      ? `businessUnit(key in ${companyKey.map((id) => `"${id}"`).join(', ')})`
      : `businessUnit(key="${companyKey}")`,
    Array.isArray(storeKey)
      ? `store(key in ${storeKey.map((key) => `"${key}"`).join(', ')})`
      : `store(key="${storeKey}")`,
    `customerId="${customerId}"`,
    `cartState = "Active"`,
  ]
    .filter(Boolean)
    .join(' and ')
    .replace(/"/g, '\\"')}"`

  const query = normalizeQuery(`query { 
      carts(where: ${where}, sort: "${sort}", limit: ${limit})
      { results { id } }     
    }`)

  return client.post<GraphQLIdsResponse, { query: string }>('/graphql', { query }, token).then((response) => {
    return response.data.carts.results[0]
  })
}

export const fetchBuCart = (
  customerId: string | undefined,
  companyKey: string,
  storeKeys: string | ReadonlyArray<string>,
  token?: string,
): Promise<Cart> => {
  const storeKey = Array.isArray(storeKeys) ? storeKeys[0] : storeKeys

  if (!customerId || !companyKey || !storeKey) {
    throw new Error('customer id or company key or store key not set when fetching cart')
  }

  const url = `/in-store/key=${storeKey}/me/carts`
  const params = {
    where: [`businessUnit(key="${companyKey}") and cartState = "Active"`],
    sort: 'createdAt desc',
    expand,
    limit: 1,
  }

  return client
    .get<CartPagedQueryResponse>(`${url}?${buildURLSearchParams(params)}`, token)
    .then((response) => response.results[0])
}

export const createBUCart = async (draft: CartDraft, storeKey: string): Promise<Cart> => {
  return client.post<Cart, CartDraft>(`/in-store/key=${storeKey}/me/carts/`, { ...draft })
}

export type UpdateCartValue = {
  id: string
  version: number
  storeKey: string
  actions: UpdateAction | UpdateAction[]
}

export const updateBuCart = ({ id, version, storeKey, actions }: UpdateCartValue, token?: string): Promise<Cart> =>
  client.post<Cart, Omit<UpdateCartValue, 'id' | 'storeKey'>>(
    `/in-store/key=${storeKey}/me/carts/${id}`,
    {
      version,
      actions: Array.isArray(actions) ? actions : [actions],
    },
    token,
  )
