import { useRouter } from 'next/router'
import { useMemo } from 'react'

import { RoutePaths } from 'config/routes'
import normalizePathname from 'utils/normalizePathname'

/**
 * @returns pathname to redirect to based on query params.
 *
 * @example
 * ```ts
 * // asPath = '/login?redirect=/profile/'
 * const redirectTo = useRedirectTo()
 * // redirectTo = '/profile/'
 * ```
 * @default RoutePaths.home
 */
function useRedirectTo(): string {
  const { isReady, query } = useRouter()

  const redirectTo = useMemo<string>(() => {
    const redirect = isReady
      ? query.redirect && typeof query.redirect === 'string' && query.redirect
      : typeof window !== 'undefined' && new URL(window.location.href).searchParams.get('redirect')

    if (redirect) {
      return normalizePathname(`/${redirect}`)
    }
    return RoutePaths.home
  }, [isReady, query.redirect])

  return redirectTo
}

export default useRedirectTo
