import { Alert, AlertDescription, AlertProps, Box } from '@chakra-ui/react'
import { ReactNode } from 'react'

import { CheckIcon } from 'components/elements/Icons/CheckIcon'

type Props = AlertProps & {
  children: ReactNode
}

export function SuccessAlert({ children, ...props }: Props) {
  return children ? (
    <Alert variant="success" minHeight="16" pb={4} status="success" {...props}>
      <CheckIcon marginRight={4} boxSize={4} />
      <Box flex="1">
        <AlertDescription display="block">{children}</AlertDescription>
      </Box>
    </Alert>
  ) : null
}
