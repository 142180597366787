import { Button, Text } from '@chakra-ui/react'
import NextLink from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import { RightArrowIcon } from 'components/elements/Icons/RightArrowIcon'

const styles = {
  button: { position: 'relative', verticalAlign: 'middle', h: 12 },
  text: { color: 'white' },
} as const

export function CheckoutMenuButton() {
  const { t } = useTranslation('common')

  return (
    <Button as={NextLink} href="/" variant="menu" rightIcon={<RightArrowIcon />} {...styles.button}>
      <Text {...styles.text}>{t('continue-shopping-text')}</Text>
    </Button>
  )
}
