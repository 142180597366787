import {
  Box,
  Button,
  chakra,
  ChakraProps,
  Collapse,
  Container,
  Divider,
  Flex,
  HStack,
  List,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { ProductProjectionPagedSearchResponse } from '@commercetools/platform-sdk'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { Dispatch, FormEvent, SetStateAction, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { useCart } from 'commercetools/hooks/use-cart'
import { MemoizedQuickSearchResultItem } from 'components/modules/Search/QuickSearch/MemoizedQuickSearchResultItem'

type SearchFormData = FormEvent<HTMLFormElement> | { searchQuery: string | string[] }

type SearchDrawerProps = ChakraProps & {
  searchQuery: string
  searchIsActive: boolean
  productsResponse: ProductProjectionPagedSearchResponse | undefined
  isFetching: boolean
  setQuantityDialogIsOpen: Dispatch<SetStateAction<boolean>>
  setAvailabilityDialogIsOpen: Dispatch<SetStateAction<boolean>>
}

export const QuickSearchDrawer = ({
  searchQuery,
  searchIsActive,
  productsResponse,
  isFetching,
  setQuantityDialogIsOpen,
  setAvailabilityDialogIsOpen,
  ...props
}: SearchDrawerProps): JSX.Element => {
  const router = useRouter()
  const { t } = useTranslation('common')
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { reset } = useFormContext<SearchFormData>()
  const [, , , getLineItemIfExists] = useCart()

  useEffect(() => {
    if (isOpen) {
      router?.prefetch('/search?searchQuery=' + searchQuery)
    }
  }, [isOpen, reset, router, searchQuery])

  useEffect(() => {
    if (searchIsActive) {
      setTimeout(() => onOpen(), 100)
    } else {
      onClose()
    }
  }, [onClose, onOpen, searchIsActive])

  const goToSearch = async () => {
    onClose()
    router.push({
      pathname: '/search',
      query: {
        searchQuery,
        ...(router.query.equipmentId && { equipmentId: router.query.equipmentId }),
      },
    })
  }

  return (
    <>
      <chakra.div
        position="absolute"
        boxShadow="0px 7px 16px 0px rgb(0 0 0 / 15%)"
        top={12}
        width="100%"
        zIndex="dropdown"
        {...props}
      >
        <Collapse in={isOpen}>
          <Container
            paddingTop={5}
            paddingBottom={5}
            px={4}
            bgColor="white"
            borderTopWidth={4}
            borderTopColor="primary.grey.100"
            mt={-1}
          >
            <Flex direction="column">
              {productsResponse && productsResponse.count > 0 ? (
                <>
                  <List>
                    {productsResponse?.results.map((product, index, { length }) => (
                      <Box key={product.id}>
                        <MemoizedQuickSearchResultItem
                          product={product}
                          lineItem={getLineItemIfExists(product)}
                          setQuantityDialogIsOpen={setQuantityDialogIsOpen}
                          setAvailabilityDialogIsOpen={setAvailabilityDialogIsOpen}
                        />
                        {length - 1 !== index && (
                          <Divider borderBottomWidth={2} marginTop={2} borderColor="primary.grey.100" />
                        )}
                      </Box>
                    ))}
                  </List>
                  <Divider borderBottomWidth={4} marginTop={6} borderColor="primary.grey.100" />
                  <HStack justifyContent="space-between" marginTop={6}>
                    <Text>
                      {productsResponse?.total +
                        ' ' +
                        (productsResponse?.total && productsResponse?.total > 1 ? t('common:items') : t('common:item'))}
                    </Text>
                    <Button width="212px" onClick={goToSearch} variant="secondary">
                      {t('show-all')}
                    </Button>
                  </HStack>
                </>
              ) : (
                !isFetching && <Text>{t('no-search-hits')}</Text>
              )}
            </Flex>
          </Container>
        </Collapse>
      </chakra.div>
    </>
  )
}
