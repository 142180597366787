const flattenParams = (key: string, values: Array<string | number>): string =>
  values
    .filter(Boolean)
    .map((value) => `${key}=${encodeURIComponent(value)}`)
    .join('&')

type Params = {
  [key: string | number]: string | number | boolean | Array<string | number>
}

const filterNullish = (value: string | number | boolean | (string | number)[]): boolean =>
  typeof value === 'boolean' || typeof value === 'number' ? true : Boolean(value)

/**
 * Builds a URL search parameters string from the given parameters object.
 *
 * @template P - The type of the parameters object.
 * @param {P} params - The parameters object to be converted into a URL search parameters string.
 * @returns {string} The URL search parameters string.
 */
export const buildURLSearchParams = <P = Params>(params: P): string => {
  return Object.entries(params as Params)
    .filter(([, value]) => filterNullish(value))
    .map(([key, value]) =>
      Array.isArray(value) ? flattenParams(key, value) : `${key}=${encodeURIComponent(value.toString())}`,
    )
    .join('&')
}
