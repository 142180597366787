export const colors = {
  greyCCC: '#CCCCCC',
  grey999: '#999999',
  primary: {
    blue: '#23408F',
    black: '#333333',
    red: '#E2241B',
    white: '#FFFFFF',
    grey: {
      100: '#F2F2F2',
      500: '#E5E5E5',
    },
  },
  secondary: {
    green: '#39B54A',
    blue: '#E5EEF6',
    red: '#D0181F',
    beige: {
      100: '#F8EEE1',
      600: '#F3D9B7',
    },
    grey: {
      100: '#F7F7F7',
      300: '#3D3D3D',
      900: '#808080',
    },
  },
}
