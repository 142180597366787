export const Switch = {
  baseStyle: {
    track: {
      _checked: {
        bg: 'black',
      },
      _focus: {
        boxShadow: 'none',
      },
    },
  },
}
