import { Icon, IconProps } from '@chakra-ui/react'

export function RightArrowIcon(props: IconProps) {
  return (
    <Icon width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fillRule="evenodd" fill="none">
        <g fillRule="nonzero" fill="#FFFFFF">
          <g>
            <g transform="rotate(-90 16 15.9583) translate(-148 -12) translate(136 0)">
              <g>
                <path d="m27.8125,15.95833c0.70877,0 1.28632,0.311 1.31163,0.69993l-0.00013,18.91507l5.89152,-5.89114c0.5207,-0.5207 1.36492,-0.5207 1.88562,0c0.50768,0.50768 0.52038,1.32291 0.03808,1.84597l-0.03808,0.03965l-8,8c-0.50768,0.50768 -1.32291,0.52037 -1.84597,0.03807l-0.03965,-0.03807l-8,-8c-0.52069,-0.5207 -0.52069,-1.36492 0,-1.88562c0.50769,-0.50768 1.32291,-0.52038 1.84597,-0.03808l0.03965,0.03808l5.59786,5.59814l0.001,-18.59544c0,-0.40127 0.58763,-0.72656 1.3125,-0.72656z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </Icon>
  )
}
