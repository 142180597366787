import { ErrorObject, ProductProjectionPagedSearchResponse } from '@commercetools/platform-sdk'
import useTranslation from 'next-translate/useTranslation'
import { useMemo } from 'react'
import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query'

import { fetchProductSearch } from 'commercetools/api/search'
import { useBusinessUnit } from 'commercetools/hooks/use-business-unit'
import { usePriceChannels } from 'commercetools/hooks/use-price-channel'
import { SearchQueryParams } from 'commercetools/types'
import { intl } from 'config/intl'
import { getIntlConfig } from 'utils/getIntlConfig'

export const useProductSearch = (
  params: SearchQueryParams,
  config?: UseQueryOptions<ProductProjectionPagedSearchResponse, ErrorObject>,
): UseQueryResult<ProductProjectionPagedSearchResponse, ErrorObject> => {
  const businessUnit = useBusinessUnit()
  const { id: priceChannel } = usePriceChannels()

  const { lang } = useTranslation('common')

  const intlConfig = useMemo(() => getIntlConfig(businessUnit.erpLegalEntity) || intl, [businessUnit.erpLegalEntity])

  const productQueryParams = useMemo(() => {
    return {
      markMatchingVariants: true,
      priceCurrency: intlConfig?.currency.code,
      priceChannel: priceChannel,
      language: lang,
      ...params,
    }
  }, [priceChannel, intlConfig?.currency.code, lang, params])

  return useQuery<ProductProjectionPagedSearchResponse, ErrorObject>(
    ['fetchProductSearch', productQueryParams],
    ({ signal }) => fetchProductSearch(productQueryParams, void 0, signal),
    {
      enabled: params.enabled,
      onError: console.error,
      ...(config || {}),
    },
  )
}
